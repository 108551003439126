import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Amenity,
  SeatCategory,
  Spaces,
  SpaceTimings,
} from 'src/app/shared/interface';
import { QueryParams } from 'src/app/shared/services/common-services/rest-api.service';
import { amountNotAvailable, defaultLatLongValuesCityName, SeatsType, SpaceOpeningDays, Timings } from 'src/app/Helper/enums';
import { Helper } from 'src/app/Helper/helpers';
import { SubSink } from 'subsink';
import { SpaceListResponse, SpaceService } from 'src/app/shared/services/spaces/space.service';
import { Router } from '@angular/router';
import { CustomAddressLocation, CustomLocationService } from 'src/app/shared/services/locations/custom-location.service';
import { MoreImagesModalComponent } from 'src/app/components/more-images-modal/more-images-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MoreImages } from 'src/app/shared/interface/more-images';
import { SweetAlertMessageService } from 'src/app/shared/services/sweet-alerts-message/sweet-alert-message.service';

@Component({
  selector: 'app-workspace-list',
  templateUrl: './workspace-list.component.html',
  styleUrls: ['./workspace-list.component.scss'],
})
export class WorkspaceListComponent implements OnInit,OnDestroy {
  amountMessage = amountNotAvailable;
  helper = Helper;
  spacesData: SpaceListResponse;
  daysEnum = SpaceOpeningDays;
  seatsCategory: SeatCategory[];
  seatTypes = SeatsType;
  timingsType = Timings;
  imageLoader = true;
  isLoading = false;
  locationPermissions = false;
  private subs = new SubSink();

  constructor(
    private spaceService: SpaceService,
    private router: Router,
    private dialog: MatDialog,
    private customLocationService: CustomLocationService
  ) {}

  ngOnInit(): void {
    this.spaces();
  }

  spaces(): void {
    const queryData: QueryParams = {
      lat: defaultLatLongValuesCityName.lat,//initial latitude for gurugram
      long: defaultLatLongValuesCityName.long,//initial longitude for gurugram
      count: 3,
      page: 0,
      citySlug: 'gurgaon',
      areaSlug: ''
    };
    this.getSpacesData(queryData);
  }
  
  trackByFn(index:number, space:Spaces): string {
    return space.id
  }

  getSpacesData(queryParam: QueryParams): void {
    this.isLoading = true;
    this.subs.add(
      this.spaceService.getSpacesBySlug(queryParam, null).subscribe((spaces) => {
        this.isLoading = false;
        this.spacesData = {
          spaces: [],
          count: 0
        };
        spaces.spaces.forEach( res => {
          res.badge = this.helper.getSpaceCategoryBadge(res?.spaceCategory?.categoryName)
         })
        this.spacesData = spaces;
      },err => {
        this.isLoading = false;
      })
    );
  }


  getImagePath(imagesArr: string[]): string {
    if (imagesArr.length > 0) {
      return imagesArr[0];
    } else {
      return '/assets/images/Rectangle 43.png';
    }
  }

  onErrorHandling(event: { target: { src: string } }): void {
    event.target.src = 'assets/images/Rectangle 43.png';
  }

  getSpaceImages(imagesArr: string[]): string[] {
    switch (imagesArr.length) {
    case 0:
      return [
        'assets/images/Rectangle 45.png',
        'assets/images/Rectangle 46.png',
        'assets/images/Rectangle 47.png',
      ];
    case 1:
      return [imagesArr[0], imagesArr[0], imagesArr[0]];
    case 2:
      return [imagesArr[0], imagesArr[1], imagesArr[0]];
    default:
      return [imagesArr[0], imagesArr[1], imagesArr[2]];
    }
  }

  getMoreImageUrl(imagesArr: string[]): string {
    if (imagesArr.length > 3) {
      return imagesArr[3];
    } else {
      return 'assets/images/Rectangle 44.png';
    }
  }


  routeToSpaceDetails(spaceInfo: Spaces): void {
    window.scrollTo(0,0);
    if(spaceInfo?.slug && spaceInfo?.slug !== ''){
      this.router.navigate([`/coworking-spaces/${spaceInfo?.cityInfo?.slug}/${spaceInfo?.areaInfo?.slug}/${spaceInfo?.slug}`]);
      return;
    } else {
      this.router.navigate(['/space-details/'+spaceInfo.id]);
    }
  }


  getAmenityImgArray(amenitiesArr: Amenity[]): Amenity[] {
    switch (amenitiesArr.length) {
    case 0:
      return [
        {
          id: '1',
          name: 'Printer/Scanner',
          icon: 'assets/images/Printer.png',
        },
        { id: '2', name: 'Reception', icon: 'assets/images/Reception.png' },
        { id: '3', name: 'Wifi', icon: 'assets/images/Wifi.png' },
      ];
    case 1:
      return [amenitiesArr[0], amenitiesArr[0], amenitiesArr[0]];
    case 2:
      return [amenitiesArr[0], amenitiesArr[1], amenitiesArr[0]];
    default:
      return [amenitiesArr[0], amenitiesArr[1], amenitiesArr[2]];
    }
  }
  getMoreAmenities(amenitiesArr: Amenity[]): string[] {
    return amenitiesArr.slice(3).map(amenity => amenity.name);
  }

  //type === 0  days and type === 1 timings
  getSpaceWorkingDays(spaceTimings: SpaceTimings, type: number): string {
    if (spaceTimings) {
      if (type === this.timingsType.days) {
        return `${Object.values(this.daysEnum)[spaceTimings.openingDay]} - ${
          Object.values(this.daysEnum)[spaceTimings.closingDay]
        }`;
      } else if (type === this.timingsType.time) {
        return `${Helper.getColonTime(
          spaceTimings.openingTime || 0
        )} - ${Helper.getColonTime(spaceTimings.closingTime || 0)}`;
      }
    }
  }

  routeToExploreSpaces(): void {
    void this.router.navigate([`/coworking-spaces/gurgaon`]);
  }

  moreImages(moreImage: MoreImages): void {
    this.dialog.open<MoreImagesModalComponent, MoreImages>(MoreImagesModalComponent,{
      disableClose: true,
      data: moreImage
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}

