export enum SpaceOpeningDays {
  sunday = 'Sun',
  monday = 'Mon',
  tuesday = 'Tue',
  wednesday = 'Wed',
  thursday = 'Thu',
  friday = 'Fri',
  saturday = 'Sat',
}

export enum SeatsType {
  desks = 'desks',
  cabins = 'cabins'
}

export enum Timings {
  days,
  time
}

export enum ListYourSpace {
  basicDetails = 1,
  precautions,
  pricings,
  allDetails
}
export const formSources = {
cps: 'CPS',
mlp1:' MLP1',
mlp2:' MLP2',
Website:'Website',
QuoteRequest : 'Quote Request',
enterprise: 'Enterprise'
}

export const amountNotAvailable = 'Not Available'
export const styleworkStats ={
  spaces: 'Spaces',
  spacesNumber: '3000',
  customersServed: 'Customers',
  customersServedNumber: '4000',
  enterpriseServed: 'Enterprises',
  enterpriseServedNumber: '500'
}
export const officeAddress = {
  address: `Vi-John Tower, 393, Udyog Vihar Phase 3 Rd, Phase III, Udyog Vihar, Sector 20, Gurugram, Haryana 122016`
}

export const styleworkBangloreAddress  = {
  address: `AWFIS, Summit-B, 12th Floor, Brigade Metropolis,
#9, ITPL Main Rd, Garudachar Palya, Mahadevapura, Bengaluru,
Karnataka 560048.
`
}
export const styleworkMumbaiAddress  = {
  address: `4th Floor, Innov8, Times Square Building, A-402, Andheri East, Mumbai, Maharashtra 400059`
}

export const OfficeAddressLatLong = [
  {
    lable:'Gurugram-Head Quarter',
    lat:28.505856680036057,
    lng:77.08869688014313,
  },
  {
    lable:'Stylework- Bangalore',
    lat:12.992510033403287,
    lng:77.70413362600672
  },
  {
    lable:'Stylework- Mumbai',
    lat:19.116704,
    lng:72.855957
  }
]


export enum VendorFormStep {
  basicDetails=1,
  servicesAndBankDetails,
  documentsUpload
}

export enum DeskTypes {
  openDesk = 'Open Desk',
  privateCabin = 'Private Cabin',
  privateCabinTwoSeater = 'private cabin(2 Seater)',
  privateCabinFourSeater = 'private cabin(4 Seater)',
  flexiDesk = 'flexi desk',
  conferenceRoom = 'conference room',
  meetingRoom = 'meeting room',
  dedicatedDesk = 'dedicated desik'
}

export const pricingKeys = {
  dailyPrice: 'dailyPrice',
  deskTypeName: 'deskTypeName',
  weeklyPrice: 'weeklyPrice',
  biWeeklyPrice: 'biWeeklyPrice',
  monthlyPrice: 'monthlyPrice',
  quarterlyPrice: 'quarterlyPrice',
  inventory: 'inventory'
};

export const geolocationKeys = {
  city: 'administrative_area_level_3',
  state: 'administrative_area_level_1',
  country: 'country',
  permissionName: 'geolocation',
  permissionDenied: 'denied',
  permissionGranted: 'granted',
  permissionPrompt: 'prompt',
  pinCode: 'postal_code'
};

export const defaultLatLongValuesCityName = {
  lat: 28.494976,//initial latitude for gurugram
  long: 77.089542,//initial longitude for gurugram
  count: 10,
  page: 1,
  cityName: 'Gurugram'
};

export const swapTextContent = {
  startup: 'Startup',
  venture: 'Venture',
  enterPrise: 'Enterprise'
};

export const swapImageURl = {
  [swapTextContent.startup]: 'assets/images/heroes/1.jpg',
  [swapTextContent.venture]: 'assets/images/heroes/2.jpg',
  [swapTextContent.enterPrise]: 'assets/images/landing_rectangle.png'
};

export const routeParams = {
  citySlug: 'citySlug',
  spaceName: 'spaceName'
};

export const notShowNavbar= ['icici','product-suite','app-privacy-policy','book-now','enterprise','get-a-quote','product-manual','proposal-details','proposal-view','unconventional-unions']
