import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Helper } from 'src/app/Helper/helpers';
import { RequestAQuoteComponent } from 'src/app/components/request-a-quote/request-a-quote.component';
import { SeatsPriceDetails } from 'src/app/shared/interface';

@Component({
  selector: 'app-d-seat-type-details',
  templateUrl: './d-seat-type-details.component.html',
  styleUrls: ['./d-seat-type-details.component.scss']
})
export class DSeatTypeDetailsComponent implements OnInit {
  helper = Helper;
  @Input() seatTypesData: SeatsPriceDetails[] = [];
  @Input() requestAQuote = true;

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // console.log(this.seatTypesData);
  }

  requestAQuoteDialog(seatName: string): void {
    this.dialog.open(RequestAQuoteComponent,
      { 
        data: seatName,
        disableClose: true,
      });
  }

}
