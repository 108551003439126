import { Component, Input, OnInit } from '@angular/core';
import { NearBy } from 'src/app/shared/interface';

@Component({
  selector: 'app-d-nearby',
  templateUrl: './d-nearby.component.html',
  styleUrls: ['./d-nearby.component.scss']
})
export class DNearbyComponent implements OnInit {
  @Input() nearByLandmarks: NearBy[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  onHandlingNearByImgError(event: { target: { src: string } }): void {
    event.target.src = 'assets/icons/location-point.svg'; //todo replace with default image
  }
}
