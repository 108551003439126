<div class="info-banner pt-md-5 pt-4 pb-4">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-5 col-md-5 order-lg-0 order-md-0 order-1 h-100">
        <h1 class="d-lg-block d-md-block d-none fs-bigest fw-500 line-h1">Find a workspace for your <span
            id="swap-text">Startup</span></h1>
        <div class="banner-tab border rounded">
          <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
            <li class="nav-item" role="presentation" (click)="webengaegEventExploreSpace()">
              <button class="nav-link py-12  active" id="explore-tab" data-bs-toggle="tab" data-bs-target="#explore"
                role="tab" aria-controls="explore" aria-selected="true">Explore Spaces</button>
            </li>
            <li class="nav-item" role="presentation" (click)="webengaegEventFlexibleMembership()">
              <button class="nav-link py-12" id="memberships-tab" data-bs-toggle="tab" data-bs-target="#memberships"
                role="tab" aria-controls="memberships" aria-selected="false">Flexible Memberships</button>
            </li>
          </ul>
          <div class="tab-content p-4" id="myTabContent">
            <div class="tab-pane fade show active" id="explore" role="tabpanel" aria-labelledby="explore-tab">
              <p class="fw-600 fs-regular line-h5">Explore the perfect workplace personalised to your needs across our
                <strong>{{companyStats?.spacesNumber}}+</strong> spaces
              </p>
              <p class="fs-regular fw-500 text-primary-base m-0 mb-2 mt-4"> Where do you want to work at?</p>
              <form [formGroup]="mform">
                <div class="searchbox-container mb-4">
                  <div
                    [class]="citiesList.length > 0 && mform.value.searchToken  !== null && isSearboxTableOpen  ? 'searchbox d-flex align-items-center justify-content-between':'searchbox-default d-flex align-items-center'">
                    <div [class]="mform.value.searchToken !== null ? 'd-flex ':'d-flex ' ">

                      <div class="d-flex align-items-center">
                        <div>

                          <img class="ms-1" height="16" width="16" src="assets/icons/Search.svg" alt="search">
                        </div>

                        <div>
                          <input #searchBox class="custom-input ms-2  w-100 " formControlName="searchToken"
                            (keyup)="getInputValue()" [(ngModel)]="searchCity" type="text"
                            placeholder="Search for Location" />
                        </div>
                      </div>

                    </div>
                    <div>
                      <img *ngIf="mform.value.searchToken  !== null && isSearboxTableOpen " (click)="resetSearchToken()"
                        class="tab me-2" src="assets/icons/clear-search.svg" alt="" title="Clear">
                    </div>
                  </div>
                  <div *ngIf="mform.value.searchToken  !== null && isSearboxTableOpen  "
                    class="searchbox-table w-100 pt-md-3 pt-2">
                    <div class="d-flex w-100 pb-3 tab"
                      *ngFor="let city of citiesList | filter: searchCity; let spaceIndex= index">
                      <div class="mt-1">
                        <img [src]="'assets/icons/searched-location.svg'" class="img-fluid" height="24" width="24"
                          alt="">
                      </div>
                      <div
                        [class]=" (citiesList | filter: searchCity).length- 1 != spaceIndex  ?'ms-2 border-set w-100 pb-2':'ms-2 w-100'"
                        (click)="routeToSpacesExplorationByCityId(city)">
                        <p class="fs-regular fw-500 font-family text-dark m-0">{{city?.name}}</p>
                        <p class="fs-small fw-500 font-family text-tertiary-grey m-0">{{city?.state?.name || city?.name
                          }},{{'India'}}</p>
                      </div>
                    </div>
                    <ng-container *ngIf="(citiesList | filter: searchCity).length === 0">
                      <p class="fs-regular fw-500 font-family text-dark m-0 text-center">
                        No result found
                      </p>
                    </ng-container>
                  </div>
                </div>
              </form>

              <h6 class="fs-regular ">Popular Cities to work at:</h6>
              <app-style-work-cities></app-style-work-cities>
              <p class="mb-0 mt-4 fs-regular">Looking for customizable plans? Check our <span class="fw-bold tab link"
                  (click)="flexibleMembership()">Flexible memberships</span></p>
            </div>
            <div class="tab-pane fade" id="memberships" role="tabpanel" aria-labelledby="memberships-tab">
              <p class="fs-regular">We are bringing flexibility in workspaces with our flexible membership plans, that
                provide you with</p>
              <div class="d-flex flex-mem">
                <div class="pt-1"><img src="assets/icons/location-point.svg" alt="location point"></div>
                <div class="ms-2">
                  <h3 class="mb-0">Easy Locational Access</h3>
                  <p>With our flexible membership plans, you can work from anywhere at our premium spaces pan-India</p>
                </div>
              </div>
              <div class="d-flex flex-mem">
                <div class="pt-1"><img src="assets/icons/Flexible-Time.png" alt="flexible time"></div>
                <div class="ms-2">
                  <h3 class="mb-0">Flexible Duration</h3>
                  <p>Easily increase of decrease the duration of your ongoing plan with flexible memberships</p>
                </div>
              </div>
              <div class="d-flex flex-mem">
                <div class="pt-1"><img src="assets/icons/Customize-space.png" alt="customize space"></div>
                <div class="ms-2">
                  <h3 class="mb-0">Customization</h3>
                  <p>You can also customize your workspace to bring a touch of personalization</p>
                </div>
              </div>

              <p class="mt-2 fs-small">We will be launching it on the website soon, till then download the stylework app
              </p>

              <div class="d-flex mt-3">
                <a href="https://stylework.app.link/istore" target="_blank">
                  <img class="img-fluid" src="assets/images/app-stores.svg" alt="app store">
                </a>
                <a class="ms-3" href="https://stylework.app.link/playstore" target="_blank">
                  <img class="img-fluid" src="assets/images/play-store.svg" alt="play store">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-1 col-md-1  h-100"></div>

      <div class="col-12 col-lg-6 col-md-6 mt-3 mb-md-0 mb-3 tab ">
        <div *ngIf="imageLoader" class="d-flex align-items-center justify-content-center py-3 h-100">
          <app-bootstrap-loader>
          </app-bootstrap-loader>
        </div>
     
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor=" let banner of banners ; trackBy: trackByFn;let i = index">
            <ng-template carouselSlide>
              <div class="card-container d-flex justify-content-center align-items-center" (click)="goto(banner,i+1)">
                <img height="600" width="581" class="img-fluid rounded-3 flex-fill" [hidden]="imageLoader" [src]="banner?.src"
                [attr.loading]="i <= 1 ? 'eager' : 'lazy'" [alt]="banner?.alt" (load)="this.imageLoader = false;">
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

    </div>
  </div>
</div>