<div class="px-2 py-3 text-start">
    <div [class]="amenities.length>=7?'more-amenities-scrollbar':''" *ngIf="amenities.length > 0 && !smallSize">
        <p class="mb-1 text-dark" *ngFor="let amenity of amenities">
            {{amenity}}
        </p>
    </div>
    <div [class]="amenities.length>=6?'small-more-amenities-scrollbar':''" *ngIf="amenities.length > 0 && smallSize">
        <p class="mb-1 text-dark" *ngFor="let amenity of amenities">
            {{amenity}}
        </p>
    </div>
</div>
