export const mediaContent = [
 
    {
        description: `Indian brands converge at 'Unconventional Unions' to shape flexspace future`,
        redirectionLink: `https://www.aninews.in/news/national/general-news/indian-brands-converge-at-unconventional-unions-to-shape-flexspace-future20240630181647 `,
        mediaImage: 'assets/icons/media-icons/ani.png'
    },
    {
        description: 'Stylework, Leading Co-working Space Aggregator, Announces Strategic Partnership with RE/MAX',
        redirectionLink: `https://cxotoday.com/press-release/stylework-leading-co-working-space-aggregator-announces-strategic-partnership-with-re-max/`,
        mediaImage: 'assets/icons/media-icons/cxo.png'
    },
    {
        description: 'Experience Unmatched Flexibility: Reduce Upfront Costs with Stylework, The top Co-Working Space Aggregator',
        redirectionLink: `https://english.jagran.com/india/experience-unmatched-flexibility-reduce-upfront-costs-with-stylework-the-top-coworking-space-aggregator-10081606`,
        mediaImage: 'assets/icons/media-icons/jagran.png'
    },
    {
        description: 'Hybrid Working Evolution: Technology Resolves All Challenges',
        redirectionLink: `https://www.expresscomputer.in/guest-blogs/hybrid-working-evolution-technology-resolves-all-challenges/111330/`,
        mediaImage: 'assets/icons/media-icons/expresscomputer.png'
    },
    {
        description: 'Flexwork, a sustainable solution to environment',
        redirectionLink: `https://thedailyguardian.com/flexwork-a-sustainable-solution-to-environment/`,
        mediaImage: 'assets/icons/media-icons/thedailyguardian.png'
    },
    {
        description: 'BizDateUp Technologies Invests $120K in Stylework’s extended Series A2 round- The Flex Space SAAS Aggregator',
        redirectionLink: `https://www.apnnews.com/bizdateup-technologies-invests-120k-in-styleworks-extended-series-a2-round-the-flex-space-saas-aggregator/`,
        mediaImage: 'assets/icons/media-icons/apn.png'
    },
   
    {
        description: 'B2B SAAS co-working space aggregator platform, Stylework to launch its version 2.0',
        redirectionLink: 'https://timesofindia.indiatimes.com/b2b-saas-co-working-space-aggregator-platform-stylework-to-launch-its-version-2-0/articleshow/99840211.cms?from=mdr',
        mediaImage: 'assets/icons/media-icons/toi.png'
    },
    {
        description: 'The future of work is here: How Stylework is revolutionizing the co-working industry',
        redirectionLink: `https://english.newstracklive.com/news/the-future-of-work-is-here-how-stylework-is-revolutionizing-the-coworking-industry-sc18-nu346-ta346-1279880-1.html`,
        mediaImage: 'assets/icons/media-icons/newstrack.png'
    },

]