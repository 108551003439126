import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopularLocations } from 'src/app/shared/interface';
import { Observable } from 'rxjs';
import { RestApiService } from 'src/app/shared/services/common-services/rest-api.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SubSink } from 'subsink';
import { SpaceService } from 'src/app/shared/services/spaces/space.service';
import { AppRouterService } from 'src/app/shared/services/router/app-router.service';
import { MoreCitiesComponent } from 'src/app/components/more-cities/more-cities.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-workspace-filters',
  templateUrl: './workspace-filters.component.html',
  styleUrls: ['./workspace-filters.component.scss'],
})
export class WorkspaceFiltersComponent implements OnInit, OnDestroy {
  popularCities$: Observable<PopularLocations[]>;
  popularLocationsData: PopularLocations[] = [];
  selectedLocation: PopularLocations;
  postalCode: number = null;
  customOptions: OwlOptions = {
    autoplay: false,
    autoplaySpeed: 500,
    loop:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2.5
      },
      260:{
        items:2.5
      },
      350: {
        items: 4
      },
      450:{
        items: 5
      },
      560: {
        items: 5
      },
      800:{
        items:6
      },
      1000: {
        items: 6
      }
    },
    nav: true,
  };
  customOptions1: OwlOptions = {
    autoplay: false,
    autoplaySpeed: 500,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 300,
    navText: ['', ''],
    responsive: {
      0: {
        items: 3,
      },
      250:{
        items: 4,
      },
      350: {
        items: 5,
      },
      500: {
        items: 6,
      },
      1000: {
        items: 7,
      },
    },
    nav: true,
  };
  locationSelected = false;
  spacesCount$: Observable<number>;
  private subs = new SubSink();
  constructor(
    private restApiService: RestApiService,
    private spaceService: SpaceService,
    private appRouterService: AppRouterService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.popularCity();
    this.spacesCount$ = this.spaceService.spacesCount$;
  }

  popularCity(): void {
    this.popularCities$ = this.restApiService.popularCity$;

    this.subs.add(
      this.restApiService.getPopularCities().subscribe((popularCities) => {
        this.popularLocationsData = popularCities;
        this.spaceService.postalCode$.subscribe((postalCodeVal) => {
          this.postalCode = postalCodeVal;
          if (this.popularLocationsData.length > 0 && this.postalCode != null) {
            const index = this.popularLocationsData.findIndex(
              (popularLocation) =>
                popularLocation.pincodePrefix ===
                +this.postalCode.toString().substr(0, 3)
            );
            if (index > -1) {
              this.locationSelected = true;
              this.selectedLocation = {
                id: this.popularLocationsData[index].id,
                name: this.popularLocationsData[index].name,
                cities: this.popularLocationsData[index].cities,
                icon: this.popularLocationsData[index].icon,
                pincodePrefix: this.popularLocationsData[index].pincodePrefix,
                image: this.popularLocationsData[index].image,
                newBlueIcon: this.popularLocationsData[index].newBlueIcon
              };
            }
          } else {
            this.locationSelected = false;
          }
        });
      })
    );
  }

  routeToPopularLocations(city: PopularLocations): void {
    this.appRouterService.routeToSpaceExplorationUsingCitySlug(Object.assign(city,{search:true}));
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MoreCitiesComponent,{
      width:'98vw',
      position:{top:'0px',},
      disableClose: true,
      data:{ route: true}
    });
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

