<owl-carousel-o [options]="customOptions" *ngIf="!loading && cardWithImageData?.length > 1">
  <ng-template carouselSlide *ngFor="let data of cardWithImageData">
   <ng-container>
     <app-card-with-image [cardWithImage]="data"></app-card-with-image>
   </ng-container>
  </ng-template>
</owl-carousel-o>
<div *ngIf="!loading && cardWithImageData?.length === 1" class="card-width">
  <ng-container *ngFor="let data of cardWithImageData">
    <app-card-with-image [cardWithImage]="data"></app-card-with-image>
  </ng-container>
</div>
<div class="d-flex align-items-center justify-content-center py-3" *ngIf="loading">
  <app-bootstrap-loader></app-bootstrap-loader>
</div>