<div class="d-flex justify-content-between flex-row align-items-center">
    <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" (change)="setPrices($event)">
        <label class="form-check-label ms-1" for="defaultCheck1">
            Rs {{minPrice}} - Rs {{maxPrice}}
        </label>
    </div>
    <div>
        <!-- <span class="list-text">({{count}})</span> -->
    </div>
</div>