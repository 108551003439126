import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaTalkAboutUsComponent } from './media-talk-about-us/media-talk-about-us.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CommonComponentsModule } from "../common/common-components.module";



@NgModule({
    declarations: [
        MediaTalkAboutUsComponent
    ],
    imports: [
        CommonModule,
        CarouselModule,
        CommonComponentsModule
    ],
    exports: [
      MediaTalkAboutUsComponent
    ]
})
export class MediaTalkAboutUsModule { }
