<ng-container *ngIf="!imageLoader">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let city of popularCitiesSlideArr">
        <ng-template  carouselSlide [id]="city?.id">
          <div class="slide tab text-center"  (click)="routeToPopularLocations(city)">
            <div class="py-1 d-flex align-items-center justify-content-center flex-column">
              <img
                [src]="city?.newBlueIcon" 
                [alt]="city?.name">
            <p class="m-0 fs-small fw-500 mt-1">{{city?.name}}</p>
            </div>
          </div>   
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</ng-container>
<ng-container *ngIf="imageLoader">
<div class="d-flex align-items-center justify-content-center">
  <app-bootstrap-loader></app-bootstrap-loader>
</div>
</ng-container>