<div class="d-none d-md-block">
    <table class="table table-bordered mt-4">
        <tbody>
            <ng-container *ngIf="seatTypesData.length > 0">
                <ng-container *ngFor="let seatType of seatTypesData">
                    <tr *ngIf="seatType.price?.amount !== '0'">

                        <td class="col-3">
                            <div class="d-flex justify-content-center align-items-center py-4">
                                    <img [src]="seatType?.icon" [alt]="seatType?.seatName">
                                    <span class="seat-description ms-2">{{seatType?.seatName}}</span>
                                    <p *ngIf="seatType?.seats > 1" class="m-0 ms-1 fs-regular fw-600 text-dark">({{seatType?.seats +' '+'Seater'}})</p>
                            </div>
                        </td>
                        <td class="col-5">
                            <div class="d-flex justify-content-center align-items-center p-2">
                                <div class="mt-3">
                                    <span class="seat-description">{{seatType?.description}}</span>
                                </div>
                            </div>
                        </td>
                        <td class="col-4">
                            <div class=" p-2">
                                <div class="d-flex justify-content-between align-items-center flex-row">
                                    <div class="ms-lg-4 md-2">
                                        <span class="price-text">Starting from</span>
                                        <p class="mt-2">₹{{
                                            helper.getFormattedAmount(seatType?.price)}}/<span
                                                class="price-text"> {{seatType?.seats > 1 ? 'seat/':''}}{{seatType?.duration}}</span></p>
                                    </div>
                                    <div *ngIf="requestAQuote">
                                        <a class="link-text tab" (click)="requestAQuoteDialog(seatType?.seatName)">Request a Quote
                                            <img class="ms-1" src="assets/icons/next-icon.png" alt="next icon"> </a>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
    </table>
</div>