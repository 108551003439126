import { Component, Input, OnInit } from '@angular/core';
import { Amenity } from 'src/app/shared/interface';

@Component({
  selector: 'app-d-amenity',
  templateUrl: './d-amenity.component.html',
  styleUrls: ['./d-amenity.component.scss']
})
export class DAmenityComponent implements OnInit {
  @Input() amenities: Amenity[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  onErrorHandling(event: { target: { src: string } }): void {
    event.target.src = 'assets/icons/Easy-Location-Access.png'; //todo replace with default image
  }
}
