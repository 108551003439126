/* eslint-disable import/order */
import { MapsAPILoader } from '@agm/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { defaultLatLongValuesCityName, geolocationKeys } from 'src/app/Helper/enums';
import { LatLongPostalCode } from '../../interface';
import { SweetAlertMessageService } from '../sweet-alerts-message/sweet-alert-message.service';
import { google } from '@google/maps';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const google: any;
export interface CustomAddressLocation extends LatLongPostalCode {
  address: string;
}

@Injectable({
  providedIn: 'root',
})

export class CustomLocationService {

  locationObj$: Observable<CustomAddressLocation>;
  private locationSubject = new BehaviorSubject<CustomAddressLocation>(null);

  constructor(
    private apiloader: MapsAPILoader,
    private sweetAlertService: SweetAlertMessageService,
    private router: Router
  ) {
    this.locationObj$ = this.locationSubject.asObservable();
  }

  checkPermission(): void {
    const that = this;
    navigator.permissions.query({ name: `geolocation` }).then((result) => {
      if (result.state === geolocationKeys.permissionDenied) {
        that.sweetAlertService
          .locationAlertDialog(
            `You have blocked locations for this site..
          so we are redirecting you to google support(https://support.google.com/chrome/answer/142065?hl=en) 
          for locations on which you can easily find steps to manage your google permissions for locations`
          )
          .then((response) => {
            window.open(
              'https://support.google.com/chrome/answer/142065?hl=en',
              '_blank'
            );
          });
      } else {
        that.getCurrentUserLocation();
      }
    });
  }

  getCurrentUserLocation(): void {
    let formattedAddress: string[] = [];
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          if (position) {
            this.apiloader.load().then(() => {
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
              const geocoder = new google.maps.Geocoder();
              const latlng = {
                lat,
                lng,
              };
              const that = this;
              let pinCode = null;
              geocoder.geocode({ location: latlng }, (results) => {
                const formattedAddressOfArray: string[] = [];
                if (
                  results &&
                  results[0].address_components &&
                  results[0].address_components.length
                ) {
                  for (const address of results[0].address_components) {
                    if (
                      address.types.includes(geolocationKeys.city) ||
                      address.types.includes(geolocationKeys.state) ||
                      address.types.includes(geolocationKeys.country)
                    ) {
                      formattedAddressOfArray.push(address.long_name);
                    } else if (
                      address.types.includes(geolocationKeys.pinCode)
                    ) {
                      pinCode = +address.long_name;
                    }
                  }
                  formattedAddress = formattedAddressOfArray;
                } else {
                  formattedAddress = [results[0].formatted_address];
                }
                that.locationSubject.next({
                  postalCode: pinCode,
                  lat,
                  lon: lng,
                  address: formattedAddress.toString(),
                });
              });
            });
          }
        },
        (error: GeolocationPositionError) => {
          if (error.PERMISSION_DENIED) {
            this.router.navigate(['explore-space'],{ queryParams: {
              lat: defaultLatLongValuesCityName.lat,//initial latitude for gurugram
              long: defaultLatLongValuesCityName.long,//initial longitude for gurugram
              count: defaultLatLongValuesCityName.count,
              page: defaultLatLongValuesCityName.page
            }});
          } else if (error.POSITION_UNAVAILABLE) {
            //positoin unavailable in bvroiser
          }
        },
        { enableHighAccuracy: true }
      );
    }
  }
}
