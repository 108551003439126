import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { Auth, ProposalRequest, ProsalInfo, SpacePostResponse, SpaceProposal, mailBody, seatCategoryProposal, userInfo } from './proposal';
import { EnvConfigurationService } from 'src/app/Helper/environment-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {
  private proposalId = new Subject<string>();
  public data$: Observable<string> = this.proposalId.asObservable();

  updateData(proposalId: string) {
    this.proposalId.next(proposalId);
  }
  
  config = this.envConfigService.configSubject$;


  constructor(
    private http: HttpClient,
    private envConfigService: EnvConfigurationService,
  ) { }

  getUserInfo(): userInfo {
     return JSON.parse(localStorage.getItem('userInfo'));
  }

  login(userName: string, password: string): Observable<Auth> {
    const data = {
      id: userName.trim(),
      password,
    };
    return this.http
      .post<Auth>(this.config.value.webUrl + 'admin/login', data)
  }

  defaultProposalId(token: string): Observable<ProsalInfo> {
    const headers = {'token': token }
    return this.http.post<ProsalInfo>(
      `${this.config.value.apiUrl}/proposal-default`, {},
      {headers}
    );
  }

  getSeatCategory(): Observable<seatCategoryProposal[]> {
    return this.http.get<seatCategoryProposal[]>(`${this.config.value.apiUrl}/cms/seat-category`)
  }

  postProposeDSpaceDetails(spaceDetails:SpaceProposal): Observable<SpacePostResponse> {
    const headers = { 'token': this.getUserInfo().token }
    return this.http.post<SpacePostResponse>(`${this.config.value.apiUrl}/proposed-space`, spaceDetails,
    {headers}
  )
  }

  uploadSpaceImage(spaceId: string, image:File):Observable<any> {
    const headers = { 'token': this.getUserInfo().token }
    const formData =  new FormData();
    formData.append('image', image);
    return this.http.post<any>(`${this.config.value.apiUrl}/proposed-space/${spaceId}/upload-image`, formData,{headers});

  }

  getProposedSpaces(proposalId: string): Observable<SpaceProposal[]>{
    const headers = { 'token': this.getUserInfo().token }
    return this.http.get<SpaceProposal[]>(`${this.config.value.apiUrl}/proposed-space/${proposalId}`,{headers});
  }

  updateProposedSpace(spaceId:string, spaceDetails:SpaceProposal): Observable<SpaceProposal>{
    const headers = { 'token': this.getUserInfo().token }
    return this.http.patch<SpaceProposal>(`${this.config.value.apiUrl}/proposed-space/${spaceId}`, spaceDetails, {headers});
  }

  getPorposalDetials(proposalId: string): Observable<ProposalRequest[]>{
    return this.http.get<ProposalRequest[]>(`${this.config.value.apiUrl}/proposals/${proposalId}`);
  }

  addExistingSpaces(proposalId:string, spaceIds:string[]):Observable<any> {
    const headers = { 'token': this.getUserInfo().token }
    return this.http.patch<any>(`${this.config.value.apiUrl}/proposed-space/${proposalId}/existing-space`, {spaceIds:spaceIds},{headers});
  }

  sendProposalMail(proposalBoady: mailBody, proposalId:string): Observable<void> {
    const headers = { 'token': this.getUserInfo().token }
    return this.http.patch<void>(`${this.config.value.apiUrl}/send-proposal-mail/${proposalId}`,proposalBoady ,{headers})
  }

  deleteSpace(spaceId:string):Observable<any> {
    const headers = { 'token': this.getUserInfo().token }
    return this.http.delete<any>(`${this.config.value.apiUrl}/proposed-space/${spaceId}`,{headers});
  }

  generateProposal(proposalId:string, proposalRequest: ProposalRequest): Observable<void>{
    const headers = { 'token': this.getUserInfo().token }
    return this.http.patch<void>(`${this.config.value.apiUrl}/proposals/${proposalId}`,proposalRequest,{headers});
  }

}
