import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { WEBENGAGE_EVENTS } from 'src/app/Helper/constant';
import { Security } from 'src/app/Helper/security';
import { PopularLocations } from 'src/app/shared/interface/popular-location.interface';
import { RestApiService } from 'src/app/shared/services/common-services/rest-api.service';
import { AppRouterService } from 'src/app/shared/services/router/app-router.service';
import { WebEngageService } from 'src/app/shared/services/webengage.service';
import { SubSink } from 'subsink';
@Component({
  selector: 'app-style-work-cities',
  templateUrl: './style-work-cities.component.html',
  styleUrls: ['./style-work-cities.component.scss']
})
export class StyleWorkCitiesComponent implements OnInit, OnDestroy {

  cityList =[];

  popularCitiesSlideArr: PopularLocations[] | string;
  imageLoader = false;
  popularCityDataAvailable = false;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['', ''],
    responsive: {
      0: {
        items: 3
      },
      375: {
        items: 4
      },
      475:{
        items:5
      },
      760: {
        items: 5
      },
      1000: {
        items: 5
      }
    },
    nav: true
  };
  private subs = new SubSink();
  constructor(
      private restApiService: RestApiService,
      private security: Security,
      private appRouterService: AppRouterService,
      private webengageService: WebEngageService
  ) {}

  ngOnInit(): void {
    this.popularCities();
  }

  popularCities(): void {
    this.subs.add(
      this.restApiService.popularCity$.subscribe((popularCities) => {
        if(!popularCities || popularCities.length === 0) {
          let popularLocationData = null;
          if(localStorage.getItem('popularLocations')) {
            popularLocationData = this.security.decryptData(localStorage.getItem('popularLocations'));
          }
          if(!popularLocationData || popularLocationData.length === 0){
            this.popularCityDataAvailable = true;
          } else {
            this.popularCitiesSlideArr = popularLocationData;
            this.popularCityDataAvailable = false;
          }
        } else {
          this.popularCitiesSlideArr = popularCities;
        }
      })
    );
    if(this.popularCityDataAvailable) {
      this.imageLoader = true;
      this.subs.add(
        this.restApiService.getPopularCities().subscribe((popularCity) => {
          this.popularCitiesSlideArr = popularCity;
          this.imageLoader = false;
        })
      );
    }
  }

  routeToPopularLocations(city: PopularLocations): void {
    this.appRouterService.routeToSpaceExplorationUsingCitySlug(city);
    this.webengageService.trackEvent(WEBENGAGE_EVENTS.City_Selected,{
      cityName:city.name, cityId:city.id
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
