import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PopularLocations } from 'src/app/shared/interface';
import { RestApiService } from 'src/app/shared/services/common-services/rest-api.service';
import { SubSink } from 'subsink';
import { Cities } from 'src/app/shared/interface/cities';
import { AppRouterService } from 'src/app/shared/services/router/app-router.service';
import { MatDialog } from '@angular/material/dialog';
import { MoreCitiesComponent } from '../../more-cities/more-cities.component';
import { RequestAQuoteComponent } from '../../request-a-quote/request-a-quote.component';
import { WebEngageService } from 'src/app/shared/services/webengage.service';
import { EventCategory, EventSubCategory } from 'src/app/shared/interface/webengage';
import { WEBENGAGE_EVENTS } from 'src/app/Helper/constant';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  popularCities$: Observable<PopularLocations[]>;
  popularLocationsData: PopularLocations[] = [];
  selectedLocation: PopularLocations;
  postalCode: number = null;
  searchCity: string = null;
  tierSelected: string = null;
  citiesList: Cities[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exploreCitiesData: PopularLocations[] = [];//string is using for data getting localstorage
  exploreSpaceMenuShow = false;
  imageLoader = true;
  private subs = new SubSink();
  constructor(
    private elRef: ElementRef,
    private restService: RestApiService,
    private webengageService:WebEngageService,
    private dialog: MatDialog,
    private appRouterService: AppRouterService,
  ) { }

  ngOnInit(): void {
    this.getPopularCitiesList();
  }
  getPopularCitiesList(): void {
    this.subs.add(
      this.restService.getPopularCities()
        .subscribe((res: PopularLocations[]) => {
          this.exploreCitiesData = res;
        })
    );
  }

  closeCollapse(categoryId=null, category=null,subCategory=null): void {
    const collapseRef = this.elRef.nativeElement.querySelectorAll('#navbarSupportedContent');
    const innerWidth = window.innerWidth;
    if(innerWidth < 992){
      collapseRef[0].className='collapse navbar-collapse';
    }

    if(category && categoryId && !subCategory){
      this.webEngageCategoryEvent({categoryId, categoryName: category});
    }else if(category && subCategory){
      this.webEngageSubCategoryEvent({categoryName:category, subCategoryName:subCategory})
    }
  }

  blogAndResources(): void{
    window.open('https://blogs.stylework.city/');
    this.closeCollapse('4','Resources','Blog & Resources');
  }
  channelPartner(): void{
    this.closeCollapse('2','Our Partners','Channel Partner');
    window.open('https://forms.gle/6KVgHsWqZSwQ8FS46');
  }

  routeSpacesExplorationByToPopularLocations(city: PopularLocations): void {
    this.closeCollapse();
    this.appRouterService.routeToSpaceExplorationUsingCitySlug(Object.assign(city));
    this.webEngageSubCategoryEvent({categoryName:'Explore Spaces', subCategoryName:city.name})
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MoreCitiesComponent,{
      width:'98vw',
    position:{top:'0px',},
      disableClose: true,
      data:{ route: true}
    });
    dialogRef.afterClosed().subscribe(
      res => {
        this.closeCollapse();
        if(res){
          this.webEngageSubCategoryEvent({categoryName:'Explore Spaces', subCategoryName:res.data.name})
        }
      }
    );
  }

  closeExploreMenu(categoryId:string, categoryName:string):void{
    console.log(this.exploreSpaceMenuShow, categoryName)
    this.exploreSpaceMenuShow = false;
    this.webEngageCategoryEvent({categoryId, categoryName:categoryName});
    
  }

  exploreSpace(): void{
    this.exploreSpaceMenuShow = !this.exploreSpaceMenuShow;
    if(this.exploreSpaceMenuShow){
      this.webEngageCategoryEvent({categoryId:'1', categoryName:'Explore Spaces'});
    }
  }

  webEngageCategoryEvent(event:EventCategory): void {
    this.webengageService.trackEvent(WEBENGAGE_EVENTS.Category_Selected,event);
  }

  webEngageSubCategoryEvent(event:EventSubCategory): void {
    this.webengageService.trackEvent(WEBENGAGE_EVENTS.Subcategory_Selected,event)
  }

  requestAQuoteDialog(): void {
    this.closeCollapse('5','Need a workspace?');
    this.dialog.open(RequestAQuoteComponent,{
      position:{top:'70px',},
      disableClose: true,
    });
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


}
