import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RestApiService } from 'src/app/shared/services/common-services/rest-api.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() hideFooterClass = false;
  @Input() heading = '';
  @Input() subheading = '';
  closeIntervalHandlerForProgressBar: ReturnType<typeof setInterval>;
  testimoList = [];
  index = 0;
  loading = false;
  constructor(public restApi: RestApiService) {}
  ngOnInit(): void {
    this.loading = true;
    this.restApi
      .getTestimonials()
      .subscribe((testimoListData) => {
        this.testimoList = testimoListData;
        this.loading = false;
      });
    this.closeIntervalHandlerForProgressBar = setInterval(
      this.progressBar,
      2000
    );
  }

  progressBar = (): void => {
    const x = document.getElementById('progress-circle-border');
    const count = 0;
    if (
      count === 0 &&
      !x.classList.contains('percentage-50') &&
      !x.classList.contains('percentage-100')
    ) {
      x.classList.add('percentage-50');
    }
   
    else if (x.classList.contains('percentage-50')) {
      x.classList.add('percentage-100');
      x.classList.remove('percentage-50');
    }
    

    else if (x.classList.contains('percentage-100')) {
      if (this.index < this.testimoList.length - 1) {
        this.index++;
      } else {
        this.index = 0;
      }
      x.classList.remove('percentage-100');
    }
  };

  getIndex(): number {
    return this.index;
  }

  ngOnDestroy(): void {
    if (this.closeIntervalHandlerForProgressBar) {
      clearInterval(this.closeIntervalHandlerForProgressBar);
    }
  }
}
