import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WEBENGAGE_EVENTS } from 'src/app/Helper/constant';
import { amountNotAvailable } from 'src/app/Helper/enums';
import { Helper } from 'src/app/Helper/helpers';
import { MoreImagesModalComponent } from 'src/app/components/more-images-modal/more-images-modal.component';
import { Amenity, Spaces } from 'src/app/shared/interface';
import { MoreImages } from 'src/app/shared/interface/more-images';
import { SpaceService } from 'src/app/shared/services/spaces/space.service';
import { WebEngageService } from 'src/app/shared/services/webengage.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-d-similar-workspace',
  templateUrl: './d-similar-workspace.component.html',
  styleUrls: ['./d-similar-workspace.component.scss']
})
export class DSimilarWorkspaceComponent implements OnInit, OnDestroy {
  amountMessage = amountNotAvailable;
  helper = Helper;
  loading = false;
  @Input() sessionId = null;
  @Input() spaceId: string;
  @Input() listMapBtn = false;
  @Input() membershipSelection = false;
  mapWidth = 'auto';
  mapHeight = '900px';
  similarWorkPlaces: Spaces[]= []
  private subs = new SubSink();
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private spaceService: SpaceService,
    private webengageService: WebEngageService
  ) { }


  ngOnInit(): void {
    this.getSimilarSpaces(this.spaceId)
   }

  getSimilarSpaces(spaceId: string): void {
    this.loading = true;
    this.subs.add(
      this.spaceService.similarSpacesList(spaceId).subscribe((res: any) => {
        this.loading = false;
        res.spaceData.forEach( res => {
          res.badge = this.helper.getSpaceCategoryBadge(res?.spaceCategory?.categoryName)
         })
        this.similarWorkPlaces = res.spaceData;
      },
      (err: any) => {
        this.loading = false;
      }
      )
    )
  
  }
  routeToSpaceDetails(space: Spaces): void {
    this.webengageService.trackEvent(WEBENGAGE_EVENTS.Similar_Workspaces_Visited,{
      workSpaceName: space.name,
      workSpaceId: space.id,
      location: space.address
    })
    window.scrollTo(0, 0);
    if (this.membershipSelection) {
      this.router.navigate([`/membership/${this.sessionId}/space-details/${space.id}`],{
        queryParams: {
          sessionId: this.sessionId,
          spaceId: space?.id,
          spaceSlug: space?.slug,
        }
      });
    } else {
      this.router.navigate([`/coworking-spaces/${space.cityInfo?.slug}/${space.areaInfo.slug}/${space.slug}`]);
    }
  }
  getMoreAmenities(amenitiesArr: Amenity[]): string[] {
    return amenitiesArr.slice(4).map(amenity => amenity.name);
  }

  moreImages(moreImage: MoreImages): void {
    this.dialog.open<MoreImagesModalComponent, MoreImages>(MoreImagesModalComponent, {
      disableClose: true,
      data: moreImage
    });
  }
  ngOnDestroy(): void {
   this.subs.unsubscribe();
  }

}
