<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousle-dots"></div>
    <div class="carousel-inner">
        <div>
            <div class="carousel-item" [ngClass]="{'active':i==0}" *ngFor="let test of testimo, let i = index">
            <h5>{{test.name}}</h5>
            <p>{{test.description}}</p>      
            </div>
        </div>
      
    </div>
</div>