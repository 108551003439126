import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bootstrap-loader',
  templateUrl: './bootstrap-loader.component.html',
  styleUrls: ['./bootstrap-loader.component.scss']
})
export class BootstrapLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
