export const home = {
  descriptionId:'description',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  keywordsId: 'keywords',
  title: 'Best Coworking and Office Spaces for Rent | Stylework ',
  description: 'Find unconventional coworking spaces with Stylework. Boost your productivity and work in a workspace, meetings room, private cabins, High Speed Wi-Fi, and all amenities, that fits your style. ',
  keywords: 'co working spaces, working space, co working, office space, coworking space near me'
};

export const aboutUs = {
  descriptionId:'description',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  keywordsId: 'keywords',
  title: 'About Stylework: Best Coworking Space Aggregator in India',
  description: 'Find your perfect coworking space in across India with Stylework, the top aggregator of premium workspaces. Explore now!',
  keywords: 'Office for rent, Stylework, space solutions, office on rent near me, coworking near me, Co working Space Agrregator'
};
export const enterprise = {
  descriptionId:'description',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  keywordsId: 'keywords',
  title: 'FlexBoard: A Hybrid Workplace Platform to Manage Hybrid  Employees | Stylework',
  description: "Stylework's FlexBoard is built for Enterprise growth. FlexBoard is a SaaS platform designed to help businesses manage their hybrid employees efficiently. Schedule a demo now!",
  keywords: "flexboard, hybrid workplace platform, Stylework's Flexboard"
};
export const contactUs = {
  descriptionId:'description',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  keywordsId: 'keywords',
  title: 'Contact Us for Coworking Spaces in Best Prices | Stylework',
  description: 'Contact us today to learn more about our flexible and modern workspaces. Stylework offers the best prices for office spaces. Get in touch today!',
  keywords: 'Coworking Space, Shared Office Space, Coworking Space, Cafe With Wifi,'+
  'Office Space For Rent, Quiet Work Cafe, Shared Office Space, Coworking Cafe, Meeting Room, Coworking Space'
};
export const blogAndResource = {
  descriptionId:'description',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  keywordsId: 'keywords',
  title: 'Stylework Blog: News, New Trends for Entrepreneur & Business',
  description: 'Blogs by Stylework-coworking workspaces: An insight into dynamic arena of entrepreneurship,'+
  'coworking, startups. Get info about market trends. Read to know more ',
  keywords: 'Blogs, coworking, entrepreneurship, Startup news, Co working blogs, Stylework blogs'
};
export const coworking = {
  descriptionId:'description',
  keywordsId: 'keywords',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  title: 'Find the Perfect Coworking Spaces in India | Stylework ',
  description: 'Find the perfect coworking space in India with Stylework. We provide a hassle-free experience in finding and booking coworking spaces. Explore Now!',
  keywords: 'Coworking Space, Shared Office Space, Coworking Space, Cafe With Wifi,'+
  'Office Space For Rent, Quiet Work Cafe, Shared Office Space, Coworking Cafe, Meeting Room, Coworking Space',
};
export const spaceExploration = {
  descriptionId:'description',
  keywordsId: 'keywords',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  title: 'Explore the Best Coworking Office Spaces in India | Stylework',
  description: 'Discover 3000+ spaces across India with Stylework. Collaborate, create and connect with like-minded professionals. Book now!',
  keywords: 'Coworking Space, Shared Office Space, Coworking Space, Cafe With Wifi,'+
  'Office Space For Rent, Quiet Work Cafe, Shared Office Space, Coworking Cafe, Meeting Room, Coworking Space',
};
export const spaceDetails = {
  descriptionId:'description',
  keywordsId: 'keywords',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  title: 'Checkout Space Details: Stylework Unconventional Spaces',
  description: 'Know more about all spaces listed with us, Checkout Coworking Space and Shared Office Space.'+
  'Flexible plans, pay per month. Private Cabins, Meeting Rooms and more',
  keywords: 'coworking spaces, working space, coworking, office space, coworking space near me,'+
  'meeting rooms, private cabins, desks, office day pass, rent office for a day',
};
export const faqs = {
  descriptionId:'description',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  keywordsId: 'keywords',
  title: 'FAQs: Solution to common queries about our services | Stylework',
  description: 'Frequently asked questions about Stylework Coworking Aggregator.'+' Find answers to all your queries about our plans, services, memberships or talk to our sales experts.',
  keywords: 'Coworking Space, Wifi ofice spaces, Coworking Space near me,'+
  'Office space With Wifi, Quiet Work Cafe, Shared Office Space, Coworking Cafe, Meeting Room, Desk spaces'
};
export const listYourSpace = {
  descriptionId:'description',
  keywordsId: 'keywords',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  title: 'List Your Office Space Details to Expand Your Business | Stylework',
  description: 'List your workspace details and connect with potential clients today.' + 'Avertise your office space for free by submit offices, cafe, meeting rooms & more. Book now! ',
  keywords: 'adtervise office for rent, advertise coworking space, list your office space,'+
  'coworking space advertise for free, Coworking spaces, Coworking spaces near me'
};

export const icici = {
  descriptionId:'description',
  keywordsId: 'keywords',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  title: 'Up to 15% with ICICI Cards on all bookings + Extra 5% off',
  description: 'Complete your new coworking space booking at Stylework'+
  ' with ICICI bank cards & save 10-15% on bulk bookings additional 5% savings with code DEMO***',
  keywords: 'Coworking booking, Coworking space aggregator, Coworking spaces, Bank offers,'+
  ' Bank offers for real estate, ICICI bank offers, ICICI credit card offers, Stylework offers,'+
  ' Stylework promotional code, Stylework promo code'
};
export const manageSpace = {
  descriptionId:'description',
  keywordsId: 'keywords',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  title: 'Best Managed Office Spaces with Hassel Free Solutions | Stylework',
  description: 'Find your perfect managed office space with Stylework, the managed office space provider for businesses of all sizes. Get fully furnished workspaces with a bespoke design and curated amenities at prime locations across India.',
  keywords: 'managed office space, managed office space provider, fully furnished workspaced'
};
export const membership = {
  descriptionId:'description',
  keywordsId: 'keywords',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  title: 'Join Coworking Membership Plans: Weekly, Monthly, Quarterly | Stylework',
  description: 'Enroll our exclusive coworking membership plans to enjoy the flexibility of coworking space'+' across India. Choose from standard, Silver, Gold or Platinum plans starting from Rs 5500*/month.',
  keywords: 'Coworking membership plans, Flexible membership Plans, Multilocation coworking memberships,'+
  ' Stylework memberships plans, Hybrid working model memberships'
};

export const careers = {
  descriptionId:'description',
  keywordsId: 'keywords',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  title: 'Explore Careers & Job Opportunities at Stylework',
  description:'Discover exciting career opportunities at Stylework.' + 'Join a dynamic team for professional growth & development.',
  keywords: 'jobs at Stylework, career at Stylework,  new jobs at Stylework, future jobs with Stylework'
};

export const thanks = {
  descriptionId:'description',
  robots:'robots',
  noindex:'noindex, nofollow',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title'
  },
  title: ' Thank You | Stylework - Unconventional  Workspaces',
  description: ' Book coworking spaces, private offices, workspaces, meeting or conference rooms'+
  ' near you on Stylwork. Flexible and Scalable Plug & Play workspace at prime location with best deals in India over 3000+ spaces',
};

export const pageNoteFound = {
  descriptionId:'description',
  robots:'robots',
  noindex:'noindex, nofollow',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  title: '404 Page Not Found | Stylework',
  description: `Looking for something? We're sorry. The Web address you entered is not a functioning page on our site.`,
};

export const productSuite = {
  descriptionId:'description',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  keywordsId: 'keywords',
  title: 'Coworking solutions for your organizational needs',
  description: `Corporate Product Suite helps organizations in 
  office-spaces optimization, and better allocation of employees to workspaces, to enhance efficiency, 
  effectiveness, and cost for the present & future through additional analytics & predictive features`,
  keywords: 'Coworking for business, co working spaces, working space, co working, office space, coworking space near me'
};

export const allianceData = {
  descriptionId:'description',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  robots:'robots',
  noindex:'noindex, nofollow',
  keywordsId: 'keywords',
  title: 'Our Partners and Become Our Partners | Stylework',
  description: `Discover our partners and learn how to become one! Connect the Stylework community and access a 
  range of benefits for coworking spaces. Explore now.`,
  keywords: ''
}
export const bookNow = {
  descriptionId:'description',
  keywordsId: 'keywords',
  robots:'robots',
  noindex:'noindex, nofollow',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  title: 'Book Best Coworking Spaces | Stylework',
  description: `Book coworking spaces, private offices, workspaces, and meeting or conference 
  rooms near you. No Brokerage Cost, Flexible Terms. No Hidden Charges. Office Assistance.`,
  keywords: 'coworking spaces, private offices, workspaces, meeting rooms, coworking spaces near me'
};
export const unconventional = {
  descriptionId:'description',
  keywordsId: 'keywords',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  title: 'Unconventional Unions: Exclusive Flex Sync by Stylework',
  description: `Join Unconventional Unions at Flex Sync by Stylework, reshaping collaboration in Flex Space and defining the Future of Work.`,
  keywords: 'unconventional unions'
};
export const demoSpace = {
  descriptionId:'description',
  keywordsId: 'keywords',
  robots:'robots',
  noindex:'noindex, nofollow',
  property:{
    ogDescription:'og:description',
    ogTitle:'og:title',
  },
  title: 'Checkout Space Details: Stylework Unconventional Spaces',
  description: `Know more about all spaces listed with us, Checkout Coworking Space and Shared Office Space.
  Flexible plans, pay per month. Private Cabins, Meeting Rooms and more`,
  keywords: `coworking spaces, working space, coworking, office space, coworking space near me,
  meeting rooms, private cabins, desks, office day pass, rent office for a day`,
};
