<div class="list">
    <ng-container *ngIf="(amenity$ | async) as amenityList">
        <ng-container *ngIf="amenityList.length > 0">
            <ng-container *ngFor="let amenity of amenityList;let i=index;">
                <div class="d-flex justify-content-between flex-row align-items-center" *ngIf="i<5">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value=""
                            [id]="'amenity'+i" (change)="onAddAmenity($event,amenity)">
                        <label class="form-check-label ms-1" [for]="'amenity'+i">
                            {{amenity?.name}}
                        </label>
                    </div>
                    <div>
                        <span class="list-text">({{amenity?.count}})</span>
                    </div>
                </div>
            </ng-container>
            <div class="collapse" id="showmore4" *ngIf="amenityList.length > 5">
                <div class="col-12" *ngFor="let amenity of amenityList;let i=index;">
                    <div class="d-flex justify-content-between flex-row align-items-center" *ngIf="i>4">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                [id]="'amenity'+i" (change)="onAddAmenity($event,amenity)">
                            <label class="form-check-label ms-1" [for]="'amenity'+i">
                                {{amenity?.name}}
                            </label>
                        </div>
                        <div>
                            <span class="list-text">({{amenity?.count}})</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- <ng-container>
            <div class="d-flex justify-content-between flex-row align-items-center" *ngIf="(isLoading$ | async)">
                <app-bootstrap-loader></app-bootstrap-loader>
            </div>
        </ng-container> -->
    </ng-container>
</div>
<div class="d-flex justify-content-end flex-row mt-2" *ngIf="(amenity$ | async)?.length > 5">
    <a (click)="readMore = !readMore" class="link-text" data-bs-toggle="collapse"
        href="#showmore4" role="button" aria-expanded="false"
        aria-controls="showmore4">
        {{readMore ? "Show More" : "Show Less"}}
    </a>
</div>