/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Injectable } from '@angular/core';
import { WindowRefService } from './window.service';
import { EnvConfigurationService, Environment } from 'src/app/Helper/environment-configuration.service';
import { WEBENGAGE_LICENSE_CODE } from 'src/app/Helper/constant';


@Injectable({
  providedIn: 'root',
})
export class WebEngageService {
  config = this.envConfigService.configSubject$;
  constructor(
    private winRef: WindowRefService,
    private envConfigService: EnvConfigurationService,
  ) {}

  initialize(): void {
    if (this.config.value.environment !== Environment.prod) {
        console.log('WebEngage is disabled in this environment.');
      return;
    }

    if (typeof this.winRef.nativeWindow.webengage !== 'undefined') {
      this.winRef.nativeWindow.webengage.init(WEBENGAGE_LICENSE_CODE);
      console.log('WebEngage initialized with license!');
    } else {
      console.warn('WebEngage script is not loaded yet.');
    }
  }

   /**
   * Tracks an event in WebEngage.
   * @param eventName The name of the event to track.
   * @param payload Optional additional data for the event.
   */
   trackEvent(eventName: string, payload?: Record<string, any>): void {
    if (this.config.value.environment !== Environment.prod) {
        console.log('WebEngage is disabled in this environment.');
      return;
    }

    if (typeof this.winRef.nativeWindow.webengage !== 'undefined') {
      this.winRef.nativeWindow.webengage.track(eventName, payload);
      console.log(`Tracked event: ${eventName}`, payload);
    } else {
      console.warn('WebEngage is not initialized. Event not tracked.');
    }
  }

  /**
   * Logs in a user to WebEngage.
   * @param userId The unique identifier for the user.
   */
  loginUser(userId: string): void {
    if (this.config.value.environment !== Environment.prod) {
        console.log('WebEngage is disabled in this environment.');
      return;
    }

    if (typeof this.winRef.nativeWindow.webengage !== 'undefined') {
      this.winRef.nativeWindow.webengage.user.login(userId);
      console.log(`User logged in: ${userId}`);
    } else {
      console.warn('WebEngage is not initialized. User login failed.');
    }
  }

  /**
   * Logs out the current user in WebEngage.
   */
  logoutUser(): void {
    if (this.config.value.environment !== Environment.prod) {
        console.log('WebEngage is disabled in this environment.');
      return;
    }

    if (typeof this.winRef.nativeWindow.webengage !== 'undefined') {
      this.winRef.nativeWindow.webengage.user.logout();
      console.log('User logged out.');
    } else {
      console.warn('WebEngage is not initialized. User logout failed.');
    }
  }

  /**
   * Sets user attributes in WebEngage.
   * @param attributes An object containing user attributes.
   */
  setUserAttributes(attributes: Record<string, any>): void {
    if (this.config.value.environment !== Environment.prod) {
        console.log('WebEngage is disabled in this environment.');
      return;
    }

    if (typeof this.winRef.nativeWindow.webengage !== 'undefined') {
      Object.entries(attributes).forEach(([key, value]) => {
        this.winRef.nativeWindow.webengage.user.setAttribute(key, value);
      });
      console.log('User attributes set:', attributes);
    } else {
      console.warn('WebEngage is not initialized. User attributes not set.');
    }
  }

 
}
