import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { EnvConfigurationService } from './environment-configuration.service';

@Injectable()
export class Security {
  private configSubject = this.envConfigService.configSubject$;

  constructor(private envConfigService: EnvConfigurationService) {}

  encryptData<T>(data: T): string {
    try {
      return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        this.configSubject.value.encryptionSecretKey
      ).toString();
    } catch (e) {
      //do nothjing
    }
  }

  decryptData<Type>(data: Type): Type {
    try {
      const bytes = CryptoJS.AES.decrypt(
        data,
        this.configSubject.value.encryptionSecretKey
      );
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }
}
