import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IdName } from 'src/app/components/filters/filters.service';
import { EnvConfigurationService } from 'src/app/Helper/environment-configuration.service';
import { Amount, FixedMembershipRequestSummary, SeatTypes, V2SpaceSelectionResponse } from 'src/app/shared/interface';

export interface MembershipDuration {
  id: string;
  title: string;
  durationInDays: string;
}
export interface Selected {
name: string;
state: string[];
}

export interface FixedMembershipRequest {
  membershipDurationId: string;
  startDate: Date;
  status: number;
  createdAt: Date;
  updatedAt: Date;
  membershipAmount?: Amount;
  gstAmount?: Amount;
  gstSlab?: number;
  id: string;
  cityId: string;
  spaceId: string;
  seatTypeId: string;
}

export interface SpaceSelectedForFixedMemberhip {
  status: number;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  id: string;
  name: string;
  images: string[];
  address: string;
  amenities: string[];
  location: {
    lng: number;
    lat: number;
  };
  rating: number;
  isBestSafety: boolean;
  numberPeopleWorked: number;
  cityId: string;
  personalSpaceCategoryId: string;
}

export interface FixedMembershipResponse extends FixedMembershipRequest {
  membershipDuration: MembershipDuration;
  city: IdName;
  space: SpaceSelectedForFixedMemberhip;
  seatTypes: SeatTypes[];
}


export interface UserRegistrationRequest {
  firstName: string
  lastName: string
  email: string
  mobile: string
  countryCode: string
  currentCompany: string
  addressLine1: string
  addressLine2: string
  gstin: string
  stateId: string
  cityId: string
  pinCode: string
  otp: string
}

export interface UserRegistrationResponse extends UserRegistrationRequest {
  id: string;
}

export interface ChangeNumberRequest {
  mobile: string;
  countryCode: string;
}
export interface OtpRequest {
  mobile: string;
  sessionId: string
  countryCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  config = this.envConfigService.configSubject$;

  constructor(
    private envConfigService: EnvConfigurationService,
    private http: HttpClient
  ) {}

  getMembershipDurations(): Observable<MembershipDuration[]> {
    return this.http.get<MembershipDuration[]>(
      `${this.config.value.apiUrl}v2/membership-durations`
    );
  }

  postFixedMembershipRequest(
    membershipRequest: Partial<FixedMembershipRequest>
  ): Observable<FixedMembershipRequest> {
    return this.http.post<FixedMembershipRequest>(
      `${this.config.value.apiUrl}v2/fixed-membership-requests`,
      membershipRequest
    );
  }

  getFixedMembershipRequestDetailsBySessionId(
    sessionId: string
  ): Observable<V2SpaceSelectionResponse> {
    return this.http.get<V2SpaceSelectionResponse>(
      `${this.config.value.apiUrl}v2/fixed-membership-request/${sessionId}`
    );
  }

  getFixedMembershipRequestDetailsSummaryBySessionId(requestId: string): Observable<FixedMembershipRequestSummary> {
    return this.http.get<FixedMembershipRequestSummary>(`${this.config.value.apiUrl}v2/fixed-membership-request/${requestId}/summary`)
  }



  patchFixedMembershipRequest(
    membershipId: string,
    spaceId: string,
  ): Observable<void> {
    return this.http.patch<void>(
      `${this.config.value.apiUrl}v2/fixed-membership-request/${membershipId}/add/${spaceId}/space`,{}
    );
  }

  patchFixedMembershipRequestWithMembershipId(requestId: string, membershipId: string) : Observable<void>{
    return this.http.patch<void>(`${this.config.value.apiUrl}v2/fixed-membership-requests/${requestId}/membership/${membershipId}`,{})
  }

  patchSpaceInFixedMembershipRequest(membershipId: string): Observable<void> {
    return this.http.patch<void>(
      `${this.config.value.apiUrl}v2/fixed-membership-requests/${membershipId}/space`,
      {}
    );
  }

  getStatesData(): Observable<IdName[]> {
    return this.http.get<IdName[]>(`${this.config.value.apiUrl}states`, {});
  }

  getCitiesData(stateId: string): Observable<IdName[]> {
    return this.http.get<IdName[]>(
      `${this.config.value.apiUrl}states/${stateId}/cities`, {}
    );
  }

  getOtpForFixedMembership(otpBody: OtpRequest): Observable<UserRegistrationResponse> {
    return this.http.get<UserRegistrationResponse>(`${this.config.value.apiUrl}v2/fixed-membership-request/request-otp/${otpBody?.mobile}/${otpBody?.sessionId}?countryCode=${otpBody?.countryCode}`)
  }

    registerUserFromFixedMembership(
    membershipRequestId: string,
    userBody: UserRegistrationRequest
  ): Observable<UserRegistrationResponse> {
    return this.http.post<UserRegistrationResponse>(
      `${this.config.value.apiUrl}v2/fixed-membership-requests/${membershipRequestId}/user/register`,
      userBody
    );
  }


  resendOtp(
    userId: string,
  ): Observable<void> {
    return this.http.patch<void>(
      `${this.config.value.apiUrl}user/${userId}/resend-otp`,
      {}
    );
  }

  verifyOtp(
    userId: string,
    otp: number
  ): Observable<void> {
    return this.http.patch<void>(
      `${this.config.value.apiUrl}user/${userId}/verify-otp/${otp}`,
      {}
    );
  }

  changeMobileNumber(
    userId: string,
    userBody: ChangeNumberRequest
  ): Observable<void> {
    return this.http.patch<void>(
      `${this.config.value.apiUrl}user/${userId}/change-number`,
      userBody
    );
  }

  downLoadMobileAppLink(
    mobileNo: number,
  ): Observable<void> {
    return this.http.get<void>(
      `${this.config.value.apiUrl}request-link/${mobileNo}`
    );
  }
}
