<div class="get-started position-relative">
    <div class="container">
      <img class="corner-img" [src]="remote"  alt="corner image" loading="lazy">
      <div class="row">
        <div class="col col-12 col-md-10 col-lg-8">
          <h2 class="fs-biger">Flexible <b>membership </b> plans for single & multilocation <b style="margin-left:0px">Coworking spaces</b>
          </h2>
          <p class="pe-lg-5 me-lg-2 fs-medium line-h5">For ambitious companies that focus on productivity and desire flexibility in their   workspaces, Stylework as a coworking aggregator in India makes it possible with easy-to-book membership   plans tailored to your particular requirements.</p>
          <div class="row">
            <div class="col col-12 col-md-6">
              <ul class="col-lg-12 col-7 ps-0">
                <li>
                 <div class="d-flex">
                   <div class="col-1 me-2">
                    <img class="img-tick-icon" [src]="vector" alt="icon">
                   </div> 
                   <div class="col-10">
                    <p class="m-0 ms-1 mt-1">Fixed/multilocational workspace access</p>
                   </div>
                 </div>
                </li>
                <li>
                  <div class="d-flex">
                    <div class="col-1 me-2">
                      <img class="img-tick-icon" [src]="vector" alt="icon">
                    </div>
                    <div class="col-10">
                      <p class="m-0 ms-1 mt-1">Fully-customizable offerings (seats, employees, and many more)</p>
                    </div>
                   </div>
                  
                </li>
              </ul>
            </div>
            <div class="col col-12 col-md-6">
              <ul class="col-lg-12 col-7 ps-0">
                <li>
                  <div class="d-flex">
                    <div class="col-1 me-2">
                      <img class="img-tick-icon" [src]="vector" alt="icon">
                    </div>
                    
                    <div class="col-10">
                      <p class="m-0 ms-1 mt-1"> Hassle-free access control</p>
                    </div>
                    
                   </div>
                </li>
                <li>
                  <div class="d-flex">
                    <div class="col-1 me-2">
                      <img class="img-tick-icon" [src]="vector" alt="icon">
                    </div>
                    <div class="col-10">
                      <p class="m-0 ms-1 mt-1"> Offerings that suits your budget, or specific brands of your own choice</p>
                    </div>
                   
                   </div>
                  
                  
                </li>
              </ul>
            </div>
          </div>
          <a href="https://stylework.app.link/website" target="_blank" class="btn btn-primary-base dwnld-btn"><img src="assets/images/app-store.svg" alt="app store" loading="lazy">
            <img src="assets/images/play-store-white.svg" alt=" play store" loading="lazy">Download Our App</a>
        </div>
        <div class="col-12 col-lg-11  mt-5 pt-lg-5">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <div class="vertical-line"></div>
              <div class="content ms-3">
                  <p class="heading">80%</p>
                  <p class="sub-heading col-lg-7 ">Of employees consider telework as a job perk</p>
              </div>
          </div>
          <div class="d-flex">
            <div class="vertical-line"></div>
            <div class="content ms-3">
                <p class="heading">46%</p>
                <p class="sub-heading col-lg-7">Of companies that allow telework has reduced attrition</p>
            </div>
        </div>
        <div class="d-flex">
          <div class="vertical-line"></div>
          <div class="content ms-3">
              <p class="heading">95%</p>
              <p class="sub-heading col-lg-7">Of employers witness high employee retention</p>
          </div>
      </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
