import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VerifyYourNumberComponent } from '../verify-your-number/verify-your-number.component';
@Component({
  selector: 'app-book-a-free-tour',
  templateUrl: './book-a-free-tour.component.html',
  styleUrls: ['./book-a-free-tour.component.scss']
})
export class BookAFreeTourComponent implements OnInit {

  constructor(
    private dialogRef: MatDialog
  ) { }

  ngOnInit(): void {
  }

  verifyYourNumberDialog(): void {
    this.dialogRef.open(VerifyYourNumberComponent, { disableClose: true });
  }


}
