<div class="container">
  <section class="mt-lg-5 mb-lg-5">
    <h2 *ngIf="paginationShow" class="col-lg-10 col-12 mb-0 fs-biger ">Learn more about us through our blog posts</h2>
    <h2 *ngIf="!paginationShow" class="col-lg-7 col-12 mb-0 ps-2 fs-biger">Latest News About us, Coworking trends, New technology </h2>
    <div class="row">
      <div class="masonry-item col-lg-4 col-md-6 col-sm-6" *ngFor="let item of blogList?.blogs; , let i = index">
        <div *ngIf="i == 1 || i == 4 " class="pt-lg-5"></div>

        <div class="masonry-box  p-3 mt-lg-5 mt-3 rounded tab" (click)="blogDetails(item?.blogPrimaryUrl)">
         
          <section class="image-section">
            <div class="image-wrapper">
              <img [src]="item?.image" alt="Your Image">
            </div> 
          </section>
          <p class="mt-2 mb-1"><small class="text-muted">{{item?.createdAt|date}}</small></p>
          <h2 class="mb-2 fs-larger" [title]="item?.title">{{item?.title.length >40 ? item?.title.substring(0,40)+'...' : item?.title}}</h2>
          <p class="mb-0">{{item?.shortDescription.length > 150 ? item?.shortDescription.substring(0,150)+'...': item?.shortDescription}}</p>
        </div>
      </div>
    </div>
    <div *ngIf="isLoading">
      <div class="text-center text-dark">
        <app-bootstrap-loader></app-bootstrap-loader>
      </div>
    </div>
    <ng-container *ngIf="blogList.blogs.length > 0">
      <div class="d-flex justify-content-center mt-5" *ngIf="paginationShow">
        <nav aria-label="Page navigation">
          <ul class="pagination">
            <li class="page-item" (click)="previousBtn()">
              <a class="page-link " aria-label="Previous">
                <span class="icon" aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="page-item"  *ngFor="let links of arrOfPaginationLinks;let i=index;">
              <a [ngClass]="i == 0 ? 'page-link active' : 'page-link'" 
              (click)="paginationBtn(links)">{{links}}</a>
            </li>
            <li class="page-item" (click)="nextBtn()">
              <a class="page-link text-active" aria-label="Next">
                <span class="icon" aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </ng-container>

  </section>
  <div *ngIf="blogList.blogs.length == 0 && !isLoading">
    <div class="text-center text-dark">
      <h1>Sorry...No Blogs Found!!</h1>
    </div>
  </div>
  
</div>