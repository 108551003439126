import { Component,Inject,OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MembershipService, UserRegistrationRequest } from 'src/app/pages/membership/memebership.service';
import { SweetAlertMessageService } from 'src/app/shared/services/sweet-alerts-message/sweet-alert-message.service';
import { SubSink } from 'subsink';
import { ChangeNumberComponent } from '../change-number/change-number.component';
export interface RegisterUserDataFixedMembership {
  userData:UserRegistrationRequest
  sessionId:string
}

@Component({
  selector: 'app-verify-your-number',
  templateUrl: './verify-your-number.component.html',
  styleUrls: ['./verify-your-number.component.scss']
})
export class VerifyYourNumberComponent implements OnInit, OnDestroy{
  userData: UserRegistrationRequest
  sessionId: string
  otp: number ;
  otpEntered = false;
  config = {
    allowNumbersOnly: false,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
  };
  isOtpVerified = false;
  loadOtpVerifyBtn = false;
  loadResendOtpText = false;
  private subsink = new SubSink();
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<VerifyYourNumberComponent>,
    private swalService: SweetAlertMessageService,
    private membershipService: MembershipService,
    @Inject(MAT_DIALOG_DATA) private data: RegisterUserDataFixedMembership
  ) { }

  ngOnInit(): void {
    this.userData = this.data?.userData;
    this.sessionId = this.data?.sessionId;
    
  }

  saveNewMobileNo(event: string): void {
    this.userData.mobile = event;
  }

  changeMobileNumber(): void {
    this.closeDialog()
    // const dialogRef = this.dialog.open<ChangeNumberComponent,UserRegistrationResponse>(ChangeNumberComponent,{
    //   disableClose: true,
    //   data: this.userData,
    // });
    // dialogRef.afterClosed().subscribe((res) =>{
    //   if(res.data !== null){
    //     this.userData.mobile = res.data;
    //   }
    // });
  }

  onOtpChange(otp: number): void{
    this.otp = otp;
    if(this.otp.toString().length === 4){
      this.otpEntered = true;
      this.userData.otp = this.otp.toString();
      this.loadOtpVerifyBtn = true;
      this.subsink.add(
        this.membershipService.registerUserFromFixedMembership(this.sessionId, this.userData)
          .subscribe(
            res => {
              this.loadOtpVerifyBtn = false;
              this.closeDialog();
              this.router.navigate(['/thank-you']);
            }, err => {
              this.closeDialog();
              this.swalService.locationAlertDialog(err?.error?.error?.message || 'Some error occured');
              this.loadOtpVerifyBtn = false;
            }
          )
      );
    }else{
      this.otpEntered = false;
    }
  }

  resendCode(): void {
    this.loadResendOtpText = true;
    this.subsink.add(
      this.membershipService.getOtpForFixedMembership(
        {
          mobile: this.userData.mobile,
          sessionId: this.sessionId,
          countryCode: this.userData.countryCode
        }
      )
        .subscribe(
          res => {
            this.loadResendOtpText = false;
            this.swalService.successAlertDialog('Otp resent successfully');
          }, err => {
            this.loadResendOtpText = false;
            this.swalService.locationAlertDialog(err?.error?.error?.message || 'Some error occured!');
          }
        )
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

}
