import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CommonComponentsModule } from 'src/app/components/common/common-components.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SharedComponentsModule } from 'src/app/components/shared.moule';
import { MatDialogModule } from '@angular/material/dialog';
import { HomePageComponent } from './home-page/home-page.component';
import { BannerComponent } from './banner/banner.component';
import { StyleWorkStatsComponent } from './style-work-stats/style-work-stats.component';
import { WhyCoWorkingComponent } from './why-co-working/why-co-working.component';
import { WorkspacesComponent } from './workspaces/workspaces.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { StyleWorkSpaceComponent } from './style-work-space/style-work-space.component';
import { UnconventionalBenefitsComponent } from './unconventional-benefits/unconventional-benefits.component';
import { WorkspaceFiltersComponent } from './workspace-filters/workspace-filters.component';
import { WorkspaceListComponent } from './workspace-list/workspace-list.component';
import { MediaTalkAboutUsModule } from "../../components/media-talk-about-us/media-talk-about-us.module";


@NgModule({
    declarations: [
        HomePageComponent,
        BannerComponent,
        StyleWorkStatsComponent,
        WhyCoWorkingComponent,
        WorkspacesComponent,
        GetStartedComponent,
        StyleWorkSpaceComponent,
        UnconventionalBenefitsComponent,
        WorkspaceFiltersComponent,
        WorkspaceListComponent
    ],
    exports: [
        StyleWorkStatsComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        FormsModule,
        MatDialogModule,
        ReactiveFormsModule,
        SharedComponentsModule,
        GooglePlaceModule,
        CarouselModule,
        LazyLoadImageModule,
        MediaTalkAboutUsModule
    ]
})
export class HomeModule {}
