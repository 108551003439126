<div class="pt-md-4 pt-2">
    <h2>
        Property Surroundings
    </h2>
    <div class="mt-3 row" *ngIf="nearByLandmarks.length > 0">
        <div class="col-6 mb-4" *ngFor="let nearby of nearByLandmarks">
            <div class="d-flex">
                <div>
                    <img src="{{nearby?.type?.icon}}" alt="{{nearby?.name}}" height="auto"
                        width="20px" (error)="onHandlingNearByImgError($event)">
                </div>
                <div class="ms-3">
                    <p class="description-text">{{nearby?.name}}({{nearby?.approxDistance}})</p>
                </div>
            </div>
        </div>
    </div>
    
</div>