<img class="float-end p-3 tab" (click)="dialogRef.close()" src="assets/icons/Cancel.svg" alt=" cancel" title="close">
<div class=" mt-md-5 mt-4 pt-2 ms-md-4 ms-1 ps-2 pe-md-0 pe-2">
    <p class="fs-larger fw-700">{{title}}</p>
    <p class="fs-medium fw-400 text-light-dark col-md-10">{{description}}</p>

    <div class=" mt-md-5 mt-4 pt-2 mb-md-4 pb-3">
        <div class="btn btn-primary-base px-md-4 px-3 py-md-3 py-2 fs-regular" (click)="cancel()">
        <p class="fs-regular m-0">No, Keep Editing </p>
    </div>
    <div class="btn ms-md-5 ms-3 fs-regular" (click)=" confirm()" ><p class="fs-regular m-0">Yes, Go Back</p></div>
    </div>
</div>