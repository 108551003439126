/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogsResponse } from 'src/app/shared/interface';
import { RestApiService } from 'src/app/shared/services/common-services/rest-api.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-masonry',
  templateUrl: './masonry.component.html',
  styleUrls: ['./masonry.component.scss'],
})
export class MasonryComponent implements OnInit, OnDestroy {
    @Input() paginationShow: boolean ;
    pageCount = 1;
    pathName='/';
    resultCount = 6;
    pageNo = 0;
    blogList: BlogsResponse = {
      blogs: [],
      count: 0
    };
    imageLoader= true;
    isLoading = false;
    arrOfPaginationLinks: number[] = [1,2,3,4,5];
    private subs = new SubSink();
    constructor(
      public restApi: RestApiService,
      private elRef: ElementRef,
      private router: Router,
      private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
      this.pathName = location.pathname;
      this.route.queryParams.subscribe((params: {page: string}) => {
        if(params && params.page) {
          this.getBlogs((+params.page), this.resultCount);
        } else {
          this.getBlogs(this.pageNo, this.resultCount);
        }
      });
    }

    onErrorHandling(event: { target: { src: string } }): void {
      event.target.src = 'assets/icons/blog-details/blog-default.png';
    }

    blogDetails(title: string): void {
      void this.router.navigate([`/blog/${title}`]);
    }

    getBlogs(page: number, count: number): void {
      this.isLoading = true;
      this.subs.add(
        this.restApi.getBlogs(page,count).subscribe(
          (blogData: BlogsResponse) => {
            this.isLoading = false;
            this.blogList = blogData;
            if(this.blogList.count < count*this.arrOfPaginationLinks.length) {
              const countOfPaginationLinks = Math.ceil(this.blogList.count/ count);
              this.arrOfPaginationLinks = [];
              for(let i = 1; i <= countOfPaginationLinks; i++) {
                this.arrOfPaginationLinks.push(i);
              }
            }
          }
        )
      );
    }

    paginationBtn(index: number): void {
      const btnRef = this.elRef.nativeElement.querySelectorAll('.page-link');
      for(let i = 0; i < btnRef.length; i++)
      {
        if(i === index){
          this.pageCount = i;
          btnRef[i].className = 'page-link active';
        }else if(i === 0 || i === btnRef.length-1){
          continue;
        }else{
          btnRef[i].className = 'page-link';
        }
      }
      this.router.navigate(['blogs-and-resources'],{ queryParams: {page: index-1}});
    }

    previousBtn(): void{
      const btnRef = this.elRef.nativeElement.querySelectorAll('.page-link');
      btnRef[0].className = 'page-link text-active';
      btnRef[btnRef.length - 1].className = 'page-link';
      if(this.pageCount > 1){
        this.pageCount -= 1;
        this.paginationBtn(this.pageCount);
      }
    }

    nextBtn(): void{
      const btnRef = this.elRef.nativeElement.querySelectorAll('.page-link');
      btnRef[0].className = 'page-link ';
      btnRef[btnRef.length - 1].className = 'page-link text-active';
      if(this.pageCount < btnRef.length-2){
        this.pageCount += 1;
        this.paginationBtn(this.pageCount);
      }
    }

    ngOnDestroy(): void {
      this.subs.unsubscribe();
    }
}
