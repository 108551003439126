<div class="search-space-container rounded mt-lg-4 mt-2 p-lg-3 p-2">
    <div class="row">
        <div class="seach-space py-md-3 py-1 pe-lg-5 col-lg-6 col-md-6 col-12">
            <div class="mb-md-3 mb-2 margin-top">
                <div class="tab float-end d-block d-md-none" (click)="collapse = !collapse" data-bs-toggle="collapse"
                    data-bs-target="#searchSection" aria-expanded="false" aria-controls="searchSection">
                    <img [class]="collapse ? 'rotate':''" src="assets/icons/Upwards-arrow.png" alt="arrow">
                </div>
                <h1 class="fs-regular">Search in <span class="text-primary-base">
                        {{helper.convertSlugToString(searchSpaceQuery.citySlug) || 'India' | titlecase}}</span> ||
                    Choose another <span class="text-primary-base tab" (click)="moreCities()">City</span></h1>
            </div>
            <div class="searchbox-container ">
                <div
                    [class]="mform.value.searchToken  !== null && isSearboxTableOpen  ? 'searchbox d-flex align-items-center justify-content-between':'searchbox-default justify-content-between d-flex align-items-center'">
                    <div [class]="mform.value.searchToken !== null ? 'd-flex ':'d-flex ' ">

                        <form [formGroup]="mform">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img class="ms-1" src="assets/icons/Search.svg" alt="search">
                                </div>

                                <div>
                                    <input #searchBox class="custom-input search-gap w-100 "
                                        formControlName="searchToken" (keyup)="getInputValue()" type="text"
                                        [placeholder]="'Search our spaces in ' + setSearchPlaceholder()" />
                                </div>
                            </div>
                            <input type='hidden' id="zc_gad" name="zc_gad" value="" />
                        </form>
                    </div>
                    <div>
                        <img *ngIf="mform.value.searchToken  !== null" (click)="resetSearchToken()" class="tab me-2"
                            src="assets/icons/clear-search.svg" alt="" title="Clear">
                    </div>
                </div>
                <div *ngIf="mform.value.searchToken  !== null && searchSpaceList.length && isSearboxTableOpen "
                    class="searchbox-table w-100 pt-md-3 pt-2">
                    <div class="d-flex  w-100 pb-3 tab" (click)="routeToSpaceDetais(space)"
                        *ngFor="let space of searchSpaceList; let spaceIndex = index">
                        <div class="">
                            <img [src]="space.images[0]" height="40" width="40"
                                (error)="$event.target.src = 'assets/images/imgs1.png'" [alt]="space.name">
                        </div>
                        <div
                            [class]=" searchSpaceList.length - 1 != spaceIndex  ?'ms-2 border-set w-100 pb-2 pe-5':'ms-2 w-100 pe-5'">
                            <p class="fs-regular fw-500 font-family text-dark m-0">{{space.name}}</p>
                            <p class="fs-small fw-500 font-family text-tertiary-grey m-0 text-truncate"
                                [title]="space.address">
                                {{ space.address }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-lg-6 col-md-6 col-12 ps-lg-5">
            <div class="collapse show" id="searchSection">
                <div class="mb-3">
                    <h2 class="fs-regular">Other places to work at:</h2>
                </div>
                <div class="ms-2">
                    <app-style-work-cities></app-style-work-cities>
                </div>
            </div>

        </div>
    </div>
</div>