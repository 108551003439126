
import { FormGroup } from '@angular/forms';
import { Amount, SpaceImage } from '../shared/interface';
import { SpaceTypeImages } from './constant';

export class Helper {
  static scheduleDemo(): void {
    let height = 600;
    let width = 800;
    let left = (screen.width - width) / 2;
    let top = (screen.height - height) / 2;
    let url = "https://calendly.com/sw-demo/30min";
    window.open(url, "center window", 'resizable = yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left)
  }

  static getColonTime(time: string | number): string {
    const hours = +time / 100 + '';
    const min = (+time % 100) + '';
    if (min.length === 1) {
      return hours.split('.')[0] + ':' + min + '0';
    } else {
      return hours.split('.')[0] + ':' + min;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static counter(id: any, start: any, end: any, duration: any): void {
    const obj = document.getElementById(id);
    let current = start;
    const range = end - start;
    const increment = end > start ? 1 : -1;
    const step = Math.abs(Math.floor(duration / range));
    const timer = setInterval(() => {
      current += increment;
      obj.textContent = current + '%';
      if (current === end) {
        clearInterval(timer);
      }
    }, step);
  }

  static getSpaceCategoryBadge(categoryName: string):string {
    return SpaceTypeImages[categoryName.toLowerCase()]
  }
  
  static setSpaceImages(spaceImages: SpaceImage[]): string[] {
    if (!spaceImages) {
      return [];
    }
    const images: string[] = [];
    spaceImages?.forEach(spaceImage => {
      spaceImage?.images?.forEach(image => {
        images.push(image.Location);
      })
    })
    return images;
  }

  static getFormattedAmount(amountObj: Amount): number {
    return amountObj?.amount / Math.pow(10, amountObj?.precision);
  }

  static getErrorCount(formGroup: FormGroup): number {
    let errorCount = 0;
    for (const controlKey in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(controlKey)) {
        if (formGroup.controls[controlKey].errors) {
          errorCount += Object.keys(formGroup.controls[controlKey].errors).length;
        }
      }
    }
    return errorCount;
  }

  static setAmountFormate(amount: number): Amount {
    return {
      amount: amount * Math.pow(10, 2),
      precision: 2,
      currency: 'INR'
    };
  }

  static convertSlugToString(slug: string): string {
    if(!slug){
      return null
    }
    return slug.split('-')
    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');
   }

   static generateUniqueId(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const idLength = 8;
    let uniqueId = '';
  
    for (let i = 0; i < idLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters[randomIndex];
    }
   console.log(uniqueId);
    return uniqueId;
  }
}
