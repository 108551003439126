import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-terminate-alert',
  templateUrl: './request-terminate-alert.component.html',
  styleUrls: ['./request-terminate-alert.component.scss']
})
export class RequestTerminateAlertComponent implements OnInit {

  title = 'Are you sure you want to go back?';
  description ='If you go back, all the progress in the current step that you filled will be lost!';

  constructor(
    public dialogRef: MatDialogRef<RequestTerminateAlertComponent>,
  ) { }

  ngOnInit(): void {
  }
  cancel(): void{
    this.dialogRef.close({ data: false });
  }

  confirm(): void{
    this.dialogRef.close({ data: true });
  }


}
