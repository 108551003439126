import { Component,Inject,OnDestroy, OnInit,} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MembershipService, UserRegistrationResponse } from 'src/app/pages/membership/memebership.service';
import { SweetAlertMessageService } from 'src/app/shared/services/sweet-alerts-message/sweet-alert-message.service';
import { SubSink } from 'subsink';

export enum DialogCondition {
  open = 'open',
  close = 'close'
}
@Component({
  selector: 'app-change-number',
  templateUrl: './change-number.component.html',
  styleUrls: ['./change-number.component.scss']
})
export class ChangeNumberComponent implements OnInit, OnDestroy {
  loadSubmitBtn = false;
  userData: UserRegistrationResponse ;
  countryCode = new FormControl('91');
  mobileNo = new FormControl('',[Validators.required, Validators.pattern(/^[0-9]\d*$/)]);
  private subsink = new SubSink();
  constructor(
    private membershipService: MembershipService,
    private swalService: SweetAlertMessageService,
    private dialogRef: MatDialogRef<ChangeNumberComponent>,
    @Inject(MAT_DIALOG_DATA) private data: UserRegistrationResponse
  ) { }

  ngOnInit(): void {
    this.userData = this.data;
  }

  submitChangeNumber(): void {
    if(this.mobileNo.invalid) {
      return;
    }
    this.loadSubmitBtn = true;
    this.subsink.add(
      this.membershipService.changeMobileNumber(this.userData?.id,{
        countryCode: this.countryCode.value,
        mobile: this.mobileNo.value
      })
        .subscribe(
          res => {
            this.loadSubmitBtn = false;
            this.swalService.successAlertDialog('Mobile number changed successfully');
            this.goBack(this.mobileNo.value);
          }, err => {
            this.loadSubmitBtn = false;
            this.swalService.locationAlertDialog('Some Error Occured');
          }
        )
    );
  }

  goBack(newMobile: string): void {
    this.dialogRef.close({data: newMobile});
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

}
