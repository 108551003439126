import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { FilterService, IdName } from '../filters.service';

@Component({
  selector: 'app-popular-locations',
  templateUrl: './popular-locations.component.html',
  styleUrls: ['./popular-locations.component.scss']
})
export class PopularLocationsComponent implements OnInit,OnChanges,OnDestroy {
  @Output() popularLocationIdArr = new EventEmitter<string[]>();
  @Input() popularLocationsIds: string[] = [];
  popularLocationsData: IdName[] = [];
  readMore = true;
  areaSlug = null;
  isLoading$: Observable<boolean>;
  private subs = new SubSink();
  constructor(
    private route: ActivatedRoute,
    private filterService: FilterService,
  ) { }

  ngOnInit(): void {
    this.subs.add(
      this.route.params.subscribe(
        data => {
          this.areaSlug = data.areaSlug;
        }
      )
    );
    this.subs.add(
      this.filterService.popularLocations$.subscribe(
        res => {
          this.popularLocationsData = res;
        }
      )
    );
    this.isLoading$ = this.filterService.isLoadingFilters$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.popularLocationsIds?.currentValue?.length>0) {
      changes.popularLocationsIds.currentValue.forEach(popularLocationId => {
        const index = this.popularLocationsData.findIndex(popularLocation => popularLocation.id === popularLocationId);
        if(index > -1) {
          this.popularLocationsData[index].active = true;
        }
        if(!this.popularLocationsIds.includes(popularLocationId)) {
          this.popularLocationsIds.push(popularLocationId);
        }
      });
    }
  }

  onAddPopularLocations(event: { target: { checked: boolean }},popularLocationFilter: IdName): void {
    const cityIndex = this.popularLocationsData.findIndex(popularLocation => popularLocation.id === popularLocationFilter.id);
    if(event.target.checked) {
      this.popularLocationsIds.push(popularLocationFilter.id);
      this.popularLocationsData[cityIndex].active = true;
    } else {
      this.popularLocationsData[cityIndex].active = false;
      if(this.popularLocationsIds.includes(popularLocationFilter.id)) {
        const index = this.popularLocationsIds.indexOf(popularLocationFilter.id);
        if(index > -1) {
          this.popularLocationsIds.splice(index, 1);
        }
      }
    }
    this.popularLocationIdArr.emit(this.popularLocationsIds);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
