import { Component, Input, OnInit } from '@angular/core';
import { SafetyStandard } from 'src/app/shared/interface';

@Component({
  selector: 'app-d-safety-standard',
  templateUrl: './d-safety-standard.component.html',
  styleUrls: ['./d-safety-standard.component.scss']
})
export class DSafetyStandardComponent implements OnInit {
  @Input() stylesafe = false;
  @Input() spaceSafety: SafetyStandard[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  onErrorHandlingOfSafetyStdImg(event: { target: { src: string } }): void {
    event.target.src = 'assets/icons/Social-Distancing-big.png'; //todo replace with default image
  }

}
