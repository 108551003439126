<div class="tab float-end m-lg-4 m-md-3 m-3" (click)="closeDialog()">
  <img src="assets/icons/Cancel.svg" width="16" height="16" alt="">
</div>
<div class="padding image-container">
  <div class=" mb-4">
     <h2 [title]="moreImage?.spaceName">{{moreImage?.spaceName.length>30 ? moreImage?.spaceName.substring(0,30)+'...':moreImage?.spaceName }}</h2>
     <div class="d-flex">
        <div>
          <img src="assets/icons/Location1.svg" alt="">
        </div>
        <p [title]="moreImage?.address" class="ms-2 m-0">
         {{moreImage?.address?.length > 65 ? moreImage?.address.substring(0, 65)+"..." : moreImage?.address}}
        </p>
     </div>
  </div>
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active" (domChange)="onDomChange($event)">
        <section class="image-section">
          <div class="image-wrapper">
            <img [src]="moreImage?.images[0]" class="d-block image-size" alt="..."  (error)="onErrorHandling($event)">
          </div> 
        </section>
        
      </div>
      <div class="carousel-item " *ngFor="let image of moreImage?.images.slice(1)" (domChange)="onDomChange($event)">
        <section class="image-section">
          <div class="image-wrapper">
            <img [src]="image" class="d-block image-size" alt="..."  (error)="onErrorHandling($event)">
          </div> 
        </section>
        
      </div>
      
      
    </div>
    <div  (click)="onPreviousSlide()" class="carousel-control-prev tab" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <img src="assets/icons/Previous-image.svg" alt="">
    </div>
    <div class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
     <img src="assets/icons/Next-image.svg" alt="">
    </div>
  </div>
  

  <div class="mt-4">
      <owl-carousel-o [options]="customOptions" *ngIf="moreImage?.images.length>2">
          <ng-template carouselSlide *ngFor="let image of moreImage?.images; let imageIndex = index">
            <div class="mx-2" (click)="onImageActive(imageIndex)">
              <section class="image-section">
                <div class="image-wrapper">
                  <img class="slider-image" [src]="image" alt=""  (error)="onErrorHandling($event)">
                </div> 
              </section>
             
            </div>
          </ng-template>
        </owl-carousel-o>
  </div>
</div>
