<div>
    <span class="info-text">Space Images</span>
</div>
<div class="d-flex mt-2 space-image" *ngIf="images?.length > 0">
    <div class="col-lg-4 col-5 pe-lg-2 pe-1">
        <div class="highlight-number pb-2">
            <div class="d-flex">
                <div>
                    <img class="matric-logo" src="assets/images/Metrics.png" alt="metrics">
                </div>
                <div class="ms-2 mt-1">
                    <h4> {{numberOfPeople}} people <span class="span">worked here</span></h4>
                </div>
            </div>
        </div>
        <img *ngIf="CategoryBadge"  class="category-badge" [src]="CategoryBadge" alt="">
        <img class="rounded  space_img tab" [src]="images?.length >= 1 ? 
                images[0] : 
                'assets/images/imgs1.png'" (error)="$event.target.src= 'assets/images/imgs1.png'"
            alt="space-image-icon"
            (click)="moreImages(spaceData)"
            title="{{spaceName}}">
    </div>
    <div class="col-lg-4 col-5 pe-lg-2 pe-1">
        <img class="main-img rounded space_img tab" [src]="images?.length > 1 ? 
                images[1] : 
                images?.length === 1 ? 
                images[0] : 
                'assets/images/imgs2.png'" (error)="$event.target.src= 'assets/images/imgs2.png'"
                alt="space-images-icon"
                (click)="moreImages(spaceData)"
                title="{{spaceName}}"
            >
    </div>
    <div class="col-lg-4 col-2">
        
        <div class="d-flex">
            <div class="col-7 d-none d-lg-block pe-2">
                <img class="img-fluid space_img img rounded tab" [src]="images.length > 2 ? 
                    images[2] : 
                    images.length === 2 ? 
                    images[1] : 
                    images.length === 1 ? 
                    images[0] : 
                    'assets/images/imgs3.png'" alt="space-image-icon"
                    (error)="$event.target.src= 'assets/images/imgs3.png'"
                    (click)="moreImages(spaceData)"
                    title="{{spaceName}}"
                    >
            </div>
            <div class="col-lg-5 col-12">
                <div class="col-12">
                    <img class="rounded space_img_end tab" [src]="images.length > 3 ? 
                        images[3] : 
                        images.length > 2 ? 
                        images[2] : 
                        images.length === 2 ? 
                        images[1] : 
                        images.length === 1 ? 
                        images[0] : 
                        'assets/images/imgs3.png'" alt="space-image-icon"
                        (error)="$event.target.src= 'assets/images/imgs4.png'"
                        (click)="moreImages(spaceData)"
                        title="{{spaceName}}"
                        >
                </div>
                <div class="mt-lg-2 mt-1 col-12">
                    <div class="more-imgs tab" (click)="moreImages(spaceData)">
                        <img class="rounded space_img_end" [src]="images.length > 4 ? 
                        images[4] : 
                        images.length > 3 ? 
                        images[3] : 
                        images.length === 3 ? 
                        images[2] : 
                        images.length === 2 ? 
                        images[1] : 
                        images.length === 1 ? 
                        images[0] : 
                        'assets/images/imgs3.png'" alt="space-image-icon" title="{{spaceName}}"
                        (error)="$event.target.src= 'assets/images/imgs4.png'">
                        <div class="overlay" *ngIf="images.length - 5 > 0">
                            <h2 class="more">+{{images.length - 5}}</h2><span>MORE</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>