
export const IMAGE_PATH = {
  placeHolderImg: 'https://via.placeholder.com/576x605.png'
};

export const HERO_SECTION = {
  startUpImage: 'assets/images/heroes/1.jpg',
  ventureImage: 'assets/images/heroes/2.jpg',
  enterpriseImage: 'assets/images/landing_rectangle.png'
};

export const BASIC_INFO = {
  primaryContactNo: '+91-9650605494',
  primaryEmailId: 'hello@stylework.city'
};

export const itData = [
  {
    altName: 'microsoft',
    imgUrl: 'assets/icons/enterprise-leading-company/it-company/microsoft.svg'
  },
  {
    altName: 'cognizant',
    imgUrl: 'assets/icons/enterprise-leading-company/it-company/cognizant.svg'
  },
  {
    altName: 'google',
    imgUrl: 'assets/icons/enterprise-leading-company/it-company/google.svg'
  },
  {
    altName: 'tcs',
    imgUrl: 'assets/icons/enterprise-leading-company/it-company/tcs.svg'
  },
   {
    altName: 'microsoft',
    imgUrl: 'assets/icons/enterprise-leading-company/it-company/honeywell.svg'
  },
  {
    altName: 'accenture',
    imgUrl: 'assets/icons/enterprise-leading-company/it-company/accenture.svg'
  },
  {
    altName: 'salesforce',
    imgUrl: 'assets/icons/enterprise-leading-company/it-company/salesforce.svg'
  },
  {
    altName: 'ey',
    imgUrl: 'assets/icons/enterprise-leading-company/it-company/ey.svg'
  },
  {
    altName: 'ibm',
    imgUrl: 'assets/icons/enterprise-leading-company/it-company/ibm.svg'
  }
]
export const fintech = [
  {
    altName: 'razorpay',
    imgUrl: 'assets/icons/enterprise-leading-company/fintech/razorpay.svg'
  },
  {
    altName: 'payu',
    imgUrl: 'assets/icons/enterprise-leading-company/fintech/payu.svg'
  },
  {
    altName: 'lazypay',
    imgUrl: 'assets/icons/enterprise-leading-company/fintech/lazypay.svg'
  },
  {
    altName: 'paytm',
    imgUrl: 'assets/icons/enterprise-leading-company/fintech/paytm.svg'
  },

]
export const ecommerce = [
  {
    altName: 'amazon',
    imgUrl: 'assets/icons/enterprise-leading-company/ecommerce/amazon.svg'
  },
  {
    altName: 'flipkart',
    imgUrl: 'assets/icons/enterprise-leading-company/ecommerce/flipkart.svg'
  },
  {
    altName: 'meesho',
    imgUrl: 'assets/icons/enterprise-leading-company/ecommerce/meesho.svg'
  },
  {
    altName: 'nykaa',
    imgUrl: 'assets/icons/enterprise-leading-company/ecommerce/nykaa.svg'
  },

]

export const entertainment = [
  {
    altName: 'instagram',
    imgUrl: 'assets/icons/enterprise-leading-company/entertainment/instagram.svg'
  },
  {
    altName: 'jiosavan',
    imgUrl: 'assets/icons/enterprise-leading-company/entertainment/jiosavan.svg'
  },
  {
    altName: 'spotify',
    imgUrl: 'assets/icons/enterprise-leading-company/entertainment/spotify.svg'
  },
]
export const banking = [
  {
    altName: 'hsbc',
    imgUrl: 'assets/icons/enterprise-leading-company/banking/hsbc.svg'
  },
  {
    altName: 'kotak',
    imgUrl: 'assets/icons/enterprise-leading-company/banking/kotak.svg'
  },
  {
    altName: 'bankamerica',
    imgUrl: 'assets/icons/enterprise-leading-company/banking/bankamerica.svg'
  },
  {
    altName: 'boi',
    imgUrl: 'assets/icons/enterprise-leading-company/banking/boi.svg'
  },
]
export const hospitality = [
  {
    altName: 'airbnb',
    imgUrl: 'assets/icons/enterprise-leading-company/hospitality/airbnb.svg'
  },
  {
    altName: 'oyo',
    imgUrl: 'assets/icons/enterprise-leading-company/hospitality/oyo.svg'
  },
  {
    altName: 'makemytrip',
    imgUrl: 'assets/icons/enterprise-leading-company/hospitality/makemytrip.svg'
  },
]
export const electronics = [
  {
    altName: 'samsung',
    imgUrl: 'assets/icons/enterprise-leading-company/electronics/samsung.svg'
  },
  {
    altName: 'apple',
    imgUrl: 'assets/icons/enterprise-leading-company/electronics/apple.svg'
  },
  {
    altName: 'verizon',
    imgUrl: 'assets/icons/enterprise-leading-company/electronics/verizon.svg'
  },
]
export const LeadingIndustries: {altName: string, imgUrl: string}[][] =[
  itData,
  fintech,
  ecommerce,
  entertainment,
  banking,
  hospitality,
  electronics
]

