import { Pipe, PipeTransform } from '@angular/core';
import { Cities, State } from '../shared/interface/cities';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: Cities[]| State[], searchText: string): Cities[]| State[]{
    if (!items){
      return [];
    }
    if (!searchText){
      return items;
    }
    return items.filter(item => item?.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
  }
}
