import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WEBENGAGE_EVENTS } from 'src/app/Helper/constant';
import { amountNotAvailable } from 'src/app/Helper/enums';
import { CardWithImageModel } from 'src/app/_models/card-with-image';
import { WebEngageService } from 'src/app/shared/services/webengage.service';


@Component({
  selector: 'app-card-with-image',
  templateUrl: './card-with-image.component.html',
  styleUrls: ['./card-with-image.component.scss']
})
export class CardWithImageComponent implements OnInit {
  @Input() cardWithImage: CardWithImageModel;
  amountMessage = amountNotAvailable;
  imageLoader = true;
  constructor(
    private router: Router,
    private webengageService: WebEngageService
  ) { }


  ngOnInit(): void {
    // console.log(this.cardWithImage)
  }
  spaceSummary(spaceDetailsLink: string): void {
    this.webengageService.trackEvent(WEBENGAGE_EVENTS.Visit_The_Places,{
      workSpaceName: this.cardWithImage.title,
      workSpaceId: this.cardWithImage.id,
      location: this.cardWithImage.address
    })
    this.router.navigate([spaceDetailsLink]);
  }

  onErrorHandling(event: { target: { src: string } }): void {
    event.target.src = 'assets/images/img1.png';
  }

}
