import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cities } from 'src/app/shared/interface/cities';
import { RestApiService } from 'src/app/shared/services/common-services/rest-api.service';
import { AppRouterService } from 'src/app/shared/services/router/app-router.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-more-cities',
  templateUrl: './more-cities.component.html',
  styleUrls: ['./more-cities.component.scss']
})
export class MoreCitiesComponent implements OnInit, OnDestroy {
  searchCity: string = null;
  citiesList: Cities[] = [];
  loading = false;
  private subs = new SubSink();
  constructor(
    private appRouterService: AppRouterService,
    private restService: RestApiService,
    private dialogRef: MatDialogRef<MoreCitiesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
           route: boolean;
    }
  ){
  }
  ngOnInit(): void {
    this.loading = true;
    this.subs.add(
      this.restService.getCities().subscribe(cityData =>{
        this.loading = false;
        cityData.forEach((item) => {
          const i = this.citiesList.findIndex(x => x?.id === item?.cityId);
          if (i <= -1) {
            this.citiesList.push(item.city);
          }
        });
      })
    );
  }

  filterPopularCities(cities:Cities[]): Cities[] {
    return cities.filter(city => city.isPopular);
  }

  filterTierOneCities(cities: Cities[]): Cities[] {
    return cities.filter(city => city?.tier.name == 'Tier1' )
  }

  filterTierTwoCities(cities: Cities[]): Cities[] {
    return cities.filter(city => city?.tier.name == 'Tier2' )
  }

  filterTierThreeCities(cities: Cities[]): Cities[] {
    return cities.filter(city => city?.tier.name == 'Tier3' )
  }

  routeToSpacesExplorationByCityId(city: Cities): void {
    if(this.data?.route){
      this.appRouterService.routeToSpaceExplorationUsingCitySlug(city);
      this.closeDialog();
    }else{
      this.dialogRef.close({data:city});
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
