<div class="container coworking">
    <div class="row">
      <div class="col-12">
        <div class="row">     
          <div class="col-12 col-lg-4 text-lg-start text-center">
            <div class="onscroolfixed">
            <h2 class="headertxtcol fs-biger line-h2">Why opt for a co-working space?</h2>
            <p class="pe-lg-5">It’s not just co-working. it’s way more than a desk. Getting a workspace should be hassle-free, and that’s what we aim to provide you!</p>
            </div>
            
          </div>
          <div class="col-lg-4 col-12 zero-setup">
            <div class=" p-4 ms-lg-5 text-lg-start text-center">
              <img [src]="zerosetup"  class="img-fluid col-6 col-lg-10 col-md-5" alt="Zero set-up" title="Zero set-up" loading="lazy">
              <h2 class="heading fs-largest">Zero set-up and maintenance costs</h2>
              <p class="sub-heading fs-medium">With a <span class="highlight fs-medium">plug-and-play</span> setup, we provide everything you would need from day one. Being a <span class="highlight">managed</span>
                workspace,
                you won’t have to worry about the nitty-gritty of facility and/or maintenance management.</p>
            </div>
          </div>
          <div class="col-lg-4 col-12 ">
            <div class=" p-3 ms-lg-5 text-lg-start text-center">
              <img [src]="amentiesLove" class="img-fluid col-6 col-lg-10 col-md-5" alt="Amenities you'll love" title="Amenities you'll love" loading="lazy">
              <h2 class="heading fs-largest">Amenities you'll love</h2>
                <p class="sub-heading fs-medium">All our workspaces offer <strong>more</strong> than just a workspace table. Some have recreational areas, coffee lounges and even quiet rooms.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="unconventional-section">
       <div class="container card-section">
         <div class="row">
          <div class="col-lg-4"></div>
          <div class="col-lg-4 community">
           <div class=" p-lg-3 p-2 ms-lg-5 text-lg-start text-center">
             <img [src]="commColl" class="img-fluid col-6 col-lg-10 col-md-5" alt='Community and collaboration' title="Community and collaboration" loading="lazy">
             <h2 class="mt-3 heading fs-largest">Community and collaboration</h2>
             <p class="sub-heading fs-medium">At coworking spaces, you’ll find a <span class="highlight">diverse</span> set of people who’re out to change the world. The coworking space you’re at acts as an incubator for finding unique people with distinct thoughts.</p>
           </div>
          </div>
          <div class="col-lg-4">
            <div class=" p-3 ms-lg-5 text-lg-start text-center">
              <img [src]="flexible" class="img-fluid col-6 col-lg-10 col-md-5" alt="Flexibility as your team grows" title="Flexibility as your team grows" loading="lazy">
              <h2 class="heading mt-3 fs-largest">Flexibility as your team grows</h2>
              <p class="sub-heading fs-medium">With multiple offerings and seating options you can <span class="highlight">extend</span> and <span class="highlight">reduce</span> your seating requirements without a hassle.
              </p>
            </div>
          </div>
         </div>
         <div class="unconventional-benefits">
          <app-unconventional-benefits></app-unconventional-benefits>
         </div>
       </div>
  </section>
  
