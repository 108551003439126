/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";

function _window(): any {
    // return the global native browser window object
    return window;
}

@Injectable({
    providedIn: "root"
})
export class WindowRefService {
    constructor() {
        // do nothing
    }

    get nativeWindow(): any {
        return _window();
    }
}