import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertMessageService {
  constructor(){}

  errorAlertDialog(): void {
    Swal.fire({
      title: 'Oops..!!',
      text: 'Some Error Occured..!',
      icon: 'warning',
      showCancelButton: true, // There won't be any cancel button
      showConfirmButton: false, // There won't be any confirm button
      backdrop : false,
    });
  }

  errorAlertDialogForResume(text: string): void {
    Swal.fire({
      title: 'Oops..!!',
      text: text,
      icon: 'error',
      showCancelButton: true, // There won't be any cancel button
      showConfirmButton: false, // There won't be any confirm button
      backdrop : false,
    });
  }

  successAlertDialog(text: string): void{
    Swal.fire({
      title: 'Thank You..!',
      text,
      icon: 'success',
      showCancelButton: true, // There would be a cancel button
      showConfirmButton: false, // There won't be any confirm button
      backdrop : false,
    });
  }

  locationAlertDialog(text: string): Promise<SweetAlertResult>{
    return Swal.fire({
      title: 'Oops..!',
      text,
      icon: 'warning',
      showCancelButton: false, // There would be a cancel button
      showConfirmButton: true, // There won't be any confirm button
      backdrop : false,
    });
  }

  alertCustomMember(text: string): Promise<SweetAlertResult>{
    return Swal.fire({
      title: 'Thank you...! ',
      text,
      icon: 'success',
      showCancelButton: false, // There would be a cancel button
      showConfirmButton: true, // There won't be any confirm button
      backdrop : false,
    }
    );
  }

}
