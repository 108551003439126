<div>
    <div class="form-check form-check-inline">
        <!-- todo change values -->
        <input class="form-check-input" type="radio" name="inlineRadioOptions"
         [formControl]="seatTypeFormControl"
         id="inlineRadio1" value="5df386fc9d0fd664fca40003">
        <label class="form-check-label" for="inlineRadio1"> Open Desk</label>
    </div>
    <div class="form-check form-check-inline ms-4">
        <input class="form-check-input" type="radio" 
        name="inlineRadioOptions" id="inlineRadio2" 
        [formControl]="seatTypeFormControl"
        value="5f17d42c32839049108c499d">
        <label class="form-check-label" for="inlineRadio2">Conference Rooms</label>
    </div>
</div>
<div class="mt-2 bar-container">
    <div>
        <img class='ms-3' src="assets/icons/Range-bar.svg" alt="">
    </div>
    <div class="range-bar">
        <div class="custom-slider">
            <ngx-slider 
            [(value)]="minValue" 
            [(highValue)]="maxValue" 
            [options]="options"
            (mouseup)="sliderEvent()"></ngx-slider>
        </div>
    </div>
    <div class="d-flex justify-content-end ">
        <span class="price-text me-3">Prices per month</span>
    </div>
    <div class="d-flex input-range-container">
        <div class="d-flex">
            <div class='mt-2'>
                <span>From</span>
            </div>
            <div class="rounded input-box p-1 ms-3 justify-content-center align-items-center">
                <input class="input" type="text" [(ngModel)]='minValue' #minInputValue>
            </div>
        </div>
        <div class="ms-4 line">
        </div>
        <div class="d-flex ms-4">
            <div class='mt-2'>
                <span>To</span>
            </div>
            <div class="rounded input-box p-1 ms-3 justify-content-center align-items-center">
                <input class="input" type="text" [(ngModel)]='maxValue' #maxInputValue>
            </div>
        </div>
    </div>
</div>