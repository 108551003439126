/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { routeParams } from './Helper/enums';
import {
  home,
  aboutUs,
  contactUs,
  blogAndResource,
  spaceExploration,
  spaceDetails,
  listYourSpace,
  faqs,
  icici,
  manageSpace,
  membership,
  careers,
  thanks,
  pageNoteFound,
  productSuite,
  allianceData,
  bookNow,
  demoSpace,
  coworking,
  enterprise,
  unconventional
} from './Helper/meta-data';
import { HomePageComponent } from './pages/home/home-page/home-page.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    data: home
  },
  {
    path: 'list-your-space',
    loadChildren: () => import('./pages/list-your-space/list-your-space.module').then((m) => m.ListYourSpaceModule),
    data: listYourSpace
  },
  {
    path: 'explore-space',
    redirectTo:'coworking-spaces'
  },

  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqModule),
    data: faqs
  },
  {
    path: 'space-details/:spaceId',
    loadChildren: () => import('./pages/space-details/space-details.module').then((m) => m.SpaceDetailsModule),
    data: spaceDetails,
  },
  {
    path: `coworking`,
    redirectTo:'coworking-spaces'
  },
  {
    path: `coworking-spaces`,
    loadChildren: () => import('./pages/space-exploration/space-exploration.module').then((m) => m.SpaceExplorationModule),
    data: coworking,
  },
  {
    path: `coworking/:citySlug`,
    redirectTo:`coworking-spaces/:${routeParams.citySlug}`
  },
  {
    path: `coworking-spaces/:${routeParams.citySlug}`,
    loadChildren: () => import('./pages/space-exploration/space-exploration.module').then((m) => m.SpaceExplorationModule),
    data: spaceExploration
  },
  
  {
    path: `coworking/:citySlug/:areaSlug`,
    redirectTo:'coworking-spaces/:citySlug/:areaSlug'
  },
  {
    path: `coworking-spaces/:citySlug/:areaSlug`,
    loadChildren: () => import('./pages/space-with-area/space-with-area.module').then((m) => m.SpaceWithAreaModule),
    data: spaceExploration
  },
  {
    path: `coworking/:${routeParams.citySlug}/:areaSlug/:${routeParams.spaceName}`,
    redirectTo: `coworking-spaces/:${routeParams.citySlug}/:areaSlug/:${routeParams.spaceName}`
  },
  {
    path: `coworking-spaces/:${routeParams.citySlug}/:areaSlug/:${routeParams.spaceName}`,
    loadChildren: () => import('./pages/space-details/space-details.module').then((m) => m.SpaceDetailsModule),
    data: spaceDetails,
  },
  {
    path:'demo',
    data: demoSpace,
    loadChildren: () => import('./pages/space-details/space-details.module').then((m) => m.SpaceDetailsModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then((m) => m.ContactUsModule),
    data: contactUs
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then((m) => m.AboutUsModule),
    data: aboutUs
  },
  {
    path: 'blogs-and-resources',
    loadChildren: () => import('./pages/blogs/blogs.module').then((m) => m.BlogsModule),
    data: blogAndResource
  },
  {
    path: 'icici',
    loadChildren: () => import('./pages/icici-landing-page/icici-landing.module').then((m) => m.IciciLandingModule),
    data: icici
  },
  {
    path: 'membership',
    loadChildren: () => import('./pages/membership/membership-plans.module').then((m) => m.MembershipPlansModule),
    data: membership
  },
  {
    path: 'enterprise',
    loadChildren: () => import('./pages/enterprise-page/enterprise-page.module').then((m) => m.EnterprisePageModule),
    data: enterprise
  },

  {
    path: 'blog/:title',
    loadChildren: () => import('./pages/blog-detail/blog-detail.module').then((m) => m.BlogDetailModule)
  },
  {
    path: 'Book-a-Demo',
    loadChildren: () => import('./pages/book-a-demo/bookADemo.module').then((m) => m.BookADemoModule)
  },
  {
    path: 'managed-space',
    loadChildren: () => import('./pages/manage-spaces/manageSpace.module').then((m) => m.ManageSpaceModule),
    data: manageSpace
  },
  {
    path: 'career',
    data: careers,
    loadChildren: () => import('./pages/career/career.module').then((m) => m.CareerModule),
  },
  {
    path: 'thank-you',
    data: thanks,
    loadChildren: () => import('./pages/user-related/thank-you-page/thanks.module').then((m) => m.ThanksModule),
  },
  {
    path: 'thank-you-trial',
    data: thanks,
    loadChildren: () => import('./pages/user-related/thank-you-trial/thank-you-trial.module').then((m) => m.ThankYouTrialModule),
  },
  
  {
    path: 'product-suite',
    data:productSuite,
    loadChildren: () => import('./pages/enterprise-page/enterprise-page.module').then((m) => m.EnterprisePageModule),
  },
  {
    path: 'our-partners',
    data: allianceData,
    loadChildren: () => import('./pages/integrated-partners/integrated-partners.module').then((m) => m.IntegratedPartnersModule),
  },

  {
    path: 'app-privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/website-privacy-policy/website-privacy-policy.module').then((m) => m.WebsitePrivacyPolicyModule),
  },
  {
    path: 'space-agreement',
    loadChildren: () => import('./pages/space-agreement/space-agreement.module').then((m) => m.SpaceAgreementModule),
  },
  {
    path: 'book-now',
    data: bookNow,
    loadChildren: () => import('./pages/marketing-landing-page/marketing-landing-page.module').then((m) => m.MarketingLandingPageModule),
  },
  {
    path: 'get-a-quote',
    data: bookNow,
    loadChildren: () => import('./pages/market-landing/market-landing.module').then((m) => m.MarketLandingModule),
  },
  {
    path:'product-license',
    loadChildren: () => import('./pages/product-license/product-license.module').then((m) => m.ProductLicenseModule)
  },
  {
    path:'product-manual',
    loadChildren: () => import('./pages/product-manual/product-manual.module').then((m) => m.ProductManualModule)
  },
  {
    path:'proposal-details',
    loadChildren: () => import('./pages/proposal/proposal.module').then((m) => m.ProposalModule)
  },
  {
    path:'vendor',
    loadChildren: () => import('./pages/vendor-onboarding/vendor-onboarding.module').then((m) => m.VendorOnboardingModule)
  },

  {
    path:'unconventional-unions',
    data: unconventional,
    loadChildren:() => import('./pages/unconventional-unions-event/unconventional-unions-event.module')
    .then((m)=> m.UnconventionalUnionsEventModule)
  },

  {
    path: '**', component: PageNotFoundComponent,
    data: pageNoteFound
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
