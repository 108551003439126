import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ComponentRestrictions } from 'ngx-google-places-autocomplete/objects/options/componentRestrictions';
import { CRM } from 'src/app/shared/interface';
import { SpaceService } from 'src/app/shared/services/spaces/space.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { formSources, styleworkStats } from 'src/app/Helper/enums';
import { SubSink } from 'subsink';
import { seatCategoryProposal } from 'src/app/pages/proposal/proposal';
import { ProposalService } from 'src/app/pages/proposal/proposal.service';


@Component({
  selector: 'app-find-a-workplace',
  templateUrl: './find-a-workplace.component.html',
  styleUrls: ['./find-a-workplace.component.scss']
})
export class FindAWorkplaceComponent implements OnInit {
  selectedCategory: string;
  styleworkstats = styleworkStats;
  seatCategoryData: seatCategoryProposal[] = [];
  @Output() aftermodalclose = new EventEmitter<boolean>();
  @Input() seatRequest = null
  crmDataForm: FormGroup;
  submitted= false;
  isLoading = false;
  resMessage = '';
  private subsink = new SubSink();
  color = 'primary';
  mode = 'indeterminate';
  value = 30;
  diameter = 30;
  selectedLocations: string[] = [];
  addressTokens: string[] = [];
  options = new Options({
    componentRestrictions: new ComponentRestrictions({
      country: 'IN',
    }),
  });

  constructor(
    private formBuilder: FormBuilder,
    private spaceService: SpaceService,
    private router: Router,
    private proposalService: ProposalService,
  ) { }

  ngOnInit(): void {
    this.seatCategoryList();
    this.crmDataForm = this.formBuilder.group({
      firstName: ['',[Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]],
      lastName: ['',[Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]],
      contactNo: ['',[Validators.required, Validators.maxLength(10),Validators.minLength(10),Validators.pattern(/^[0-9]\d*$/)]],
      email: ['',[Validators.required,Validators.email]],
      countryCode: ['+91'],
      companySize: ['Individual',[Validators.required]],
      source:[formSources.QuoteRequest],
      requirements:[''],
      seats: ['',[Validators.required, Validators.pattern(/^[0-9]\d*$/),Validators.maxLength(4)]],
      seatCategory: ['', Validators.required]
    });
 
    // if user open form throug the request a quote button so we filled seatCategory field
    if(this.seatRequest){
      this.selectedCategory = this.seatRequest;
      this.crmDataForm.get('seatCategory').setValue(this.seatRequest)
    }
  }

  get formControls(): { [key: string]: AbstractControl} {
    return this.crmDataForm.controls;
  }

  setCompanySize(size: string): void {
    this.crmDataForm.get('companySize').setValue(size);
  }

  onSubmitRequirements(): void {
    this.submitted = true;
    if(this.crmDataForm.invalid || this.selectedLocations.length === 0) {
      return;
    }
    this.isLoading = true;
    this.resMessage = '';
    this.crmDataForm.get('seats').setValue(+this.crmDataForm.value.seats);
    const crmData: CRM = {
      firstName: this.crmDataForm.value.firstName,
      lastName: this.crmDataForm.value.lastName,
      contactNo: this.crmDataForm.value.countryCode+this.crmDataForm.value.contactNo,
      email: this.crmDataForm.value.email,
      requirements: this.crmDataForm.value.requirements || 'Not Available',
      companySize: this.crmDataForm.value.companySize,
      source:this.crmDataForm.value.source,
      location: this.selectedLocations.join(';'),
      seats: this.crmDataForm.value.seats,
      seatCategory: this.crmDataForm.value.seatCategory
    };
    this.spaceService.postRequirements(crmData)
      .subscribe(
        res => {
          this.isLoading = false;
          this.aftermodalclose.emit(true);
          this.resMessage = 'Your requirements have been saved successfully.';
          this.router.navigate(['/thank-you']);
        },
        err => {
          this.isLoading = false;
          this.resMessage = '';
        }
      );
  }

  handleAddressChange(address: Address, event): void{
    event.value = '';
    const index = this.selectedLocations.indexOf(address.formatted_address);
    if(index < 0){
      this.selectedLocations.push(address.formatted_address);
      this.addressTokens.push(address.name);
    }
  }


  deleteAddressToken(index: number): void {
    this.selectedLocations.splice(index, 1);
    this.addressTokens.splice(index, 1);
  }

  seatCategoryList(): void {
    this.subsink.add(
      this.proposalService.getSeatCategory().subscribe((res: seatCategoryProposal[]) => {
        this.seatCategoryData = res;
      })
    )
  }

  categorySelected(category: seatCategoryProposal): void {
    this.selectedCategory = category?.name;
    this.crmDataForm.get("seatCategory").setValue(category?.name);
  }


}
