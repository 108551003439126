<div class="row" *ngIf="!loading">
    <div class="col-12">
        <!-- <div class="border-bottom border-2 pb-3">
            <app-popular-filters></app-popular-filters>
        </div> -->
    </div>
    <div class="col-12"  *ngIf="filtersData?.budget && filtersData?.budget?.length > 0">
        <!--- Your Budget---->
        <!-- <div class="mt-3">
            <span class="sub-heading">Your Budget</span>
            <div class="mt-3 border-bottom border-2 pb-3">
                <app-vertical-budget-layout 
                (pricingValues)="addPriceToFilter($event)"
                (seatTypesId)="addSeatType($event)"></app-vertical-budget-layout>
            </div>
        </div> -->
    </div>
    <div class="col-12"  *ngIf="filtersData?.landmarks && filtersData?.landmarks?.length > 0">
        <!---Locational preferences--->
        <div class="border-bottom border-2 pb-3 mt-3">
            <app-location-preference (nearByIds)="addLocationPreferences($event)"></app-location-preference>
        </div>
    </div>
    <ng-container>
        <div class="col-12" *ngIf="filtersData?.popularLocations && filtersData?.popularLocations?.length > 0">
            <div class="location-preferences mt-3"  *ngIf="areaSlug === null || areaSlug === undefined">
                <h3 class="fs-large">Popular Locations</h3>
                <div class="mt-3 border-bottom border-2 pb-3">
                    <app-popular-locations (popularLocationIdArr)="addPopularLocations($event)" [popularLocationsIds]="locationsIdsArr"></app-popular-locations>
                </div>
            </div>
        </div>
    </ng-container>
   
    <div class="col-12" *ngIf="filtersData?.amenities && filtersData?.amenities?.length > 0">
        <!-----Amenities available----->
        <div class="border-bottom border-2 pb-3 mt-3">
            <h3 class="fs-large">Amenities available</h3>
            <div class="mt-3">
                <app-amenity (amenitiesIdArr)="addAmenities($event)"></app-amenity>
            </div>
        </div>
    </div>
    <div class="col-12" *ngIf="filtersData?.desks && filtersData?.desks?.length > 0">
        <!-----Desk Preferences------>
        <div class="mt-3">
            <h3 class="fs-large">Desk Preferences</h3>
            <div class="mt-3">
                <app-desk-preference (deskPreferenceIdArr)="addDeskPrefernece($event)"></app-desk-preference>
            </div>
        </div>
    </div>

</div>
<div class="d-flex align-items-center justify-content-center py-3" *ngIf="loading">
   <app-bootstrap-loader></app-bootstrap-loader>
</div>