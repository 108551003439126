<section class="container section-position">
   <div class="text-center">
    <img class="img-fluid" src="assets/icons/page-not-found.svg" alt="404 page not found">
    <h1 class="text-primary-base fs-huge">Oops! Page not Found</h1>
    <p class="m-0 text-dark-10 fw-500 fs-largest mt-3 pt-md-2">We are unable to find the Page you are looking for</p>
    <div class="btn btn-primary-base px-3 py-2 mt-4" [routerLink]="'/'" >
        <p class="m-0 fs-small fw-500 py-1 px-1 text-center">Go to Home Page</p>
    </div>
   </div>
</section>
<app-main-footer></app-main-footer>
