<div [ngClass]="hideFooterClass ? 'footer':'footer1'" *ngIf="testimoList.length > 0">
  <div class="container">
    <div class="row">
      <div class="col col-12 col-lg-4 col-md-12 details" *ngIf="hideFooterClass">
        <h2 class="fs-bigest">{{heading}}</h2>
        <p>{{subheading}}</p>
      </div>
      <div class="col col-12 col-md-12 col-lg-7">
        <div id="progress-circle-border" class="d-none d-lg-block">
        </div>
        <div class="progress-circle d-none d-lg-block">
            <div id="carouselExampleControls1" class="carousel slide" data-bs-ride="carousel">
          
              <div class="carousel-inner">
                <div class="testimonial-list">
                <div class="carousel-item active">
                  <h4 class="mb-0">
                    {{testimoList[getIndex() || 0].name}}
                  </h4>
                  <span><small>
                    {{testimoList[getIndex() || 0].designation}}
                  </small></span>
                  <hr>
                    <p>{{testimoList[getIndex() || 0].description  | truncate:{sliceStart:0, sliceEnd:300} }}
                     </p>   
                </div>                 
                </div>
              </div>
            </div> 
        </div>

        <div class="d-block d-lg-none">
          <app-testimonial-mobile [testimo]="testimoList"></app-testimonial-mobile>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center align-items-center py-5" *ngIf="loading">
  <app-bootstrap-loader></app-bootstrap-loader>
</div>


