<h2 class="text-light mt-lg-4 mt-2 mb-0 fs-biger">Our <span>unconventional</span> benefits</h2>
<div class="row unconvential-benifits">
  <div class="col col-12 col-md-7 col-lg-6 order-lg-0 order-1 order-md-0">
    <div class="row border-bottom-custom mx-1">
      <div class="circle col-2 circle-active tab" (click)="animationStepper(0)">01</div>
      <div class="col-10 ps-md-4 content content-active tab" (click)="animationStepper(0)">
        <h2 class="fs-largest">Flexibility at your fingertips</h2>
        <p class="view mb-0">
          With options to choose amongst - <strong class="text-white">duration, kind of space, pass kind</strong>
          - we offer you a totally customized experience, so you get exactly what you’re looking for.
        </p>
      </div>
    </div>
    <div class="row border-bottom-custom mt-2 mx-1">
      <div class="circle col-2 tab" (click)="animationStepper(1)">02</div>
      <div class="col-10 ps-md-4 content tab" (click)="animationStepper(1)">
        <h2 class="fs-largest">Competitive prices you can’t resist</h2>
        <p class="view mb-0">
          From budgeted to luxurious co-working spaces, we provide everything!
          Choose the coworking space of your choice and book it with us at the most competitive price.
        </p>
      </div>
    </div>
    <div class="row border-bottom-custom mt-2 mx-1">
      <div class="circle col-2 tab" (click)="animationStepper(2)">03</div>
      <div class="col-10 ps-md-4 content tab" (click)="animationStepper(2)">
        <h2 class="fs-largest">Ready support when you need</h2>
        <p class="view mb-0">
          Stylework is always glad to help you. Contact us at 9650605494 or drop a mail at hello@stylework.city. for any
          kind of support, question or query,
          we will surely get back to you within 24-48 hours. We are always happy to help!
        </p>
      </div>
    </div>
    <div class="row py-md-4 py-3 my-md-3 my-2 mx-1">
      <div class="circle col-2 tab" (click)="animationStepper(3)">04</div>
      <div class="col-10 ps-md-4 content tab" (click)="animationStepper(3)">
        <h2 class="fs-largest">
          Benefits of discounts and promotions
        </h2>
        <!-- on events, softwares, services and more -->
        <p class="view mb-0">
          Get promotional discounts on our <span class="fw-bold tab link"
          (click)="flexibleMembership()">flexible coworking membership plans</span>, events, softwares, services and much
          more. Avail our services today and get amazing discounts on your bookings!
        </p>
      </div>
    </div>
  </div>
  <div class="col col-12 col-md-5 col-lg-6 text-center p-lg-5  mt-md-5 mt-lg-0 p-3 order-lg-1 order-0 order-md-1">
    <div class="margin-top">
      <div class="img-box view p-lg-5 mt-md-5 pt-md-5 pt-lg-0 mt-lg-0">
        <img class="img-fluid col-6 col-md-12 col-lg-12"
          src="assets/images/Landing-page/flexibility-at-your-fingertips.svg" alt="flexibility at your fingertips" loading="lazy">
      </div>
    </div>
  </div>
</div>