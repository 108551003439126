import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { FiltersQuery } from 'src/app/components/filters/filters.service';
import { EnvConfigurationService } from 'src/app/Helper/environment-configuration.service';
import {
  Amount,
  Pricing,
  SpaceDetails,
  Spaces,
  CRM,
  CMR,
  LatLongPostalCode,
  SearchSpaceQuery,
  SearchSpaces,
  SeatTypeData,
} from '../../interface';
import { QueryParams } from '../common-services/rest-api.service';
import { AreaList } from 'src/app/pages/proposal/proposal';

export type DurationInString = 'month' | 'week' | 'year' | 'quarter' | 'NA';

export interface SpaceListResponse {
  spaces: Spaces[];
  spaceData?: Spaces[];
  count: number;
}

@Injectable({
  providedIn: 'root',
})
export class SpaceService {
  config = this.envConfigService.configSubject$;
  filtersData: FiltersQuery;
  postalCode$: Observable<number>;
  spacesCount$: Observable<number>;
  spaces$: Observable<Spaces[]>;
  popularSpaces$: Observable<Spaces[]>;
  latlong$: Observable<LatLongPostalCode>;
  filtersByCityId$: Observable<string>;
  private latLongSubject = new BehaviorSubject<LatLongPostalCode>(null);
  private spaceCountSubject = new BehaviorSubject<number>(0);
  private postalCodeSubject = new BehaviorSubject<number>(null);
  private spaceSubject = new BehaviorSubject<Spaces[]>([]);
  private populatSpacesSubject = new BehaviorSubject<Spaces[]>([]);
  private filterByCityIdSubject = new BehaviorSubject<string>(null);

  constructor(private envConfigService: EnvConfigurationService,private router: Router, private http: HttpClient) {
    this.spaces$ = this.spaceSubject.asObservable();
    this.popularSpaces$ = this.populatSpacesSubject.asObservable();
    this.spacesCount$ = this.spaceCountSubject.asObservable();
    this.latlong$ = this.latLongSubject.asObservable();
    this.postalCode$ = this.postalCodeSubject.asObservable();
    this.filtersByCityId$ = this.filterByCityIdSubject.asObservable();
  }

  postRequirements(leadData: CRM): Observable<CRM> {
    return this.http.post<CRM>(`${this.config.value.apiUrl}post-requirement`, leadData);
  }

  customMembershipRequest(leadData: CMR): Observable<CMR> {
    return this.http.post<CMR>(`${this.config.value.apiUrl}custom-membership-requests`, leadData);
  }

  changePostalCode(obj: LatLongPostalCode): void {
    this.postalCodeSubject.next(obj.postalCode);
    this.latLongSubject.next(obj);
    const queryData: Partial<QueryParams> = {
      lat: obj.lat, //latitude of location choosed by user
      long: obj.lon, //longitude of location choosed by user
      count: 3,
      page: 1,
    };
    this.getSpacesList(queryData);
  }

  getSpaceDetails(spaceId: string): Observable<SpaceDetails> {
    return this.http.get<SpaceDetails>(`${this.config.value.apiUrl}v2/space/${spaceId}/detail`)
  }

  getAreaList(cityId: string): Observable<AreaList[]> {
   return this.http.get<AreaList[]>(`${this.config.value.apiUrl}v2/areas/${cityId}`)
  }
  

  redirectToSpaceDetails(spaceId: string): Observable<{redirectUrl: string}> {
    return this.http.get<{redirectUrl: string}>(`${this.config.value.apiUrl}space/${spaceId}/redirect`).pipe(
      catchError(this.handleError)
    );
  }

  redirectToSpaceList(cityId: string,popular: boolean= false): Observable<{redirectUrl: string}> {
    return this.http.get<{redirectUrl: string}>(`${this.config.value.apiUrl}city/${cityId}/redirect?popular=${popular}`).pipe(
      catchError(this.handleError)
    );
  }

  getSearchSpaces(searchSpaceQuery:SearchSpaceQuery): Observable<SearchSpaces[]> {
    return this.http.post<SearchSpaces[]>(
      `${this.config.value.apiUrl}/v2/spaces/search`, searchSpaceQuery
    )
  }

  getSpaceDetailsUsingSlug(
    spaceSlug: string,
    citySlug: string,
    areaSlug: string
  ): Observable<SpaceDetails> {
    // eslint-disable-next-line max-len
    return this.http.get<SpaceDetails>(
      `${this.config.value.apiUrl}v2/spaces/${spaceSlug}/detail?areaSlug=${areaSlug}&citySlug=${citySlug}`)
  }

  similarSpacesList(spaceId: string): Observable<void>{
    return this.http.get<void>( `${this.config.value.apiUrl}/v2/similar-spaces/${spaceId}`)
  }

  getSpaceSeatTypeData(type:string, value: string, data?:{ areaSlug: string, citySlug:string}): Observable<SeatTypeData[]> {
    let params = new HttpParams();
    if(data){
      params = params.append('areaSlug',`${data.areaSlug}`);
      params = params.append('citySlug', `${data.citySlug}`);
    }
    return this.http.get<SeatTypeData[]>( `${this.config.value.apiUrl}/v2/spaces/${type}/seats/${value}`,{params})
  }
  getSpacesByLatLong(
    data: { page: number; count: number; lat: number; long: number },
    filters: FiltersQuery
  ): Observable<SpaceListResponse> {
    const filterObj = filters === null ? '{}' : JSON.stringify(filters);
    this.filterByCityIdSubject.next(null);
    return this.http
      .get<SpaceListResponse>(
        `${this.config.value.apiUrl}spaces?page=${data.page}&lat=${data.lat}&long=${data.long}&count=${data.count}&filter=${filterObj}`
      )
      .pipe(
        retry(1),
        map((spaceListResponse: SpaceListResponse) => {
          this.spaceCountSubject.next(spaceListResponse.count);
          if (spaceListResponse.spaces.length > 0) {
            return {
              spaces: spaceListResponse.spaces,
              count: spaceListResponse.count,
            };
          }
          else{
            return { spaces: [], count: spaceListResponse.count };
          }
        }),
        catchError(this.handleError)
      );
  }

  getSpacesBySlug(
    data: QueryParams,
    filters: FiltersQuery
  ): Observable<SpaceListResponse> {
    const filterObj = filters === null ? '{}' : JSON.stringify(filters);
    this.filterByCityIdSubject.next(null);
    if (!data || !data.citySlug) {
      return;
    }
    return this.http
      .get<SpaceListResponse>(
        // eslint-disable-next-line max-len
        `${this.config.value.apiUrl}v2/cities/slug/${data.citySlug}/spaces?page=${data.page}&count=${data.count}&filter=${filterObj}&areaSlug=${data?.areaSlug}`)
  }

  getSpacesList(data: Partial<QueryParams>): Observable<SpaceListResponse> {
    return this.http
      .get<SpaceListResponse>(
        `${this.config.value.apiUrl}spaces?page=${data.page}&lat=${data.lat}&long=${data.long}&count=${data.count}&filter=${'{}'}`
      )
      .pipe(
        retry(1),
        map((spaceListResponse: SpaceListResponse) => {
          if (spaceListResponse.spaces.length > 0) {
            this.spaceCountSubject.next(spaceListResponse.count);
            return {
              spaces: spaceListResponse.spaces,
              count: spaceListResponse.count,
            };
          }
          else{
            return { spaces: [], count: spaceListResponse.count };
          }
        }),
        catchError(this.handleError)
      );
  }


  getSpacesByPopularLocationsId(
    data: QueryParams,filters: FiltersQuery
  ): Observable<SpaceListResponse> {
    const filterObj = filters === null ? '{}' : JSON.stringify(filters);
    this.filterByCityIdSubject.next(null);
    return this.http
      .get<SpaceListResponse>(
        `${this.config.value.apiUrl}popular-locations/${data.id}/spaces?page=${data.page}&count=${data.count}&filter=${filterObj}`
      )
      .pipe(
        retry(1),
        map((spaceListResponse: SpaceListResponse) => {
          if (spaceListResponse.spaces.length > 0) {
            this.spaceCountSubject.next(spaceListResponse.count);
            const spacesData = spaceListResponse.spaces
            this.getPopularSpaceByPopularLocationId(data);
            return { spaces: spacesData, count: spaceListResponse.count };
          }
          else{
            return { spaces: [], count: spaceListResponse.count };
          }
        }),
        catchError(this.handleError)
      );
  }

  getPopularSpaceByPopularLocationId(
    data: QueryParams
  ): Observable<SpaceListResponse> {
    this.filterByCityIdSubject.next(null);
    return this.http
      .get<SpaceListResponse>(
        `${this.config.value.apiUrl}popular-locations/${data.id}/popular-spaces?page=${data.page}&count=${data.count}`
      )
      .pipe(
        retry(1),
        map((spaceListResponse: SpaceListResponse) => {
          if (spaceListResponse.spaces.length > 0) {
            this.spaceCountSubject.next(spaceListResponse.count);
            const spacesData = spaceListResponse.spaces
            return { spaces: spacesData, count: spaceListResponse.count };
          }
          else{
            return { spaces: [], count: spaceListResponse.count };
          }
        }),
        catchError(this.handleError)
      );
  }

  getPopularSpacesByCityID(data: QueryParams): Observable<SpaceListResponse> {
    return this.http
      .get<SpaceListResponse>(
        `${this.config.value.apiUrl}cities/${data.id}/popular-spaces?page=${data.page}&count=${data.count}`
      )
      .pipe(
        retry(1),
        map((spaceListResponse: SpaceListResponse) => {
          if (spaceListResponse.spaces.length > 0) {
            this.filterByCityIdSubject.next(data.id);
            this.spaceCountSubject.next(spaceListResponse.count);
            const spacesData = spaceListResponse.spaces
            return { spaces: spacesData, count: spaceListResponse.count };
          }
          else{
            return { spaces: [], count: spaceListResponse.count };
          }
        }),
        catchError(this.handleError)
      );
  }

  getPopularSpacesByCitySlug(data: QueryParams): Observable<SpaceListResponse> {
    if (!data || !data.citySlug) {
      return;
    }
    return this.http
      .get<SpaceListResponse>(
        `${this.config.value.apiUrl}/v2/cities/slug/${data.citySlug}/popular-spaces?page=${data.page}&count=${data.count}` )
  }

  getPopularSpacesByLatLong(data: QueryParams): Observable<SpaceListResponse> {
    return this.http
      .get<SpaceListResponse>(
        `${this.config.value.apiUrl}popular-spaces?page=${data.page}&count=${data.count}&lat=${data.lat}&long=${data.long}`
      )
      .pipe(
        retry(1),
        map((spaceListResponse: SpaceListResponse) => {
          if (spaceListResponse.spaces.length > 0) {
            this.filterByCityIdSubject.next(data.id);
            this.spaceCountSubject.next(spaceListResponse.count);
            const spacesData =  spaceListResponse.spaces
            return { spaces: spacesData, count: spaceListResponse.count };
          }
          else{
            return { spaces: [], count: spaceListResponse.count };
          }
        }),
        catchError(this.handleError)
      );
  }


  getSpacesByCityID(data: QueryParams,filters: FiltersQuery): Observable<SpaceListResponse> {
    const filterObj = filters === null ? '{}' : JSON.stringify(filters);
    if(data?.id === null) {
      return;
    }
    return this.http
      .get<SpaceListResponse>(
        `${this.config.value.apiUrl}cities/${data.id}/spaces?page=${data.page}&count=${data.count}&filter=${filterObj}`
      )
      .pipe(
        retry(1),
        map((spaceListResponse: SpaceListResponse) => {
          if (spaceListResponse.spaces.length > 0) {
            this.filterByCityIdSubject.next(data.id);
            this.spaceCountSubject.next(spaceListResponse.count);
            const spacesData = spaceListResponse.spaces
            
            return { spaces: spacesData, count: spaceListResponse.count };
          }
          else{
            return { spaces: [], count: spaceListResponse.count };
          }
        }),
        catchError(this.handleError)
      );
  }

  getSpacesFormattedData(spaces: Spaces[]): Spaces[] {
    for (const space of spaces) {
      if (space.seatTypes.length > 0) {
        const seatTypesArr = space.seatTypes;
        const arrOfSeatCategoryAmountInCabin: {
          amount: number;
          duration: string;
        }[] = [];
        const arrOfSeatCategoryAmountInDesk: {
          amount: number;
          duration: string;
        }[] = [];
        for (const seatTypeArr of seatTypesArr) {
          const seatCategoryType = seatTypeArr;
          if (seatCategoryType.seatCategory.isMultiSeater) {
            arrOfSeatCategoryAmountInCabin.push({
              amount:
                Object.keys(seatCategoryType.pricing).length === 0
                  ? 0
                  : +this.getPricingsAmount(seatCategoryType.pricing),
              duration: this.getDurationData(seatCategoryType.pricing),
            });
          } else {
            arrOfSeatCategoryAmountInDesk.push({
              amount:
                Object.keys(seatCategoryType.pricing).length === 0
                  ? 0
                  : +this.getPricingsAmount(seatCategoryType.pricing),
              duration: this.getDurationData(seatCategoryType.pricing),
            });
          }
        }

        const minimumObjForCabin = this.getMinimumValue(
          arrOfSeatCategoryAmountInCabin
        );
        const minimumObjForDesk = this.getMinimumValue(
          arrOfSeatCategoryAmountInDesk
        );
        // space.cabinPrice = {
        //   amount: minimumObjForCabin.amount || undefined,
        //   duration: minimumObjForCabin.duration || undefined,
        // };
        space.deskPrice = {
          amount: minimumObjForDesk.amount || undefined,
          duration: minimumObjForDesk.duration || undefined,
        };
      }
    }
    return spaces;
  }

  getMinimumValue(arr: { amount: number; duration: string }[]): {
    amount: number;
    duration: string;
  } {
    if (arr.length) {
      let min = arr[0].amount;
      for (let i = 1, len = arr.length; i < len; i++) {
        if (arr[i].amount && arr[i].amount !== 0) {
          const v = arr[i].amount;
          min = v < min ? v : min;
        }
      }
      let duration: string;
      arr.forEach((obj) => {
        if (obj.amount && obj.amount === min) {
          duration = obj.duration;
        }
      });
      return { amount: min, duration };
    } else {
      return { amount: undefined, duration: undefined };
    }
  }

  getPricingsAmount(pricing: Pricing): number {
    const values = Object.values(pricing);
    if (values.length > 0) {
      const pricingValue: number[] = [];
      for (const price of values) {
        pricingValue.push(+this.getPricings(price));
      }
      const minValue = Math.min(...pricingValue);
      return minValue;
    } else {
      const minValue = +this.getPricings(values[0]);
      return minValue;
    }
  }

  getDurationData(pricing: Pricing): DurationInString {
    const values = Object.entries(pricing);
    if (values.length > 0) {
      const pricingValue: { index: DurationInString; amount: number }[] = [];
      for (const [i, v] of values.entries()) {
        pricingValue.push({
          index: this.getDurationInString(v[0]),
          amount: +this.getPricings(v[1]),
        });
      }
      const arrOfPrices: number[] = pricingValue.map(
        (price: { index: DurationInString; amount: number }) => price.amount
      );
      const minValue = Math.min(...arrOfPrices);
      const index = pricingValue.findIndex(
        (priceObj: { index: string; amount: number }) =>
          priceObj.amount === minValue
      );
      if (index > -1) {
        return pricingValue[index].index;
      } else {
        return 'NA';
      }
    } else {
      return 'NA';
    }
  }

  getPricings(pricing: Amount): string {
    if (pricing && pricing.amount) {
      return `${pricing.amount / Math.pow(10, pricing.precision)}`;
    } else {
      return '0';
    }
  }

  getPricingDuration(pricing: Pricing): string {
    if (pricing.monthly) {
      return 'month';
    } else if (pricing.weekly) {
      return 'week';
    } else if (pricing.yearly) {
      return `year`;
    } else {
      return 'NA';
    }
  }

  getDurationInString(durationVal: string): DurationInString {
    if (durationVal === 'monthly' || durationVal === 'Monthly') {
      return 'month';
    } else if (durationVal === 'weekly' || durationVal === 'Weekly') {
      return 'week';
    } else if (durationVal === 'yearly' || durationVal === 'Yearly') {
      return `year`;
    } else if (durationVal === 'quarterly' || durationVal === 'quaterly' || durationVal === 'Quarterly') {
      return `quarter`;
    } else {
      return 'NA';
    }
  }

  handleError(error): ReturnType<typeof throwError> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
      console.error(error);
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      console.error(error);
      // window.alert(errorMessage);
    }

    return throwError(errorMessage);
  }
}
