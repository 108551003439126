import { LabelType, Options } from '@angular-slider/ngx-slider';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { FilterParameterForPrice } from '../filters.service';

@Component({
  selector: 'app-your-budget',
  templateUrl: './your-budget.component.html',
  styleUrls: ['./your-budget.component.scss']
})
export class YourBudgetComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() pricesObj = new EventEmitter<FilterParameterForPrice>();
  @Output() seatTypeId = new EventEmitter<string>();
  @ViewChild('minInputValue', { static: true }) minInputValue: ElementRef;
  @ViewChild('maxInputValue', { static: true }) maxInputValue: ElementRef;
  minValue = 1000;
  maxValue = 20000;
  seatTypeFormControl = new FormControl();
  options: Options = {
    floor: 0,
    ceil: 50000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
      case LabelType.Low:
        return (
          '<div class=\'bg-light py-2 px-2\' style=\'color:#20847d; border-radius:20px;\'>' +
            '₹' +
            value +
            '<div>'
        );
      case LabelType.High:
        return (
          '<div class=\'bg-light py-2 px-2\'style=\'color:#20847d; border-radius:20px;\'>' +
            '₹' +
            value +
            '<div>'
        );
      default:
        return '' + value;
      }
    },
  };
  private subs = new SubSink();

  constructor() { }

  ngOnInit(): void {
    this.subs.add(
      this.seatTypeFormControl.valueChanges.subscribe((value) => {
        this.seatTypeId.emit(value);
      })
    );
  }

  sliderEvent(): void {
    this.pricesObj.emit({
      minimum: this.minValue * 100,
      maximum: this.maxValue * 100
    });
  }

  ngAfterViewInit(): void{
    this.subs.add(
      fromEvent(this.minInputValue.nativeElement, 'keyup')
        .pipe(debounceTime(500))
        .subscribe(res => {
          this.sliderEvent();
        })
    );
    this.subs.add(
      fromEvent(this.maxInputValue.nativeElement, 'keyup')
        .pipe(debounceTime(500))
        .subscribe(res => {
          this.sliderEvent();
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
