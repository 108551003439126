<app-your-budget 
(pricesObj)="addPricesToFilter($event)"
(seatTypeId)="addSeatTypes($event)"></app-your-budget>
<ng-container *ngIf="(pricesData$ | async) as pricesData">
    <ng-container *ngIf="pricesData.length > 0">
        <div class="row">
            <div class="col-12" *ngFor="let prices of pricesData;let i=index;">
                <ng-container *ngIf="i<4">
                    <app-prices 
                    [minPrice]="prices.minimum" 
                    [maxPrice]="prices.maximum" 
                    [count]="prices.count"
                    (pricesObj)="addPricesToFilter($event)"></app-prices>
                </ng-container>    
            </div>
            <div class="collapse" id="showmore1">
                <div class="col-12" *ngFor="let prices of pricesData;let i=index;">
                    <ng-container *ngIf="i>3">
                        <app-prices 
                        [minPrice]="prices.minimum" 
                        [maxPrice]="prices.maximum" 
                        [count]="prices.count"
                        (pricesObj)="addPricesToFilter($event)"></app-prices>
                    </ng-container>    
                </div>
            </div>
        </div>
        <div class="row">
            
        </div>
        <div class="d-flex justify-content-between flex-row mt-2">
            <span class='information-text'>All prices are per month basis</span>
            <a (click)="readMore1 = !readMore1" class="link-text" data-bs-toggle="collapse"
                href="#showmore1" role="button" aria-expanded="false"
                aria-controls="showmore1">
                {{readMore1 ? "Show More" : "Show Less"}}
            </a>
        </div>
    </ng-container>
    <ng-container>
        <div class="row" *ngIf="(isLoading$ | async)">
            <div class="col-12">
                <!-- <app-bootstrap-loader></app-bootstrap-loader> -->
            </div>
        </div>
    </ng-container>
</ng-container>

