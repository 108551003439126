<div class="dialog-container ">
    <div class="float-end me-3 tab" (click)="closeDialog()">
      <img src="assets/icons/Cancel.svg" width="16" height="16" alt="otp verification dialog cancle btn">
    </div>
    <div class="p-lg-4 p-2 m-2 mt-3">
       <p class="heading-text">Verify your number</p>
       <span class="me-3 content-text">A Verification code was sent to your contact number 
        <span class="number-text">{{userData?.mobile}}</span>
        <span class="tab" (click)="changeMobileNumber()" ><img class="ms-2 edit-btn" src="assets/icons/edit.png" alt="edit"></span>
       </span> 
    
       <div class="d-flex justify-content-between mt-3">
        <p class="sub-text">Enter verification code</p>
        <!-- <p class=" me-3 content-text">05:00</p> -->
       </div>
       <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="config"></ng-otp-input>
       <p class="link-desc-text mt-3" *ngIf="loadResendOtpText === false">Didn’t recieve the code?
         <a class="link-text ms-2" (click)="resendCode()">Resend Code</a>
        </p>
        <div class="link-desc-text mt-3" *ngIf="loadResendOtpText === true">
          <app-bootstrap-loader class="link-text ms-2"></app-bootstrap-loader>
        </div>
       <p class="link-text"><a>Get Via Call</a></p>
       <div class="mt-5 me-3">
        <div
          *ngIf="loadOtpVerifyBtn === false"
          [attr.disabled]="!otpEntered"
          [ngClass]=" !otpEntered ?
          'btn disabled-btn p-3 btn-text w-100':
          'btn btn-primary-base p-3 btn-text text-center w-100'">
           Verify 
       </div> 
       <div *ngIf="loadOtpVerifyBtn === true">
         <app-bootstrap-loader></app-bootstrap-loader>
       </div>
       
    </div>
</div>
