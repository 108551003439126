import { Component, OnInit } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-why-co-working',
  templateUrl: './why-co-working.component.html',
  styleUrls: ['./why-co-working.component.scss'],
  animations:[
    trigger('fade',[
      state('void', style({ opacity : 0})),
      transition(':enter',[ animate(600)]),
      transition(':leave',[ animate(500)]),
    ]
    )]
})
export class WhyCoWorkingComponent implements OnInit {
  defaultImage = 'https://www.placecage.com/1000/1000';
  zerosetup= 'assets/images/Landing-page/zero-setup-and-maintenance.jpg';
  amentiesLove= 'assets/images/Landing-page/amenities.jpg';
  commColl= 'assets/images/Landing-page/community.jpg';
  flexible = 'assets/images/Landing-page/flexibility.jpg';
  scrolldiv = false;


  constructor() {}

  ngOnInit(): void {
    {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      function divScroll(): void{
        const y = window.scrollY;
        const width = window.innerWidth;
        if ((y < 1000 || y > 2000)) {
          const header = document.getElementsByClassName('onscroolfixed')[0];
          header?.classList.remove('divfix');
          header?.classList.remove('fade-in-text');
        }
        else if (y > 1000 && width > 900) {
          const header = document.getElementsByClassName('onscroolfixed')[0];
          header?.classList.add('divfix');
          header?.classList.add('fade-in-text');
        }

      }
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      function colChange(): void{
        const y = window.scrollY;

        if(y < 1500){
          const color = document.getElementsByClassName('headertxtcol')[0];
          color?.classList.remove('text-white');
        }

        else if(y > 1500){
          const color = document.getElementsByClassName('headertxtcol')[0];
          color?.classList.add('text-white');
        }

      }
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      window.addEventListener('scroll', function() {
        divScroll();
        colChange();
      });
    }
  }
}

