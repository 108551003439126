import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { mediaContent } from 'src/app/Helper/media-talk-about-us';
import { RestApiService } from 'src/app/shared/services/common-services/rest-api.service';

@Component({
  selector: 'app-media-talk-about-us',
  templateUrl: './media-talk-about-us.component.html',
  styleUrls: ['./media-talk-about-us.component.scss']
})
export class MediaTalkAboutUsComponent implements OnInit {
  mediaContent = mediaContent
  newsLogo = [];
  loading = false;
  defaultUrl = 'assets/images/news/mail-today.png';
  customOptions: OwlOptions = {
    autoplay:true,
    autoplaySpeed:500,
    autoplayHoverPause:true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 100,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      500: {
        items: 2
      },
      760: {
        items: 3
      },
      1000: {
        items: 3
      }
    },
    nav: false
  };
  constructor(
    public restApi: RestApiService
  ) { }

  ngOnInit(): void {
    // this.loading = true;
    // this.restApi.getLogo().subscribe(logoData =>{
    //   this.newsLogo = logoData;
    //   this.loading = false;
    // });
  }
}
