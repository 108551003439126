import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  BrowserModule,
  BrowserTransferStateModule,
  Meta,
} from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
//material imports
import { MatDialogModule } from '@angular/material/dialog';


//app imports
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
// eslint-disable-next-line max-len
import { Security } from './Helper/security';
import { CookieInterceptorService } from './shared/interceptors/cookie.interceptor';
import { EnvConfigurationService } from './Helper/environment-configuration.service';
import { CommonComponentsModule } from './components/common/common-components.module';
import { MoreCitiesComponent } from './components/more-cities/more-cities.component';
import { HomeModule } from './pages/home/home.module';
import { RequestTerminateAlertComponent } from './components/request-terminate-alert/request-terminate-alert.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    MoreCitiesComponent,
    RequestTerminateAlertComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    CommonComponentsModule,
    AppRoutingModule,
    FormsModule,
    HomeModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyARnAr_HKQe-mSVVJr7ZTlx-QilmPaGw_o',
      libraries: ['places']
    }),
  ],

  providers: [
    Security,
    Meta,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CookieInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (envConfigService: EnvConfigurationService) => (): Promise<void> =>
        envConfigService.load(),
      deps: [EnvConfigurationService],
      multi: true,
    },
    GoogleTagManagerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
