<div class="company-box container">
    <div class="mt-md-0 mt-3">
        <h2 class="text-center fs-biger">
            The media’s been talking about us too!
        </h2>
    </div>
    <div class="mt-4 pt-md-3 px-md-5 px-2">
        <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let data of mediaContent">
                <ng-template carouselSlide>
                    <div class="card-container px-md-4 px-3 pt-4  text-white ">
                    <div class="d-flex align-items-start flex-column h-100">
                        <div class="mb-auto">
                            <p class="inverted-comma m-0 mt-md-4 mt-3">“</p>
                        <p class="fs-regular fw-500 line-larger text-dark description m-0 ">{{data?.description}}</p>
                        </div>
                        <div>
                            <div class="seprate-line"></div>
                            <div class="d-flex justify-content-between align-items-center">
                                <img class="media-icon" [src]="data?.mediaImage" alt="media news" loading="lazy">
                                <a [href]="data?.redirectionLink" target="new" class="fs-regular line-regular fw-600 text-primary-base m-0 tab">Read more</a>
                            </div>
                        </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
    <div class="d-flex justify-content-center align-items-center py-5" *ngIf="loading">
        <app-bootstrap-loader></app-bootstrap-loader>
    </div>
</div>