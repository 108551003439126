<!-- <h2 class="heading mb-4 ng-star-inserted">Select filters</h2> -->
<ng-container *ngIf="!loading">
    <div class="row">
        <!--- Your Budget---->
    
        <!-- <div class="col-md-4 order-0" *ngIf="filtersData?.budget && filtersData?.budget?.length > 0">
            <div class="mt-1">
                <span class="sub-heading">Your Budget</span>
                <div class="mt-3">
                    <app-horizontal-budget-layout
                    (pricingValues)="addPriceToFilter($event)"
                    (seatTypesId)="addSeatType($event)"></app-horizontal-budget-layout>
                </div>
            </div>
        </div> -->
    
        <!-- Popular filters -->
        <!-- <div class='col-md-4'>
            <div class="border-start border-2 ps-3">
                
                <app-popular-filters></app-popular-filters>
            </div>
        </div> -->
        
        <!---Locational preferences--->
    
        <!-- <div class="col-md-4" *ngIf="filtersData?.landmarks && filtersData?.landmarks?.length > 0">
            
            <div class="border-start border-2 ps-3">
                <app-location-preference (nearByIds)="addLocationPreferences($event)"></app-location-preference>
            </div>
        </div> -->
    </div>
    <div class="row">
        <div class="col-md-4" *ngIf="filtersData?.landmarks && filtersData?.landmarks?.length > 0 && filterDataKeys?.length < 3">
            
            <div class="border-end border-2 pe-3 mt-3">
                <app-location-preference (nearByIds)="addLocationPreferences($event)"></app-location-preference>
            </div>
        </div>
        <ng-container *ngIf="areaSlug === null">
            <div class="col-md-4" *ngIf="filtersData?.popularLocations && filtersData?.popularLocations?.length > 0">
                <!---popular locations-->
                <div class="border-end border-2 pe-3 mt-3">
                    <span class="sub-heading">Popular Locations</span>
                    <div class="mt-3">
                        <app-popular-locations (popularLocationIdArr)="addPopularLocations($event)"></app-popular-locations>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="col-md-4" *ngIf="filtersData?.amenities && filtersData?.amenities?.length > 0">
            <!-----Amenities available----->
            <div class="border-end border-2 pe-3 mt-3">
                <span class="sub-heading">Amenities available</span>
                <div class="mt-3">
                    <app-amenity (amenitiesIdArr)="addAmenities($event)"></app-amenity>
                </div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="filtersData?.desks && filtersData?.desks?.length > 0">
            <!-----Desk Preferences------>
            <div class="mt-3">
                <span class="sub-heading">Desk Preferences</span>
                <div class="mt-3">
                    <app-desk-preference (deskPreferenceIdArr)="addDeskPrefernece($event)"></app-desk-preference>
                </div>
            </div>
        </div>
    </div>
    <div class="row collapse mt-4" id="horizontalFilter">
        <div class="col-md-4" *ngIf="filtersData?.landmarks && filtersData?.landmarks?.length > 0 && filterDataKeys?.length > 3">
            <div class="border-end border-2 pe-3 mt-3">
                <app-location-preference (nearByIds)="addLocationPreferences($event)"></app-location-preference>
            </div>
        </div>
    </div>
</ng-container>
<div class="d-flex align-items-center justify-content-center py-3" *ngIf="loading">
    <app-bootstrap-loader></app-bootstrap-loader>
</div>
<div *ngIf="filterDataKeys?.length > 3" class="text-center next-btn" data-bs-toggle="collapse"
    data-bs-target="#horizontalFilter" aria-expanded="false" aria-controls="horizontalFilter" (click)="iconRotate = ! iconRotate">
    <img src="assets/icons/bottom-next-btn.svg" alt="next" [ngStyle]="{'transform': iconRotate ? 'rotate(-180deg)': 'rotate(0deg)'}">
</div> 