<ng-container *ngIf="areaSlug === null || areaSlug === undefined">
    <div class="list">
        <!-- <mat-slide-toggle class="mb-2"> Set your own location</mat-slide-toggle> -->
        <ng-container *ngIf="areaSlug === null || areaSlug === undefined">
            <ng-container *ngIf="popularLocationsData?.length > 0">
                <ng-container *ngFor="let location of popularLocationsData;let i=index;">
                    <div class="d-flex justify-content-between flex-row align-items-center" *ngIf="i<5">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                            [checked]="location === true ? true : false"
                                [id]="'popularLocations'+i" (change)="onAddPopularLocations($event,location)">
                            <label class="form-check-label ms-1 fs-regular" [for]="'popularLocations'+i">
                                {{location?.name}}
                            </label>
                        </div>
                        <div>
                            <span class="list-text">({{location?.count}})</span>
                        </div>
                    </div>
                </ng-container>
                <div class="collapse" id="showmore3" *ngIf="popularLocationsData.length > 5">
                    <div class="col-12" *ngFor="let location of popularLocationsData;let i=index;">
                        <div class="d-flex justify-content-between flex-row align-items-center" *ngIf="i>4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value=""
                                [checked]="location === true ? true : false"
                                    [id]="'popularLocations'+i" (change)="onAddPopularLocations($event,location)">
                                <label class="form-check-label ms-1 fs-regular" [for]="'popularLocations'+i">
                                    {{location?.name}}
                                </label>
                            </div>
                            <div>
                                <span class="list-text">({{location?.count}})</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </ng-container>
        
    </div>
    <div class="d-flex justify-content-end flex-row mt-2" *ngIf="popularLocationsData?.length > 5">
        <a (click)="readMore = !readMore" class="link-text" data-bs-toggle="collapse"
            href="#showmore3" role="button" aria-expanded="false"
            aria-controls="showmore3">
            {{readMore ? "Show More" : "Show Less"}}
        </a>
    </div>
</ng-container>