<span class="sub-heading fs-large" >Locational preferences</span>
<div class="mt-3">
    <div class="list">
        <ng-container *ngIf="(locationPreferencesLandmark$ | async) as locationLandmarks">
            <ng-container *ngIf="locationLandmarks.length > 0">
                <ng-container *ngFor="let location of locationLandmarks;let i=index;">    
                    <div class="d-flex justify-content-between flex-row align-items-center" *ngIf="i<5">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                [id]="'locationLandmarks'+i" (change)="onAddLocationPreference($event,location)">
                            <label class="form-check-label ms-1" [for]="'locationLandmarks'+i">
                               Nearby {{location?.name}}
                            </label>
                        </div>
                        <div>
                            <span class="list-text">({{location?.count}})</span>
                        </div>
                    </div>
                </ng-container>
                <div class="collapse" id="showmore2" *ngIf="locationLandmarks.length > 5">
                    <ng-container *ngFor="let location of locationLandmarks;let i=index;">
                        <div class="d-flex justify-content-between flex-row align-items-center" *ngIf="i>4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value=""
                                [id]="'locationLandmarks'+i" (change)="onAddLocationPreference($event,location)">
                                <label class="form-check-label ms-1" [for]="'locationLandmarks'+i">
                                   Nearby {{location?.name}}
                                </label>
                            </div>
                            <div>
                                <span class="list-text">({{location?.count}})</span>
                            </div>
                        </div> 
                    </ng-container> 
                </div>
            </ng-container>
            <!-- <ng-container>
                <div class="d-flex justify-content-between flex-row align-items-center" *ngIf="(isLoading$ | async)">
                    <app-bootstrap-loader></app-bootstrap-loader>
                </div>
            </ng-container> -->
        </ng-container>
    </div>
    <div class="d-flex justify-content-end flex-row mt-2"  *ngIf="(locationPreferencesLandmark$ | async)?.length > 5">
        <a (click)="readMore2 = !readMore2" class="link-text" 
            data-bs-toggle="collapse"
            href="#showmore2" role="button" aria-expanded="false"
            aria-controls="showmore2">
            {{readMore2 ? "Show More" : "Show Less"}}
        </a>
    </div>

</div>