import { Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-request-a-quote',
  templateUrl: './request-a-quote.component.html',
  styleUrls: ['./request-a-quote.component.scss']
})
export class RequestAQuoteComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<RequestAQuoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data:string,
  ) { }


  ngOnInit(): void {
  }

  afterCloseModal(close: boolean): void {
    if(close){
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
