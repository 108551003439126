<div class="container-fluid workspace-list mt-4">
  <div class="container">
    <ng-container *ngIf="(spacesData?.spaces) && (isLoading == false)">
      <ng-container *ngIf="spacesData?.spaces.length > 0">
        <div class="row card-container " *ngFor="let space of spacesData?.spaces;trackBy: trackByFn; let i = index">
          <div class="col col-12 col-md-4 position-spacesData">
            <div [hidden]="!imageLoader">
              <app-bootstrap-loader style="margin-left:80px;"></app-bootstrap-loader>
            </div>
            <img *ngIf="space?.spaceCategory" class="category-badge" [src]="space?.badge" alt="">
            <section class="image-section">
              <div class="image-wrapper">
                <img class="rounded tab" [hidden]="imageLoader" title="{{space?.name}}"
              src="{{getImagePath(space && space?.images)}}" alt="{{space?.name}}"
              (click)="moreImages({images: space?.images, spaceName: space?.name, address: space?.address })"
              (load)="imageLoader = false" (error)="onErrorHandling($event)" />
              </div> 
            </section>

            
            <div class="d-lg-flex justify-content-between d-md-none d-none">
              <ng-container *ngFor="let img of getSpaceImages(space && space?.images)">
                <img class="crousel-img rounded tab" src="{{img}}" [title]="space?.name" alt="{{space?.name}}"
                  height="80px" width="80px"
                  (click)="moreImages({images: space?.images, spaceName: space?.name, address: space?.address })"
                  (error)="onErrorHandling($event)" />
              </ng-container>

              <div [class]="space?.images.length >= 4 ? 'more-imgs tab':'tab'"
                (click)="moreImages({images: space?.images, spaceName: space?.name, address: space?.address })">
                <img class="crousel-img rounded" src="{{getMoreImageUrl(space?.images)}}" alt="{{space?.name}}"
                  height="80px" width="80px" (error)="onErrorHandling($event)" />
                <span *ngIf="space?.images.length >= 4">
                  <h2>+{{space?.images.length - 3}}</h2>
                  <span>MORE</span>
                </span>
              </div>


            </div>
          </div>
          <div class="col col-12 col-md-8">
            <img class="d-lg-block d-none" src="assets/images/Stylesafe.png" alt="safety icon"
              *ngIf="space?.styleSafe" />
            <h2 class="mt-3 mt-md-0 mt-lg-3 fs-huge tab" (click)="routeToSpaceDetails(space)">{{ space?.name }}</h2>
            <div class="row contact-details">
              <div class="col col-12 col-md-12 col-lg-8">
                <p class="mb-2">
                  <span><img src="assets/icons/Location1.svg" height="15" width="15" alt="Location" />
                  </span>
                  <span *ngIf="space?.address.length > 65">{{space?.address.substring(0,60)}}...</span>
                  <span *ngIf="space?.address.length <= 65">{{space?.address}}</span>
                </p>
                <!-- <p>
                  <ng-container *ngIf="space?.nearby.length > 0">
                    <span>
                      <img src="{{space?.nearby[0]?.type?.icon}}" alt="{{space?.nearby[0]?.type?.name}} icon"
                        height="15" width="18" />
                    </span>
                    {{space?.nearby[0]?.name}}
                    <b>({{space?.nearby[0]?.approxDistance}})</b>
                  </ng-container>
                  <ng-container *ngIf="!space?.nearby || space?.nearby.length == 0">
                    <span>
                      <img height="15" width="15" src="assets/icons/Nearest-metro.svg" alt="metro">
                    </span>NA
                  </ng-container>
                </p> -->
              </div>
              <div class="col col-12 col-lg-4">
                <span class="d-none d-lg-block ">
                  <img height="15" width="15" src="assets/icons/clock.svg" alt="Timings icon" />
                </span>
                <span class="d-none d-lg-block ">{{getSpaceWorkingDays(space?.spaceTimings,timingsType.time)}}</span>
                <p class="text-muted m-0 ps-lg-3 d-none d-lg-block">
                  {{getSpaceWorkingDays(space?.spaceTimings,timingsType.days)}}</p>
              </div>
            </div>
            <div
              [ngClass]=" space?.styleSafe ? 'row contact-details mt-lg-3 mt-md-2 mb-lg-2 mb-3 mt-3':'row contact-details mt-lg-4 mt-md-2 mb-md-2 mb-3 mb-lg-4 mt-3'">
              <ng-container *ngFor="let amenityImg of getAmenityImgArray(space && space?.amenities)">
                <div class=" col col-3 col-md-3 sm-img-amen">
                  <div class="d-flex align-items-center">
                    <img height="auto" width="40px"src="{{amenityImg?.icon}}" alt="{{amenityImg?.name}} icon" />
                    <p class="ms-3 m-0 d-lg-block d-none">{{amenityImg?.name}}</p>
                  </div>

                </div>
              </ng-container>
              <div class="col col-3 col-md-3 mt-lg-0  " *ngIf="space?.amenities.length > 3">
                <div class="btn btn-secondary more-amenities">+{{space?.amenities.length - 3}} More
                  <div class="popover-amenities">
                    <app-more-amenities [amenities]="getMoreAmenities(space && space?.amenities)"></app-more-amenities>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-lg-block d-md-block d-none">
              <div
                [class]="space?.styleSafe ?'row contact-details mt-lg-4 pt-lg-3':'row contact-details mt-lg-5 pt-lg-3'">
                <div [ngClass]="{'col-md-6': 
                (!space.cabinPrice) || (!space?.cabinPrice?.amount?.amount)}" class="col-6 col-md-4">
                  <div class="price mt-lg-0 mt-md-0 mt-2">
                    <p class="mb-1">Desks from</p>
                    <h2 class="fs-big line-h5"
                    *ngIf="space?.passAmount && space.passAmount?.amount!= 0">
                    {{ helper.getFormattedAmount(space?.passAmount)}}/<small class="text-light-dark fs-medium">day</small>
                    <small *ngIf="space.seatPrice && space?.seatPrice?.amount?.amount && space?.seatPrice?.amount?.amount != 0" class="text-primary-base mx-2"> & </small>
                    </h2>
                    <h2 class="fs-big line-h5"
                    *ngIf="space.seatPrice && space?.seatPrice?.amount?.amount && space?.seatPrice?.amount?.amount != 0">
                    {{ helper.getFormattedAmount(space.seatPrice.amount)}}/<small class="text-light-dark fs-medium">{{space?.seatPrice?.type}}</small>
                    </h2>
                    <h2 class="fs-regular"
                    *ngIf="(!space?.seatPrice || !space?.seatPrice?.amount?.amount) && space.passAmount.amount===0">
                        {{amountMessage}}
                    </h2>
                  </div>
                </div>
                <div *ngIf="space.cabinPrice && space?.cabinPrice?.amount?.amount && space?.cabinPrice?.amount?.amount != 0" class="col-3 col-md-4 d-md-block  d-lg-block d-none">
                  <div class="price">
                    <p class="mb-1">Cabin from</p>
                    <h2 class="fs-big line-h5">
                      {{  helper.getFormattedAmount(space.cabinPrice.amount)}}/
                    </h2><small class="text-light-dark fs-medium"
                    *ngIf="space?.cabinPrice && space?.cabinPrice?.type">{{space.cabinPrice.type}}</small>

                    <h2 class="fs-regular line-h5"  *ngIf="!space?.cabinPrice || !space?.cabinPrice.amount?.amount">
                      {{amountMessage}}
                    </h2>
                  </div>
                </div>
                <div [ngClass]="{'col-md-6':(!space.cabinPrice) || (!space?.cabinPrice?.amount?.amount)}" class="col-6 col-md-4 position-relative">
                  <div class="btn btn-primary-base visit-page" (click)="routeToSpaceDetails(space)">Visit the space
                  </div>

                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center d-md-none d-lg-none mobile-view">
              <div class="price mt-lg-0 mt-md-0 mt-2">
                <p>Desks from</p>
                <h2 class="fs-big line-h5"
                    *ngIf="space?.passAmount && space.passAmount?.amount!= 0">
                    {{ helper.getFormattedAmount(space?.passAmount)}}/<small class="text-light-dark fs-medium">day</small>
                    <small *ngIf="space.seatPrice && space?.seatPrice?.amount?.amount && space?.seatPrice?.amount?.amount != 0" class="text-primary-base mx-2"> & </small>
                    </h2>
                    <h2 class="fs-big line-h5"
                    *ngIf="space.seatPrice && space?.seatPrice?.amount?.amount && space?.seatPrice?.amount?.amount != 0">
                    {{ helper.getFormattedAmount(space.seatPrice.amount)}}/<small class="text-light-dark fs-medium">{{space?.seatPrice?.type}}</small>
                    </h2>
                    <h2 class="fs-regular"
                    *ngIf="(!space?.seatPrice || !space?.seatPrice?.amount?.amount) && space.passAmount.amount===0">
                        {{amountMessage}}
                    </h2>
              </div>
              <div class="d-flex align-items-center btn-text" (click)="routeToSpaceDetails(space)">
                <p class="m-0 btn-text me-1 fw-bold">Visit the space </p>
                <img src="assets/icons/next-icon.png" alt="next icon">
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isLoading">
      <app-bootstrap-loader style="margin-left:50%;"></app-bootstrap-loader>
    </ng-container>
    <div class="col-12 col-md-12 text-center mt-4" *ngIf="!isLoading && spacesData?.spaces ">
      <button class="btn btn-secondary view_all" (click)="routeToExploreSpaces()">View All></button>
    </div>
  </div>
</div>