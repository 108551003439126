import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PopularLocations } from 'src/app/shared/interface';
import { RestApiService } from 'src/app/shared/services/common-services/rest-api.service';
import { SubSink } from 'subsink';
import { Security } from 'src/app/Helper/security';
import { Cities } from 'src/app/shared/interface/cities';
import { AppRouterService } from 'src/app/shared/services/router/app-router.service';
import { MatDialog } from '@angular/material/dialog';
import { MoreCitiesComponent } from '../../more-cities/more-cities.component';
import { RequestAQuoteComponent } from '../../request-a-quote/request-a-quote.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  popularCities$: Observable<PopularLocations[]>;
  popularLocationsData: PopularLocations[] = [];
  selectedLocation: PopularLocations;
  postalCode: number = null;
  searchCity: string = null;
  tierSelected: string = null;
  citiesList: Cities[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exploreCitiesData: PopularLocations[] = [];//string is using for data getting localstorage
  exploreSpaceMenuShow = false;
  imageLoader = true;
  private subs = new SubSink();
  constructor(
    private elRef: ElementRef,
    private restService: RestApiService,
    private security: Security,
    private dialog: MatDialog,
    private appRouterService: AppRouterService,
  ) { }

  ngOnInit(): void {
    this.getPopularCitiesList();
  }
  getPopularCitiesList(): void {
    this.subs.add(
      this.restService.getPopularCities()
        .subscribe((res: PopularLocations[]) => {
          this.exploreCitiesData = res;
        })
    );
  }

  closeCollapse(): void {
    const collapseRef = this.elRef.nativeElement.querySelectorAll('#navbarSupportedContent');
    const innerWidth = window.innerWidth;
    if(innerWidth < 992){
      collapseRef[0].className='collapse navbar-collapse';
    }
  }

  blogAndResources(): void{
    window.open('https://blogs.stylework.city/');
    this.closeCollapse();
  }

  routeSpacesExplorationByToPopularLocations(city: PopularLocations): void {
    this.closeCollapse();
    this.appRouterService.routeToSpaceExplorationUsingCitySlug(Object.assign(city));
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MoreCitiesComponent,{
      width:'98vw',
    position:{top:'0px',},
      disableClose: true,
      data:{ route: true}
    });
    dialogRef.afterClosed().subscribe(
      res => {
        this.closeCollapse();
      }
    );
  }

  requestAQuoteDialog(): void {
    this.closeCollapse();
    this.dialog.open(RequestAQuoteComponent,{
      position:{top:'70px',},
      disableClose: true,
    });
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


}
