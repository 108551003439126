<app-banner></app-banner>
<app-style-work-stats></app-style-work-stats> 
<app-why-co-working></app-why-co-working>
<app-workspaces></app-workspaces>
<app-get-started></app-get-started>
<app-style-work-space></app-style-work-space>
<app-footer [heading]="testimonialHeading" [subheading]="testimonialSubHeading" [hideFooterClass]='hideFooterClass'></app-footer>
<!-- <app-masonry></app-masonry> -->
<app-media-talk-about-us></app-media-talk-about-us>
<app-main-footer></app-main-footer>
