import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DefaultPrice, FilterParameterForPrice, FilterService } from '../../filters.service';

@Component({
  selector: 'app-horizontal-budget-layout',
  templateUrl: './horizontal-budget-layout.component.html',
  styleUrls: ['./horizontal-budget-layout.component.scss']
})
export class HorizontalBudgetLayoutComponent implements OnInit {
  @Output() pricingValues = new EventEmitter<FilterParameterForPrice>();
  @Output() seatTypesId = new EventEmitter<string>();
  pricesData$: Observable<DefaultPrice[]>;
  readMore = true;

  constructor(
    private filterService: FilterService,
  ) { }

  ngOnInit(): void {
    this.pricesData$ = this.filterService.defaultPrices$;
  }

  addPricesToFilter(price: FilterParameterForPrice): void {
    this.pricingValues.emit(price);
  }

  addSeatTypes(seatTypeId: string): void {
    this.seatTypesId.emit(seatTypeId);
  }
}
