<div class="d-flex align-items-center justify-content-center py-3" *ngIf="loading">
    <app-bootstrap-loader></app-bootstrap-loader>
</div>
<div class="pt-4 similar-space" *ngIf="similarWorkPlaces?.length > 0">
    <div class="float-end d-md-none d-inline">
        <div class="list-map-btn" *ngIf="listMapBtn" (click)="listMapBtn = !listMapBtn">
            <img src="assets/icons/space-details/Map-Location.png" alt="map icon" height="16" width="16">
            <a class="btn-text ms-1">Map View</a>
        </div>
        <div class="list-map-btn" *ngIf="!listMapBtn" (click)="listMapBtn = !listMapBtn">
            <img src="assets/icons/space-details/Map-Location.png" alt="map icon" height="16" width="16">
            <a class="btn-text ms-1">List View</a>
        </div>
    </div>
    <h2 class="fs-largest">Similar Workspaces</h2>
    <div class="row mt-4">
        <div class="col-md-6" [ngClass]="listMapBtn ? 'col-md-6 d-block':'col-md-6 d-md-block d-none'">
            <!----Similar and neaar by tab hide--->

            <div class="space-scroller-container">
                <div [class]="i < similarWorkPlaces.length-1?'rounded p-3 mb-4 space-container':'rounded p-3 space-container'"
                    *ngFor="let space of similarWorkPlaces;let i = index">
                    <div class="row">
                        <div class="col-md-3 col-3 pe-0 tab space-img">
                            <img class="rounded main-image img-fluid" [src]="space?.images && 
                                space?.images.length > 0 ? 
                                space?.images[0] : 
                                'assets/icons/space-details/Space-img.png'" alt="space-image" title="{{space?.name}}"
                                (error)="$event.target.src='assets/icons/space-details/Space-img.png'"
                                (click)=" moreImages({images: space?.images, spaceName: space?.name, address: space?.address})">
                                <img *ngIf="space?.spaceCategory" class="category-badge" [src]="space?.badge" alt="">

                        </div>
                        <div class="col-md-9 col-9">
                            <div class="ms-2">
                                <h2 class="fs-largest tab" (click)="routeToSpaceDetails(space)">{{space?.name
                                    |truncate:{sliceStart:0,
                                    sliceEnd:25, append: '...'} }}</h2>
                                <div class="d-flex">
                                    <div>
                                        <img src="assets/icons/location-point.svg" alt="location point">
                                    </div>
                                    <div class="ms-2">
                                        <p class="description mb-0 d-md-block d-none">
                                            {{space?.address|truncate:{sliceStart:0,
                                            sliceEnd:80, append: '...'} }}
                                        </p>
                                        <p class="description mb-0 d-md-none d-block">
                                            {{space?.address|truncate:{sliceStart:0,
                                            sliceEnd:50, append: '...'} }}
                                        </p>
                                    </div>
                                </div>
                                <!-- <div class="d-flex mt-1">
                                    <div>
                                        <img [src]="space?.nearby.length > 0 ? 
                                            space?.nearby?.type?.icon : 'assets/icons/Metro.svg'"
                                            alt="landmark icon"
                                            (error)="$event.target.src='assets/icons/Metro.svg'">
                                    </div>
                                    <div class="ms-2">
                                        <span class="description" *ngIf="space?.nearby.length > 0">
                                            {{space?.nearby[0]?.name}} <b>(
                                                {{space?.nearby[0]?.approxDistance}})</b>
                                        </span>
                                        <span class="description" *ngIf="space?.nearby.length == 0">
                                            NA
                                        </span>
                                    </div>
                                </div> -->
                                <div class="d-flex mt-4 dnone-mob"
                                    *ngIf="space?.amenities && space?.amenities.length > 0">
                                    <div class="me-lg-4 me-2" *ngFor="let amenity of space?.amenities | slice:0:4">
                                        <img [src]="amenity?.icon" alt="{{amenity?.name}} icon" [title]="amenity?.name"
                                            style="height:auto;width:26px;">
                                    </div>
                                    <div *ngIf="space.amenities.length > 4">
                                        <div class="more-amenities rounded">
                                            +{{space.amenities.length - 4}} More
                                            <div class="popover-amenities">
                                                <app-more-amenities [smallSize]="true"
                                                    [amenities]="getMoreAmenities(space?.amenities)"></app-more-amenities>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="d-flex justify-content-md-between justify-content-end align-items-center flex-row mt-3">
                            <div *ngIf="space.seatPrice && space?.seatPrice?.amount?.amount && space?.seatPrice?.amount?.amount != 0"
                                class="col-6 col-md-6 ps-3 ps-md-0">
                                <p class="desk-text fs-regular mb-0 me-lg-0 me-4">Desks from</p>
                                <p class="price-text fs-big mb-0">
                                    ₹{{helper.getFormattedAmount(space?.seatPrice?.amount)}}/

                                    <span class="desk-text fs-regular"
                                        *ngIf="space?.seatPrice && space?.seatPrice?.type">{{space?.seatPrice?.type}}</span>
                                </p>
                                <p class="price-text mb-0 fs-large"
                                    *ngIf="!space?.seatPrice || !space?.seatPrice?.amount?.amount">
                                    {{amountMessage}}
                                </p>

                            </div>

                            <ng-container
                                *ngIf="!(space.seatPrice && space?.seatPrice?.amount?.amount && space?.seatPrice?.amount?.amount != 0)">
                                <div *ngIf="space?.cabinPrice && space?.cabinPrice?.amount && space?.cabinPrice?.amount != 0"
                                    class="col-6 col-md-6 ps-3 ps-md-0">
                                    <p class="desk-text fs-regular mb-0 me-lg-0 me-4">Cabin from</p>
                                    <p class="price-text fs-big mb-0">
                                        ₹{{ helper.getFormattedAmount(space?.cabinPrice?.amount)}}/

                                        <span class="desk-text fs-regular"
                                            *ngIf="space?.cabinPrice && space?.cabinPrice?.type">{{space?.cabinPrice?.type}}</span>
                                    </p>
                                </div>
                            </ng-container>


                            <div class="mt-2 mt-lg-4 col-md-6 col-3 text-end img-xs-5 ms-md-auto">
                                <p class="link-text tab m-0" (click)="routeToSpaceDetails(space)">
                                    Visit Space
                                    <img class="ms-1" src="assets/icons/next-icon.png" alt="next icon">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [ngClass]="!listMapBtn ? 'col-md-6 d-block':'col-md-6 d-md-block d-none'">
            <div *ngIf="similarWorkPlaces.length > 0">
                <app-map-view [membershipSelection]='false' [sessionId]="sessionId" [spaces]="similarWorkPlaces"
                    [mapWidth]="mapWidth" [mapHeight]="mapHeight">
                </app-map-view>
            </div>
        </div>
        <div class="col-md-6" *ngIf="similarWorkPlaces.length == 0">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7011.07577817905!2d77.20454687532707!3d28.52354792984357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1f0dd053fdd%3A0x97d95c1a5860139f!2sSaket%2C%20New%20Delhi%2C%20Delhi%20110017!5e0!3m2!1sen!2sin!4v1622125499177!5m2!1sen!2sin"
                width="600" height="912" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
</div>