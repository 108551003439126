import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-more-amenities',
  templateUrl: './more-amenities.component.html',
  styleUrls: ['./more-amenities.component.scss']
})
export class MoreAmenitiesComponent implements OnInit {
  @Input() amenities: string[] = [];
  @Input() smallSize = false;
  constructor() { }

  ngOnInit(): void {
  }

}
