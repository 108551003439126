import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgOtpInputModule } from 'ng-otp-input';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { DSimilarWorkspaceComponent } from '../pages/space-details/d-similar-workspace/d-similar-workspace.component';
import { DSeatTypeDetailsComponent } from '../pages/space-details/d-seat-type-details/d-seat-type-details.component';
import { DSafetyStandardComponent } from '../pages/space-details/d-safety-standard/d-safety-standard.component';
import { DAboutComponent } from '../pages/space-details/d-about/d-about.component';
import { DAmenityComponent } from '../pages/space-details/d-amenity/d-amenity.component';
import { DNearbyComponent } from '../pages/space-details/d-nearby/d-nearby.component';
import { DSpaceImagesComponent } from '../pages/space-details/d-space-images/d-space-images.component';
import { DSearchSectionComponent } from '../pages/space-details/d-search-section/d-search-section.component';
import { DomChangeDirective } from '../shared/directives/dom-change-directive';
import { VerticalFiltersComponent } from './layout/vertical-filters/vertical-filters.component';
import { AmenityComponent } from './filters/amenity/amenity.component';
import { DeskPreferenceComponent } from './filters/desk-preference/desk-preference.component';
import { LocationPreferenceComponent } from './filters/location-preference/location-preference.component';
import { PopularFiltersComponent } from './filters/popular-filters/popular-filters.component';
import { PopularLocationsComponent } from './filters/popular-locations/popular-locations.component';
import { YourBudgetComponent } from './filters/your-budget/your-budget.component';
import { VerticalBudgetLayoutComponent } from './filters/your-budget/vertical-budget-layout/vertical-budget-layout.component';
import { PricesComponent } from './filters/your-budget/prices/prices.component';
import { LookingForSpaceComponent } from './looking-for-space/looking-for-space.component';
import { PopularWorkspacesGalleryComponent } from './popular-workspaces-gallery/popular-workspaces-gallery.component';
import { CardWithImageComponent } from './layout/card-with-image/card-with-image.component';
import { MoreImagesModalComponent } from './more-images-modal/more-images-modal.component';
import { MapViewComponent } from './map-view/map-view.component';
import { VerifyYourNumberComponent } from './verify-your-number/verify-your-number.component';
import { ChangeNumberComponent } from './change-number/change-number.component';
import { FooterComponent } from './layout/footer/footer.component';
import { TestimonialMobileComponent } from './layout/testimonial-mobile/testimonial-mobile.component';
import { MasonryComponent } from './masonry/masonry.component';
import { MediaComponent } from './media/media.component';
import { StyleWorkCitiesComponent } from './style-work-cities/style-work-cities.component';
import { FindAWorkplaceComponent } from './find-a-workplace/find-a-workplace.component';
import { RequestAQuoteComponent } from './request-a-quote/request-a-quote.component';
import { HorizontalBudgetLayoutComponent } from './filters/your-budget/horizontal-budget-layout/horizontal-budget-layout.component';
import { HorizontalFiltersComponent } from './layout/horizontal-filters/horizontal-filters.component';
import { BookAFreeTourComponent } from './book-a-free-tour/book-a-free-tour.component';
import { CommonComponentsModule } from './common/common-components.module';
import { MoreAmenitiesComponent } from './more-amenities/more-amenities.component';
import { LocationModalComponent } from './location-modal/location-modal.component';

@NgModule({
  declarations: [
    VerticalFiltersComponent,
    AmenityComponent,
    DeskPreferenceComponent,
    LocationPreferenceComponent,
    PopularFiltersComponent,
    PopularLocationsComponent,
    YourBudgetComponent,
    VerticalBudgetLayoutComponent,
    PricesComponent,
    LookingForSpaceComponent,
    PopularWorkspacesGalleryComponent,
    CardWithImageComponent,
    MoreImagesModalComponent,
    DomChangeDirective,
    DSpaceImagesComponent,
    DAboutComponent,
    DAmenityComponent,
    DNearbyComponent,
    DSafetyStandardComponent,
    DSeatTypeDetailsComponent,
    DSimilarWorkspaceComponent,
    MapViewComponent,
    VerifyYourNumberComponent,
    ChangeNumberComponent,
    FooterComponent,
    TestimonialMobileComponent,
    MasonryComponent,
    MediaComponent,
    StyleWorkCitiesComponent,
    FindAWorkplaceComponent,
    RequestAQuoteComponent,
    HorizontalFiltersComponent,
    HorizontalBudgetLayoutComponent,
    BookAFreeTourComponent,
    DSearchSectionComponent,
    MoreAmenitiesComponent,
    LocationModalComponent,
  ],
  imports: [
    CommonModule,
    CommonComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule ,
    CarouselModule,
    RouterModule,
    NgOtpInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyARnAr_HKQe-mSVVJr7ZTlx-QilmPaGw_o',
      libraries: ['places']
    }),
    NgApexchartsModule,
    GooglePlaceModule,
  ],
  exports:[
    VerticalFiltersComponent,
    AmenityComponent,
    DeskPreferenceComponent,
    LocationPreferenceComponent,
    PopularFiltersComponent,
    PopularLocationsComponent,
    YourBudgetComponent,
    VerticalBudgetLayoutComponent,
    PricesComponent,
    LookingForSpaceComponent,
    PopularWorkspacesGalleryComponent,
    CardWithImageComponent,
    MoreImagesModalComponent,
    DSpaceImagesComponent,
    DAboutComponent,
    DAmenityComponent,
    DNearbyComponent,
    DSafetyStandardComponent,
    DSeatTypeDetailsComponent,
    DSimilarWorkspaceComponent,
    MapViewComponent,
    VerifyYourNumberComponent,
    ChangeNumberComponent,
    FooterComponent,
    TestimonialMobileComponent,
    MasonryComponent,
    MediaComponent,
    StyleWorkCitiesComponent,
    FindAWorkplaceComponent,
    RequestAQuoteComponent,
    HorizontalFiltersComponent,
    HorizontalBudgetLayoutComponent,
    BookAFreeTourComponent,
    DSearchSectionComponent,
    MoreAmenitiesComponent
  ]
})
export class SharedComponentsModule {}
