<div class="form-container py-4 px-md-3 px-2 ">
    <h2 class="heading fs-large mb-0">Find a workplace that fits your style</h2>

    <span class="sub-heading">
        Explore the perfect workplace personalized to your needs
        accross our {{styleworkstats.spacesNumber}}+ {{styleworkstats.spaces}} by filling up some quick details.
    </span>
    <form [formGroup]="crmDataForm">
        
        <div class="row mt-2 mt-lg-3">
            <div class="col-6">
                <div class="mb-2">
                    <span class="input-label">Your First Name</span>
                </div>
                <div class="input-container p-2 pt-0">
                    <input class="input ms-0" type="text" placeholder="Enter your first name" formControlName="firstName"
                    [ngClass]="{ 'is-invalid': submitted && formControls?.firstName?.errors }"  pattern="[A-Za-z0-9]+" />
                </div>
                
                <div *ngIf="submitted && formControls?.firstName?.errors">
                    <div *ngIf="formControls?.firstName?.errors?.required" class="d-flex mt-2">
                        <img src="assets/icons/error.svg" alt="error message icon">
                       <p class="error-text m-0 ms-2">First name is required</p>
                    </div>
                </div>
                <div *ngIf="formControls.firstName?.errors?.pattern" class="d-flex mt-2">
                    <img src="assets/icons/error.svg" alt="error message icon">
                    <p class="error-text m-0 ms-2"> Name should be alphabets</p>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-2">
                    <span class="input-label">Your Last Name</span>
                </div>
                <div class="input-container p-2 pt-0">
                    <input class="input ms-0" type="text" placeholder="Enter your last name" formControlName="lastName"
                    [ngClass]="{ 'is-invalid': submitted && formControls?.lastName?.errors }" />
                </div>
                <div *ngIf="submitted && formControls?.lastName?.errors">
                    <div *ngIf="formControls?.lastName?.errors?.required" class="d-flex mt-2">
                        <img src="assets/icons/error.svg" alt="error message icon">
                       <p class="error-text m-0 ms-2">Last name is required</p>
                    </div>
                </div>
                <div *ngIf="formControls.lastName?.errors?.pattern" class="d-flex mt-2">
                    <img src="assets/icons/error.svg" alt="error message icon">
                    <p class="error-text m-0 ms-2"> Name should be alphabets</p>
                </div>
            </div>
        </div>
        <div class="row mt-2 mt-lg-3">
            <div class="col-6">
                <div class="mb-2">
                    <span class="input-label">Your Email</span>
                </div>
                <div class="input-container p-2 pt-0">
                    <input class="input  ms-0" type="text" placeholder="xyz@email.com" formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && formControls?.email?.errors }" />
                </div>
                <div *ngIf="submitted && formControls?.email?.errors">
                    <div *ngIf="formControls?.email?.errors?.required" class="d-flex mt-2">
                        <img src="assets/icons/error.svg" alt="error message icon">
                       <p class="error-text m-0 ms-2">Email is required</p>
                    </div>
                </div>
                <div *ngIf="formControls?.email?.errors?.email" class="d-flex mt-2">
                    <img src="assets/icons/error.svg" alt="error message icon">
                    <p class="error-text m-0 ms-2"> Email must be a valid</p>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-2">
                    <span class="input-label">Number of seats </span>
                </div>
                <div class="input-container p-2 pt-0">
                    <input class="input ms-0 ms-lg-3mt-1" maxlength="4" type="text" placeholder="Enter number of seats" formControlName="seats"
                    [ngClass]="{ 'is-invalid': submitted && formControls?.seats?.errors }"/>
                </div>
               
                    <div *ngIf="submitted && formControls?.seats?.errors">
                        <div *ngIf="formControls?.seats?.errors?.required" class="d-flex mt-2">
                            <img src="assets/icons/error.svg" alt="error message icon">
                           <p class="error-text m-0 ms-2">Seats are required</p>
                        </div>
                    </div>
                    <div *ngIf="formControls.seats?.errors?.pattern" class="d-flex mt-2">
                        <img src="assets/icons/error.svg" alt="error message icon">
                        <p class="error-text m-0 ms-2"> Seats should be Numeric</p>
                    </div>
            </div>
        </div>
        <div class="row mt-2 mt-lg-3">
            <div class="form-group col-12">
                <label class="input-label">Seat Category</label>
                <div class="dropdown mt-2">
                    <div class="tab d-flex align-items-center justify-content-between"
                        id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        <p class="m-0 ms-2 fs-small">
                            {{selectedCategory? selectedCategory:'Select category'}}
                        </p>
                        <img class="me-3" src="assets/icons/Upwards-arrow.svg" alt="" />
                    </div>

                    <div class="dropdown-menu w-100 px-2" aria-labelledby="state">
                        <div class="scroller mt-2 pe-2 mx-1">
                            <ng-container
                                *ngFor="let category of seatCategoryData; let categoryIndex = index">
                                <div (click)="categorySelected(category)"
                                    class="p-2 my-2 tab fs-regular city'">
                                    {{category?.name}}
                                </div>
                                <hr class="m-0" *ngIf="categoryIndex != seatCategoryData.length-1">
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="submitted && crmDataForm.get('seatCategory')?.errors?.required"
                        class="mt-2 d-flex">
                        <img class="" src="assets/icons/eroor.svg" alt="error" />
                        <p class="m-0 ms-1 error-text">
                            Category is required
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2 mt-lg-3">
            <div class="col-12">
                <div class="mb-2">
                    <span class="input-label">Your Contact Number</span>
                </div>
                <div class="d-flex">
                    <div class="select-container p-2 px-3">
                        <select class="input select-text">
                            <option selected>+91</option>
                            <option value="1">+11</option>
                            <option value="2">+12</option>
                            <option value="3">11</option>
                          </select>
                    </div>
                    <div class="input-container p-2 pt-0 ms-2 w-100">
                        <input class="input  ms-0 " type="text" maxlength="10" placeholder="Your Phone Number" 
                        [ngClass]="{ 'is-invalid': submitted && formControls?.contactNo?.errors }"  formControlName="contactNo"/>
                    </div>
                </div>
                    <div *ngIf="submitted && formControls?.contactNo?.errors?.required">
                        <div *ngIf="formControls?.contactNo?.errors?.required" class="d-flex mt-2">
                            <img src="assets/icons/error.svg" alt="error message icon">
                           <p class="error-text m-0 ms-2">Mobile Number is required</p>
                        </div>
                    </div>
                    <div *ngIf="formControls?.contactNo?.errors?.maxlength || formControls?.contactNo?.errors?.minlength " class="mt-2 d-flex">
                        <img class="" src="assets/icons/error.svg" alt="error" />
                        <div class="m-0 ms-1 error-text"> 
                            Mobile Number should be 10 Digits long.
                        </div>
                    </div>
                    <div *ngIf="formControls?.contactNo?.errors?.pattern" class="d-flex mt-2">
                        <img src="assets/icons/error.svg" alt="error message icon">
                        <p class="error-text m-0 ms-2"> Mobile Number should be Numeric</p>
                    </div>
            </div>
        </div>
        <div class="mt-2 mt-lg-3">
            <div class="mb-2">
                <span class="input-label">Comapny Size</span>
            </div>
            <div class="d-flex justify-content-between com_size">
                <div>
                    <input class="form-check-input" type="radio" name="exampleRadios"
                        id="exampleRadios1" value="individual" (click)="setCompanySize('Individual')" checked>
                    <label class="form-check-label ms-1" for="exampleRadios1">
                        Individual
                    </label>
                </div>
                <div>
                    <input class="form-check-input" type="radio" name="exampleRadios"
                        id="exampleRadios2" value="2-10" (click)="setCompanySize('2-10')">
                    <label class="form-check-label ms-1" for="exampleRadios1">
                        2-10
                    </label>
                </div>
                <div>
                    <input class="form-check-input" type="radio" name="exampleRadios"
                        id="exampleRadios3" value="11-50" (click)="setCompanySize('11-50')">
                    <label class="form-check-label ms-1" for="exampleRadios1">
                        11-50
                    </label>
                </div>
                <div>
                    <input class="form-check-input" type="radio" name="exampleRadios"
                        id="exampleRadios4" value="51-200" (click)="setCompanySize('51-200')">
                    <label class="form-check-label ms-1" for="exampleRadios1">
                        51-200
                    </label>
                </div>
                <div>
                    <input class="form-check-input" type="radio" name="exampleRadios"
                        id="exampleRadios5" value="200+" (click)="setCompanySize('200+')">
                    <label class="form-check-label ms-1" for="exampleRadios1">
                        200+
                    </label>
                </div>
            </div>
        </div>
        <div class="form-group  mt-2 mt-lg-3">
            <div class="mb-2"> 
                <span class="input-label">Location(s)</span>
            </div>
            <div class="input-container p-2 pt-0">
                <input type="text" class="input ms-0" ngx-google-places-autocomplete
                [options]='options' #placesRef="ngx-places"
                value=""
                #address
                (onAddressChange)="handleAddressChange($event, address)"
                 [ngClass]="{ 'is-invalid': submitted && selectedLocations.length === 0}"
                placeholder="Enter your location" />
            </div>
            <div *ngIf="submitted && selectedLocations.length === 0" class="error-text mt-1 d-flex">
                <img class="" src="assets/icons/error.svg"
                    alt="error" />
                <div  class="m-0 ms-1">
                    Location is required
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap mt-2 mt-lg-3" *ngIf="addressTokens.length > 0">
           <div class="me-2 px-2 py-1 token d-flex align-items-center" *ngFor="let token of addressTokens; let tokenIndex = index">
             <p class="m-0 fs-small">{{token}}</p>
             <img src="assets/icons/cross-dark.svg" 
             (click)="deleteAddressToken(tokenIndex)"
             width="12" height="12" class="tab ms-3" alt="cross-icon">
           </div>
        </div>
        <div class="mt-2 mt-lg-3">
            <div class="mb-2">
                <span class="input-label">Requirements (Optional)</span>
            </div>
            <div class="input-container p-2 h-25">

                <textarea class="input w-100" placeholder="Enter your requirements here"
                  formControlName="requirements" placeholder="Enter your requirements"
                    rows="3"></textarea>
            </div>
        </div>
        <div class="mt-4">
            <button 
            class="btn btn-primary-base p-3 btn-text text-center w-100"
            [disabled]="crmDataForm.invalid || selectedLocations.length == 0 " (click)="onSubmitRequirements()" 
            *ngIf="!isLoading">
                Request a Quote
            </button>
            <app-bootstrap-loader *ngIf="isLoading" style="position: relative;left: 50%"></app-bootstrap-loader>                             
        </div>
        <input type='hidden' id="zc_gad" name="zc_gad" value=""/>
    </form>


</div>