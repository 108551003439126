<div class="container-fluid media pt-lg-5">
  <div class="col-12">
    <h2 class="text-center mb-0 mt-lg-0 mt-5 fs-biger">
      The media’s been talking about us too!
  </h2>
  </div>
    <owl-carousel-o [options]="customOptions" *ngIf="!loading">
        <ng-template carouselSlide *ngFor="let logo of newsLogo">
          <div class="slide">
             <ng-container *ngIf="logo.redirectionLink !== ''">
              <a [href]="logo.redirectionLink" class="tab" target="_blank" rel="open new tab for news "> <img class="img-fluid tab" 
                [src]="logo.image"
                (error)="$event.target.src = 'assets/images/news/mail-today.png'"
                 alt="icon"></a>
            </ng-container>
            <ng-container *ngIf="logo.redirectionLink == ''">
              <img class="img-fluid" 
                [src]="logo.image"
                (error)="$event.target.src = 'assets/images/news/mail-today.png'"
                 alt="icon">
            </ng-container>
          </div>
        </ng-template>
    </owl-carousel-o>
    <div class="d-flex justify-content-center align-items-center py-5" *ngIf="loading">
      <app-bootstrap-loader></app-bootstrap-loader>
    </div>
</div>

