import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MoreImagesModalComponent } from 'src/app/components/more-images-modal/more-images-modal.component';
import { Address, MoreImages } from 'src/app/shared/interface/more-images';

@Component({
  selector: 'app-d-space-images',
  templateUrl: './d-space-images.component.html',
  styleUrls: ['./d-space-images.component.scss']
})
export class DSpaceImagesComponent implements OnInit, OnChanges {
  @Input() images: string[] = [];
  @Input() spaceName = '';
  @Input() address: Address ;
  @Input() numberOfPeople = 0;
  @Input() CategoryBadge = ''
  spaceData: MoreImages;
  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    //do
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.spaceData = {
      spaceName: this.spaceName,
      address: this.address,
      images: changes.images.currentValue ?? []
    };
  }

  moreImages(moreImage: MoreImages): void {
    this.dialog.open<MoreImagesModalComponent, MoreImages>(MoreImagesModalComponent,{
      disableClose: true,
      data: moreImage
    });
  }

}
