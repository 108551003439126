<div class="dialog-container p-md-4 p-2  pe-md-5 m-2 mt-3 me-md-5 text-dark">
   <p class="mb-0 heading-text">Do you wish to change your current number?</p>
   <p class="mt-2 sub-text">Your current contact number is<b><span class="ms-2">{{userData?.mobile}}</span></b></p>
   <div class="mt-3 ">
    <label class="form-label input-label">Enter New Contact Number</label>
    <div class="d-flex w-100">
        <div class="select-container">
            <select class="input select-text" [formControl]="countryCode">
                <option value="91" selected>+91</option>
              </select>
        </div>
        <div class="input-container ms-2 me-3">
            <input class="input w-100" type="text" maxlength="10" [formControl]="mobileNo"  placeholder="Mobile Number">
        </div>
    </div>
    <div *ngIf="mobileNo?.errors" class="text-danger">
        <div *ngIf="mobileNo?.errors?.pattern">
          Please enter valid mobile no
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="mt-5 col-11" *ngIf="loadSubmitBtn === false">
                <div 
                    (click)="submitChangeNumber()"
                    [ngClass]="!mobileNo.invalid ? 
                    'btn btn-primary-base btn-text text-center w-100':
                    'btn disabled-btn btn-text text-center w-100'"
                >
                Resend OTP
                </div>                                    
            </div>
            <div class="mt-5 col-11" *ngIf="loadSubmitBtn === true">
                <app-bootstrap-loader></app-bootstrap-loader>
            </div>
        </div>
        <div class="col-6">
            <div class="back-text mt-5" (click)="goBack(null)">
                <span>Go Back</span>
            </div>
        </div>
    </div>
</div>
</div>
