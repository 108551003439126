import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { retry, catchError, map } from "rxjs/operators";
import { EnvConfigurationService } from "src/app/Helper/environment-configuration.service";
import { QueryParams } from "src/app/shared/services/common-services/rest-api.service";

export interface DefaultPrice {
  minimum: number;
  maximum: number;
  count?: number;
}

export interface DesksPreference {
  categoryName: string;
  isMultiSeater: boolean;
  icon: string;
  id: string;
  count: number;
}

export interface NameCountFilter {
  name: string;
  count: number;
  id: string;
  icon: string;
}

export interface IdName {
  name: string;
  id: string;
  count?: number;
  _id?: string;
  active?: boolean;
  slug?: string;
  cityInfo?: {
    name: string;
    slug: string;
    id: string;
  };
}

export interface FiltersResponse {
  amenities: NameCountFilter[];
  landmarks: NameCountFilter[];
  desks: DesksPreference[];
  popularLocations: IdName[];
  budget: DefaultPrice[];
}

export interface BudgetFilter {
  seatType?: string[];
  priceRanges?: {
    minimum: number;
    maximum: number;
  }[];
}

export interface FilterParameterForPrice {
  minimum: number;
  maximum: number;
}

export interface FiltersQuery {
  budget?: BudgetFilter | undefined;
  nearBy?: string[] | undefined;
  popularAreas?: string[] | undefined;
  amenitiesAvailable?: string[] | undefined;
  seatCategories?: string[] | undefined;
}

@Injectable({
  providedIn: "root",
})
export class FilterService {
  config = this.envConfigService.configSubject$;

  isLoadingFilters$: Observable<boolean>;

  filters: FiltersQuery = {
    budget: {
      seatType: [],
      priceRanges: [],
    },
    nearBy: [],
    popularAreas: [],
    amenitiesAvailable: [],
    seatCategories: [],
  };

  filtersAvailable$: Observable<boolean>;

  defaultPrices$: Observable<DefaultPrice[]>;
  popularLocations$: Observable<IdName[]>;
  amenityData$: Observable<NameCountFilter[]>;
  deskPreferenceData$: Observable<DesksPreference[]>;
  landmarks$: Observable<NameCountFilter[]>;

  private priceDataSubject = new BehaviorSubject<DefaultPrice[]>([]);
  private popularLocationSubject = new BehaviorSubject<IdName[]>([]);
  private amenitySubject = new BehaviorSubject<NameCountFilter[]>([]);
  private deskPreferencSubject = new BehaviorSubject<DesksPreference[]>([]);
  private landmarkSubject = new BehaviorSubject<NameCountFilter[]>([]);
  private loadingFiltersSubject = new BehaviorSubject<boolean>(false);
  private availableFiltersSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private envConfigService: EnvConfigurationService,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.defaultPrices$ = this.priceDataSubject.asObservable();
    this.popularLocations$ = this.popularLocationSubject.asObservable();
    this.amenityData$ = this.amenitySubject.asObservable();
    this.deskPreferenceData$ = this.deskPreferencSubject.asObservable();
    this.landmarks$ = this.landmarkSubject.asObservable();
    this.filtersAvailable$ = this.availableFiltersSubject.asObservable();
    this.isLoadingFilters$ = this.loadingFiltersSubject.asObservable();
  }

  getSpaceFilters(queryData: QueryParams): Observable<FiltersResponse> {
    this.loadingFiltersSubject.next(true);
    return this.http
      .get<FiltersResponse>(
        // eslint-disable-next-line max-len
        `${this.config.value.apiUrl}spaces/filters?lat=${queryData.lat}&long=${queryData.long}&page=${queryData.page}&count=${queryData.count}`
      )
      .pipe(
        retry(1),
        map((data: FiltersResponse) => {
          this.mapSpaceResponseData(data);
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getSpaceFiltersByCityId(cityId: string): Observable<FiltersResponse> {
    this.loadingFiltersSubject.next(true);
    if (cityId === null) {
      return;
    }
    return this.http
      .get<FiltersResponse>(
        `${this.config.value.apiUrl}cities/${cityId}/spaces/filters`
      )
      .pipe(
        retry(1),
        map((data: FiltersResponse) => {
          this.mapSpaceResponseData(data);
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getSpaceFiltersByPopularLocationsIds(
    popularLocationId: string
  ): Observable<FiltersResponse> {
    this.loadingFiltersSubject.next(true);
    return this.http
      .get<FiltersResponse>(
        `${this.config.value.apiUrl}popular-locations/${popularLocationId}/spaces/filters`
      )
      .pipe(
        retry(1),
        map((data: FiltersResponse) => {
          this.mapSpaceResponseData(data);
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getSpaceFiltersByCitySlug(citySlug: QueryParams): Observable<FiltersResponse> {
    this.loadingFiltersSubject.next(true);
    if (!citySlug || !citySlug.citySlug) {
      return;
    }
    return this.http
      .get<FiltersResponse>(
        `${this.config.value.apiUrl}v2/cities/slug/${citySlug.citySlug}/spaces/filters?areaSlug=${citySlug.areaSlug}`
      )
      .pipe(
        retry(1),
        map((data: FiltersResponse) => {
          this.mapSpaceResponseData(data);
          return data;
        }),
        catchError(this.handleError)
      );
  }

  mapSpaceResponseData(data: FiltersResponse): void {
    this.loadingFiltersSubject.next(false);
    this.amenitySubject.next(data.amenities);
    const pricesData: DefaultPrice[] = data.budget.map((item) => {
      const container = {
        minimum: +item.minimum / 100,
        maximum: +item.maximum / 100,
        count: +item.count || 0,
      };
      return container;
    });

    const popularLocations: IdName[] = data.popularLocations.map((item) => {
      const container = {
        // eslint-disable-next-line no-underscore-dangle
        id: item.id || item._id,
        name: item.name,
        slug: item.slug,
        count: item.count || 0,
        active: false,
        cityInfo: item.cityInfo
      };
      return container;
    });
    this.priceDataSubject.next(pricesData);
    this.deskPreferencSubject.next(data.desks);
    this.popularLocationSubject.next(popularLocations);
    this.landmarkSubject.next(data.landmarks);
  }


  handleError(error): ReturnType<typeof throwError> {
    let errorMessage = "";
    this.loadingFiltersSubject?.next(false);
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //  window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
