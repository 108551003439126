<div class="cities-container">
    <div class="left-gradient"></div>
    <div class="p-0">
        <div class="tab float-end ms-2" (click)="closeDialog()">
            <img src="assets/icons/Cancel.svg" width="16" height="16" alt="More cities dialog cancle btn">
        </div>
        <h2 class="heading me-md-5">Select your city from the list below</h2>
    </div>
    <div class="scroller-section mt-3" *ngIf="!loading">
        <div class="col-lg-11 col-11 ">
            <div class="search-container d-flex px-3 py-2 mt-lg-3 mt-2">

                <img src="assets/icons/Search.svg" alt="search" class="search_icon_cities">
                <input class="input ms-3 w-100" type="text" [(ngModel)]="searchCity" placeholder="Search for a city">
            </div>
            <div class="d-flex  mt-4 mb-3" *ngIf="selectedCityArray.length > 0">
                <p class="fs-regular fw-400 text-tertiary-grey m-0 mt-2">Selected </p>
                <div class="d-flex flex-wrap ms-4">
                    <div *ngFor="let city of selectedCityArray" class="highlight ms-2 mb-2">
                        <p class="m-0 fs-regular fw-500 text-primary-base py-2 px-2 mx-1">{{city}}</p>
                    </div>

                </div>


            </div>
        </div>
        <ul class="tier-list  pe-md-5 pe-4 mt-3 ps-0" *ngIf="(citiesList | filter: searchCity).length > 0; else noResults">
            <li *ngFor="let city of citiesList | filter: searchCity; let cityIndex = index" style="cursor:pointer">
                <p (click)="selectedCityName(city.name)" class='city-name'>{{city?.name}}</p>
                <hr>
            </li>
        </ul>

        <ng-template #noResults>""
            <p>No results found for : {{searchCity}}.</p>
        </ng-template>
    </div>
</div>
<div class="loading-section d-flex justify-content-center align-items-center" *ngIf="loading">
    <app-bootstrap-loader></app-bootstrap-loader>
</div>
<div class="right-gradient"></div>