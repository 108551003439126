/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, OnInit } from '@angular/core';
import { styleworkStats } from 'src/app/Helper/enums';


@Component({
  selector: 'app-style-work-stats',
  templateUrl: './style-work-stats.component.html',
  styleUrls: ['./style-work-stats.component.scss']
})
export class StyleWorkStatsComponent implements OnInit {
  styleworkstats = styleworkStats;

  constructor() { }

  ngOnInit(): void {
  }
}
