<div class="pt-md-4 pt-2">
    <h2 class="fs-largest">
        Amenities
    </h2>
    <div class="row pt-lg-3 pt-1">
        <ng-container *ngFor="let amenity of amenities;let i=index">
            <div class="col-md-6 col-6">
                <div class="d-flex mb-lg-4 mb-2">
                    <div>
                        <img src="{{amenity?.icon}}" alt="{{amenity?.name}} icon" height="auto"
                            width="20px" (error)="onErrorHandling($event)">
                    </div>
                    <div class="ms-3">
                        <p class="description-text mb-0">{{amenity?.name}}</p>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>