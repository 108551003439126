import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PopularLocations } from '../../interface';
import { Cities } from '../../interface/cities';
import { QueryParams } from '../common-services/rest-api.service';
import { StaticPopularCity } from 'src/app/Helper/popular-cities';

@Injectable({
  providedIn: 'root',
})
export class AppRouterService {
  constructor(private router: Router) {}

  routeToSpaceExplorationByPopularLocationId(city: PopularLocations): void {
    void this.router.navigate(['/coworking-spaces']);
  }

  routeToSpaceExplorationByLatLong(data: QueryParams): void {
    void this.router.navigate(['/coworking-spaces']);
  }

  routeToSpaceExplorationUsingCityId(city: Cities|{name: string; id: string}): void {
    void this.router.navigate(['/coworking-spaces']);
  }

  routeToSpaceExplorationUsingCitySlug(city: Cities|{name: string; id: string; slug?: string}): void {
    void this.router.navigate([`/coworking-spaces/${city.slug}`]);
  }
  routeToSpaceExplorationUsingCitySlugWithstaticPopularCity(city: StaticPopularCity): void {
    void this.router.navigate([`/coworking-spaces/${city.slug}`]);
  }
}
