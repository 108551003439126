import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomAddressLocation, CustomLocationService } from 'src/app/shared/services/locations/custom-location.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-looking-for-space',
  templateUrl: './looking-for-space.component.html',
  styleUrls: ['./looking-for-space.component.scss']
})
export class LookingForSpaceComponent implements OnInit {
  private subs = new SubSink();

  constructor(
    private customLocationService: CustomLocationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  routeToExploreSpaces(): void {
    this.customLocationService.getCurrentUserLocation();
    this.subs.add(
      this.customLocationService.locationObj$.subscribe((location: CustomAddressLocation) => {
        if(location !== null) {
          this.router.navigate(['explore-space'],{ queryParams: {
            lat: location.lat,
            long: location.lon,
            count: 10,
            page: 1
          }});
        }
      })
    );
  }

}
