import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DesksPreference, FilterService, NameCountFilter } from '../filters.service';

@Component({
  selector: 'app-desk-preference',
  templateUrl: './desk-preference.component.html',
  styleUrls: ['./desk-preference.component.scss']
})
export class DeskPreferenceComponent implements OnInit {
  @Output() deskPreferenceIdArr = new EventEmitter<string[]>();
  deskPreference$: Observable<DesksPreference[]>;
  readMore = true;
  deskPreferenceIds: string[] = [];
  isLoading$: Observable<boolean>;

  constructor(
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.deskPreference$ = this.filterService.deskPreferenceData$;
    this.isLoading$ = this.filterService.isLoadingFilters$;
  }

  onAddDeskPreference(event: { target: { checked: boolean }},deskFilter: DesksPreference): void {
    if(event.target.checked) {
      this.deskPreferenceIds.push(deskFilter.id);
    } else {
      if(this.deskPreferenceIds.includes(deskFilter.id)) {
        const index = this.deskPreferenceIds.indexOf(deskFilter.id);
        if(index > -1) {
          this.deskPreferenceIds.splice(index, 1);
        }
      }
    }
    this.deskPreferenceIdArr.emit(this.deskPreferenceIds);
  }

}
