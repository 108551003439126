import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterService, NameCountFilter } from '../filters.service';

@Component({
  selector: 'app-amenity',
  templateUrl: './amenity.component.html',
  styleUrls: ['./amenity.component.scss']
})
export class AmenityComponent implements OnInit {
  @Output() amenitiesIdArr = new EventEmitter<string[]>();
  amenity$: Observable<NameCountFilter[]>;
  readMore = true;
  isLoading$: Observable<boolean>;
  amenityIdsArr: string[] = [];

  constructor(
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.amenity$ = this.filterService.amenityData$;
    this.isLoading$ = this.filterService.isLoadingFilters$;
  }

  onAddAmenity(event: { target: { checked: boolean }},amenityFilter: NameCountFilter): void {
    if(event.target.checked) {
      this.amenityIdsArr.push(amenityFilter.id);
    } else {
      if(this.amenityIdsArr.includes(amenityFilter.id)) {
        const index = this.amenityIdsArr.indexOf(amenityFilter.id);
        if(index > -1) {
          this.amenityIdsArr.splice(index, 1);
        }
      }
    }
    this.amenitiesIdArr.emit(this.amenityIdsArr);
  }
}
