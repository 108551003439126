<div class="tab float-end m-3" (click)="closeDialog()">
  <img src="assets/icons/Cancel.svg" width="16" height="16" alt="More cities dialog cancle btn">
</div>
<div class="cities-container container">
    <div class="p-0">
      <h2 class="fs-largest me-md-5 text-md-center ">Choose the city you want to work from</h2>
    </div>
    <div class="scroller-section mt-3" *ngIf="!loading">
      <!-- <h2 class="fs-large text-primary-base fw-600 ">Popular Cities</h2>
      <div class="d-flex flex-wrap mt-md-3 mt-2">
        <div class="slide mb-mb-4 mb-3 tab" (click)="routeToSpacesExplorationByCityId(city)" *ngFor="let city of filterPopularCities(citiesList)">
          <div class="py-1 d-flex align-items-center justify-content-center flex-column">
            <img
              [src]="city?.newBlueIcon" 
              [alt]="city?.name">
          <p class="m-0 fs-small fw-500 mt-1">{{city?.name}}</p>
          </div>
         </div>
      </div> -->
      <h2 class="fs-large text-primary-base fw-600 mt-md-4 mt-2">Tier - 1 Cities({{filterTierOneCities(citiesList).length}})</h2>
      <div class="d-flex flex-wrap mt-md-3 mt-2">
        <div class="city-tab mb-mb-4 mb-3 " (click)="routeToSpacesExplorationByCityId(city)" *ngFor="let city of filterTierOneCities(citiesList)">
          <p class="m-0 fs-small fw-500 mt-1" [title]="city?.name">{{city?.name.length > 13 ? city?.name.substring(0,13)+'..':city?.name }}</p>
         </div>
      </div>
      <h2 class="fs-large text-primary-base fw-600 mt-md-4 mt-2">Tier - 2 Cities({{filterTierTwoCities(citiesList).length}})</h2>
      <div class="d-flex flex-wrap mt-md-3 mt-2">
        <div class="city-tab mb-mb-4 mb-3 " (click)="routeToSpacesExplorationByCityId(city)" *ngFor="let city of filterTierTwoCities(citiesList)">
          <p class="m-0 fs-small fw-500 mt-1" [title]="city?.name">{{city?.name.length > 13 ? city?.name.substring(0,13)+'..':city?.name }}</p>
         </div>
      </div>
      <h2 class="fs-large text-primary-base fw-600 mt-md-4 mt-2">Tier - 3 Cities({{filterTierThreeCities(citiesList).length}})</h2>
      <div class="d-flex flex-wrap mt-md-3 mt-2">
        <div class="city-tab mb-mb-4 mb-3 " (click)="routeToSpacesExplorationByCityId(city)" *ngFor="let city of filterTierThreeCities(citiesList)">
          <p class="m-0 fs-small fw-500 mt-1" [title]="city?.name">{{city?.name.length > 13 ? city?.name.substring(0,13)+'..':city?.name }}</p>
         </div>
      </div>
    </div>
    <div class="loading-section d-flex justify-content-center align-items-center" *ngIf="loading">
       <app-bootstrap-loader></app-bootstrap-loader>
    </div>
</div>
