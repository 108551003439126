import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-testimonial-mobile',
  templateUrl: './testimonial-mobile.component.html',
  styleUrls: ['./testimonial-mobile.component.scss']
})
export class TestimonialMobileComponent implements OnInit {
  @Input() testimo = [];
  constructor() {}
  ngOnInit(): void {
  }

}
