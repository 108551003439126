import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterService, NameCountFilter } from '../filters.service';

@Component({
  selector: 'app-location-preference',
  templateUrl: './location-preference.component.html',
  styleUrls: ['./location-preference.component.scss']
})
export class LocationPreferenceComponent implements OnInit {
  @Output() nearByIds = new EventEmitter<string[]>();
  readMore2 = false;
  locationPreferencesLandmark$: Observable<NameCountFilter[]>;
  locationPreferenceIds: string[] = [];
  isLoading$: Observable<boolean>;

  constructor(
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.isLoading$ = this.filterService.isLoadingFilters$;
    this.locationPreferencesLandmark$ = this.filterService.landmarks$;
  }

  onAddLocationPreference(event: { target: { checked: boolean }},locationPref: NameCountFilter): void {
    if(event.target.checked) {
      this.locationPreferenceIds.push(locationPref.id);
    } else {
      if(this.locationPreferenceIds.includes(locationPref.id)) {
        const index = this.locationPreferenceIds.indexOf(locationPref.id);
        if(index > -1) {
          this.locationPreferenceIds.splice(index, 1);
        }
      }
    }
    this.nearByIds.emit(this.locationPreferenceIds);
  }

}
