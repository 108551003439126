<app-your-budget 
(pricesObj)="addPricesToFilter($event)"
(seatTypeId)="addSeatTypes($event)"></app-your-budget>
<div class="row">
    <div class="col-md-6" *ngFor="let prices of (pricesData$ | async);let i=index;">
        <ng-container *ngIf="i<4">
            <app-prices [minPrice]="prices.minimum" 
            [maxPrice]="prices.maximum" [count]="prices.count"
            (pricesObj)="addPricesToFilter($event)"></app-prices>
        </ng-container>
    </div>
    <div class="collapse" id="showmore1">
        <div class="col-md-6"  *ngFor="let prices of (pricesData$ | async);let i=index;">
            <ng-container *ngIf="i>3">
                <app-prices [minPrice]="prices.minimum" 
                [maxPrice]="prices.maximum" [count]="prices.count"
                (pricesObj)="addPricesToFilter($event)"></app-prices>
            </ng-container>
        </div>
    </div>
</div>
<div class="d-flex justify-content-between flex-row mt-2">
    <span class='information-text'>All prices are per month basis</span>
    <a (click)="readMore = !readMore" class="link-text" data-bs-toggle="collapse"
        href="#showmore1" role="button" aria-expanded="false"
        aria-controls="showmore1">
        {{readMore ? "Show More" : "Show Less"}}
    </a>
</div>