<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container main-nav">
      <div class="navbar-brand tab" routerLink="/"><img  width="170" height="42.23" src="assets/logo.svg" alt="Stylework"></div>
      <button #navIcon class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto">
              <li class="nav-item dropdown" (click)="exploreSpace()">
                  <a [ngClass]="exploreSpaceMenuShow ?'nav-link dropdown-toggle p-md-3 rounded-top fs-regular fw-500':'nav-link dropdown-toggle p-md-3 fs-regular fw-500'"  id="navbarDropdown" role="button"  data-bs-toggle="dropdown" aria-expanded="false">
                      Explore Spaces
                  </a>
                  <div class="dropdown-menu space-menu p-3 border-0 " aria-labelledby="navbarDropdown">
                    <p class="heading">Choose the city you want to work from</p>
                    <div class="row">
                      <div class="col-lg-3 col-4 mb-4 tab" data-bs-dismiss="modal" 
                        (click)="routeSpacesExplorationByToPopularLocations(city)" *ngFor="let city of exploreCitiesData">
                        <div class="slide w-100">
                          <div class="py-1 d-flex align-items-center justify-content-center flex-column">
                            <img
                              [src]="city?.newBlueIcon" 
                              [alt]="city?.name">
                          <p class="m-0 fs-small fw-500 mt-1">{{city?.name}}</p>
                          </div>
                         </div>
                       </div>
                      <div class="col-lg-3  mb-4">
                        <div class="c-img tab">
                          <img class="" src="assets/icons/Add-City.svg" width="88" height="96" alt="add city icon" (click)=" openDialog()">
                        </div>
                      </div>  
                    </div>
                    <span class="heading">Your city not in the list?<a  routerLink="/contact-us" class="link-text"> Reach to us for booking</a></span>
                  </div>
               
              </li>
            
             <li class="nav-item dropdown">
              <a class="nav-link p-md-3 dropdown-toggle fs-regular fw-500" id="our-partners" role="button"  data-bs-toggle="dropdown" aria-expanded="false" (click)="closeExploreMenu('2','Our Partners')">Our Partners</a>
              <div class="dropdown-menu services-menu px-3 pb-3 border-0" aria-labelledby="our-partners">
                <div class="tab pb-3 border-bottom-link" (click)="closeCollapse('2','Our Partners','Integrated Partner')" routerLink="/our-partners">
                  <span class="fs-small text-light-dark fw-500">Integrated Partner</span>
                </div>
                <div class="tab mt-3" (click)="channelPartner()">
                  <span class="fs-small text-light-dark fw-500">Channel Partner</span>
                </div>
               
              </div>
          </li>
              <li class="nav-item dropdown">
                  <a class="nav-link p-md-3 dropdown-toggle fs-regular fw-500" id="cowoking-services" role="button"  data-bs-toggle="dropdown" aria-expanded="false" (click)="closeExploreMenu('3','Co-Working Services')">Co-Working Services</a>
                  <div class="dropdown-menu services-menu px-3 pb-3 border-0" aria-labelledby="cowoking-services">
                    <div class="tab pb-3 border-bottom-link" (click)="closeCollapse('3','Co-Working Services','Managed Spaces')" routerLink="/managed-space">
                      <span class="fs-small text-light-dark fw-500">Managed Spaces</span>
                    </div>
                    <div class="tab pb-3 border-bottom-link mt-3" (click)="closeCollapse('3','Co-Working Services','List your Space')" routerLink="/list-your-space">
                      <span class="fs-small text-light-dark fw-500">List your Space</span>
                    </div>
                    <div class="tab pb-3 border-bottom-link mt-3" (click)="closeCollapse('3','Co-Working Services','Membership Plans')" routerLink="/membership">
                      <span class="fs-small text-light-dark fw-500">Membership Plans</span>
                    </div>
                    <div class="tab pb-3 border-bottom-link mt-3" (click)="closeCollapse('3','Co-Working Services','Enterprise')" routerLink="/enterprise">
                      <span class="fs-small text-light-dark fw-500">Enterprise</span>
                    </div>
                    <div class="tab  mt-3" (click)="closeCollapse('3','Co-Working Services','Vendor Onboarding')" routerLink="/vendor">
                      <span class="fs-small text-light-dark fw-500">Vendor Onboarding</span>
                    </div>
                  </div>
              </li>
              <li class="nav-item dropdown" >
                  <a class="nav-link p-md-3 dropdown-toggle fs-regular fw-500" id="cowoking-services" role="button"  data-bs-toggle="dropdown" aria-expanded="false" (click)="closeExploreMenu('4','Resources')">Resources</a>
                  <div class="dropdown-menu resourses px-3 pb-3 border-0" aria-labelledby="cowoking-services">
                    <div class="tab pb-3 border-bottom-link" (click)="closeCollapse('4','Resources','About Us')" routerLink="/about-us">
                      <span class="fs-small text-light-dark fw-500">About Us</span>
                    </div>
                    <div class="tab pb-3 border-bottom-link mt-3" (click)="closeCollapse('4','Resources','Contact Us')" routerLink="/contact-us">
                      <span class="fs-small text-light-dark fw-500">Contact Us</span>
                    </div>
                    <div class="tab pb-3 border-bottom-link mt-3" (click)="blogAndResources()">
                      <span class="fs-small text-light-dark fw-500">Blog & Resources</span>
                    </div>
                    <div class="tab  mt-3" (click)="closeCollapse('4','Resources','Career')" routerLink="/career">
                      <span class="fs-small text-light-dark fw-500">Career</span>
                    </div>
                    
                  </div>
              </li>
              <li class="nav-item mt-2">
                  <div class="nav-link btn btn-primary-base px-xl-4 px-3 btn-workspace" (click)="requestAQuoteDialog()">
                        <span class="fs-regular text-light fw-500">Need a workspace?</span>
                  </div>
              </li>

          </ul>   
      </div>
  </div>
 
</nav>