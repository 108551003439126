import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-unconventional-benefits',
  templateUrl: './unconventional-benefits.component.html',
  styleUrls: ['./unconventional-benefits.component.scss'],
})
export class UnconventionalBenefitsComponent implements OnInit, OnDestroy {
  closeIntervalHandlerForClassChange: ReturnType<typeof setInterval>;
  count = 1;
  constructor(
    private elRef: ElementRef,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this. closeIntervalHandlerForClassChange = setInterval(() => {
      this.animationStepper(this.count);
    }, 11000);
  }

  animationStepper(count: number): void {
    const circleRef = this.elRef.nativeElement.querySelectorAll('.circle');
    const contentRef = this.elRef.nativeElement.querySelectorAll('.content');
    for(let i = 0; i < circleRef.length; i++){
      if(i === count){
        circleRef[i].className = 'circle col-2 circle-active tab';
        contentRef[i].className = 'col-10 ps-md-4 content content-active tab';
      }else{
        circleRef[i].className = 'circle col-2 tab';
        contentRef[i].className = 'col-10 ps-md-4 content tab';
      }
    }
    this.count = (count + 1) % 4;
  }
  flexibleMembership(): void {

    this.router.navigate(['/membership']);
  }

  ngOnDestroy(): void {
    if (this.closeIntervalHandlerForClassChange) {
      clearInterval(this.closeIntervalHandlerForClassChange);
    }
  }
}

