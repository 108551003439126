import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MoreImages } from 'src/app/shared/interface/more-images';

@Component({
  selector: 'app-more-images-modal',
  templateUrl: './more-images-modal.component.html',
  styleUrls: ['./more-images-modal.component.scss']
})
export class MoreImagesModalComponent implements OnInit{
  moreImage: MoreImages;
  callingLimit = 0;
  callingCount = 0;
  imageIndex = 0;
  customOptions: OwlOptions = {
    autoplay:true,
    autoplaySpeed:500,
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['', ''],
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 4
      },
      760: {
        items: 7
      },
      1000: {
        items: 7
      },
      1200: {
        items: 7
      }
    },
    nav: true
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MoreImages,
    private matDialogRef: MatDialogRef<MoreImagesModalComponent>,
    private elRef: ElementRef,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.moreImage = this.data;
    this.callingLimit = this.moreImage?.images.length - 1;
  }

  closeDialog(): void {
    this.matDialogRef.close();
  }

  onErrorHandling(event: { target: { src: string } }): void {
    event.target.src = 'assets/images/Rectangle 43.png';
  }

  onImageActive(imageIndex: number): void {
    this.imageIndex = imageIndex;
    this.callingLimit = this.moreImage?.images.length;
    const carouselImageRef = this.elRef.nativeElement.querySelectorAll('.carousel-item');
    carouselImageRef.forEach((image, index) => {
      if(index === imageIndex) {
        image.classList.add('active');
      }else{
        image.classList.remove('active');
      }
    });
  }

  onDomChange(_$event: Event): void {
    this.callingCount += 1;
    if(this.callingLimit !== this.callingCount) {
      return;
    }else{
      this.carousaelImageActive();
      this.callingLimit = 4;
      this.callingCount = 0;
    }
  }

  onPreviousSlide(): void {
    const imageIndex = this.imageIndex - 2;
    if (imageIndex < 0){
      this.imageIndex = this.moreImage?.images.length - 1;
    }else{
      this.imageIndex = imageIndex;
    }
  }

  carousaelImageActive(): void {
    if(this.imageIndex > this.moreImage?.images.length - 1){
      this.imageIndex = 0;
    }
    const imageRef = this.elRef.nativeElement.querySelectorAll('.slider-image');
    imageRef.forEach((image, index) => {
      if(index === this.imageIndex) {
        image.classList.add('slider-image-active');
      }else{
        image.classList.remove('slider-image-active');
      }
    });
    this.imageIndex += 1;
  }

}
