<div class="container-fluid footer">
    <div class="container pt-5">
        <div class="row">
            <div class="col col-12 col-lg-6 col-md-6 text-lg-start text-center">
                   <img class="img-fluid" height="110" width="170" src="assets/Stylework-Logo-white.svg" alt="stylework logo">          
                    <p class="about-text mt-lg-3 mb-lg-5 mt-2 mb-5 col-lg-7 col-12">We’re aiming at empowering organisations of any size seamlessly build their personalised workspace to maximise productivity.</p>
                <div class="search-container">
                   <h4 class="text-white fs-regular">Don’t be shy, tell us anything!</h4>
                   <div class="d-flex justify-content-center justify-content-lg-start mt-3">
                       <div class="search-bar px-3 rounded d-flex align-items-center">
                           <input type="text" class="input-bar" 
                           placeholder="Enter your message" [formControl]="message">
                       </div>
                        <div class="ms-2">
                            <button class="btn btn-primary-base py-2 px-3" 
                            (click)="postMessage()"
                            [disabled]="message.invalid" *ngIf="!isLoading">Submit</button>
                            <app-bootstrap-loader *ngIf="isLoading" style="position: relative;left: 50%"></app-bootstrap-loader>
                        </div>
                   </div>
                </div>
            </div>
            <div class="col col-12 col-lg-6 col-md-6">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12 text-lg-start text-md-start text-center discover-container my-5 my-md-0 my-lg-0">
                           <p class="fs-larger">Discover</p>
                               <ul class="ps-0 link-list-container fs-large mt-4">
                                <li class="mb-3" *ngFor="let ft1 of footerNavFirst; let i = index">
                                    <a *ngIf="i!=1" routerLink="/{{ft1.link}}"><h5 class="fs-large">{{ft1.title}}</h5></a>
                                    <a *ngIf="i===1" href="{{ft1.link}}" target="_blank"><h5 class="fs-large">{{ft1.title}}</h5></a>
                                </li>
                       </ul>
                    </div>        
                    <div class="col-lg-6 col-md-6 col-12 text-lg-start text-md-start text-center discover-container">
                        <p class="fs-larger">Products</p>
                            <ul class="ps-0 link-list-container mt-4">
                             <li class="mb-3" *ngFor="let ft2 of footerNavSecond">
                                 <a routerLink="/{{ft2.link}}"><h5 class="fs-large">{{ft2.title}}</h5></a>
                             </li>
                         </ul>
                 </div>
                </div>
            </div>
        </div>
           <div class="social-medai-container d-flex justify-content-lg-between justify-content-md-between  flex-column flex-lg-row flex-md-row mt-5">         
                <ul class="logo text-lg-start text-center list-inline mb-lg-0 mb-4 pt-lg-3 pt-md-3">
                    <li class="me-lg-5 me-4 list-inline-item" *ngFor="let soci of socialIcon">
                        <a [href]="soci.link" target="_blank"> 
                            <img src="{{soci.icon}}" alt="{{soci.alt}}" loading="lazy">
                         </a>
                     </li>
                 </ul> 
                <hr class=" d-block d-md-none d-lg-none  mt-2 bg-light">
                   <div class="row contact-container">
                       <div class="col-12 col-md-6 col-lg-6 text-lg-start text-center pe-md-4 pe-lg-4">
                           <span>Call Us at</span>
                           <a href="tel:9650605494"><p>{{basicInfo.primaryContactNo}}</p></a>
                       </div>
                       <div class="col-12 col-md-6 col-lg-6 text-lg-start text-center pe-md-4 pe-lg-4">
                           <span>Mail us at</span>
                           <a href="mailto:hello@stylework.city" target="_blank"><p>{{basicInfo.primaryEmailId}}</p></a>
                       </div>  
               </div>
           </div>       
        <hr class="bg-light mt-2 d-lg-block d-md-block d-none">
        <div class="row mb-2">
               <div class="col-md-7 col-12 order-lg-0 order-1 copyright align-items-center text-lg-start text-center mt-lg-2 mt-4">
                       <span class="ms-lg-2 text-muted  mt-lg-0 mt-3"> <span>&#169;</span> {{year}} STYLEWORK INNOVATION HUB PRIVATE LIMITED</span>   
            </div>
            <div class="col-md-5 col-12 order-0 order-lg-1 mt-lg-2 mt-2 order-0">
               <div class="d-flex justify-content-between flex-row align-items-center ms-lg-5 privacy">     
                       <a class="tab" (click)="refreshRoute()">FAQs</a>
                       <a href="https://server.stylework.city/static/docs/terms-n-conditions.pdf" target="_blank">Terms & Conditions</a>
                       <a routerLink="/privacy-policy" target="new">Privacy Policy</a>
               </div>
            </div>
        </div>    
    </div>
</div>

