<div class="list">
    <ng-container *ngIf="(deskPreference$ | async) as deskPreferenceList">
        <ng-container *ngIf="deskPreferenceList.length > 0">
            <ng-container *ngFor="let deskPreference of deskPreferenceList;let i=index;">
                <div class="d-flex justify-content-between flex-row align-items-center" *ngIf="i<5">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value=""
                            [id]="'deskPreference'+i" (change)="onAddDeskPreference($event,deskPreference)">
                        <label class="form-check-label ms-1" [for]="'deskPreference'+i">
                            {{deskPreference.categoryName}}
                        </label>
                    </div>
                    <div>
                        <span class="list-text">({{deskPreference.count}})</span>
                    </div>
                </div>
            </ng-container>
            <div class="collapse" id="showmore5" *ngIf="deskPreferenceList.length > 5">
                <div class="col-12" *ngFor="let deskPreference of deskPreferenceList;let i=index;">
                    <div class="d-flex justify-content-between flex-row align-items-center" *ngIf="i>4">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                [id]="'deskPreference'+i" (change)="onAddDeskPreference($event,deskPreference)">
                            <label class="form-check-label ms-1" [for]="'deskPreference'+i">
                                {{deskPreference.categoryName}}
                            </label>
                        </div>
                        <div>
                            <span class="list-text">({{deskPreference.count}})</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- <ng-container>
            <div class="d-flex justify-content-between flex-row align-items-center" *ngIf="(isLoading$ | async)">
                <app-bootstrap-loader></app-bootstrap-loader>
            </div>
        </ng-container> -->

    </ng-container>
</div>
<div class="d-flex justify-content-end flex-row mt-2" *ngIf="(deskPreference$ | async)?.length > 5">
    <a (click)="readMore = !readMore" class="link-text" data-bs-toggle="collapse"
        href="#showmore5" role="button" aria-expanded="false"
        aria-controls="showmore5">
        {{readMore ? "Show More" : "Show Less"}}
    </a>
</div>