<div class="rounded safe-guidelines" *ngIf="spaceSafety && spaceSafety.length > 0">
    <div class="d-flex justify-content-center flex-row align-items-center">
        <div class="safety-badge col-9 col-sm-7 col-md-5 col-lg-4">
            <img class="img-fluid" src="assets/images/Stylesafe.svg" alt="safety-icon"> 
        </div>
    </div>
    <div class="p-lg-5 p-2 pt-3 px-lg-5 row " *ngIf="spaceSafety.length > 3">
            <div class="col-3 d-flex flex-column flex-lg-row text-lg-start text-center"
                *ngFor="let safetyStd of spaceSafety | slice:0:4">
                <div class="saftey-stan d-flex justify-content-center">
                    <!-- <img src="assets/icons/Social-Distancing-big.png"> -->
                    <img src="{{safetyStd.icon}}"
                        alt="safetyStd icon" (error)="onErrorHandlingOfSafetyStdImg($event)" />
                </div>
                <div class="ms-lg-3 ms-0 mt-0 mt-lg-0 d-flex align-items-lg-center" [title]="safetyStd.heading">
                    <p class="span-text fz-xs-10 " >
                        {{safetyStd.heading |truncate:{sliceStart:0,
                            sliceEnd:30, append: '...'} }}
                    </p>
                </div>
            </div>
            <!-- <div class="col-3 d-flex justify-content-center  align-items-center" *ngIf="spaceSafety.length - 3 > 0">
                <div class="circle-container">
                    <div class="text-center mt-lg-4 mt-2">
                        <p class="m-0 fs-regular">+{{spaceSafety.length - 3}}</p>
                        <p class="m-0 fs-small">MORE</p>
                    </div>
                </div>
            </div> -->
    </div>
    <div class="p-lg-5 p-2 pt-3 px-lg-5 row " *ngIf="spaceSafety.length <= 3">
        <div class="col d-flex flex-column flex-lg-row text-lg-start text-center"
            *ngFor="let safetyStd of spaceSafety">
            <div class="saftey-stan d-flex justify-content-center">
                <!-- <img src="assets/icons/Social-Distancing-big.png"> -->
                <img src="{{safetyStd.icon}}"
                    alt="safetyStd icon" (error)="onErrorHandlingOfSafetyStdImg($event)" />
            </div>
            <div class="ms-lg-3 ms-0 mt-0 mt-lg-0 d-flex align-items-lg-center">
                <p class="span-text fz-xs-10">
                    {{safetyStd.heading |truncate:{sliceStart:0,
                        sliceEnd:40, append: '...'} }}
                </p>
            </div>
        </div>
</div>
</div>