import { AgmInfoWindow } from '@agm/core';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { amountNotAvailable } from 'src/app/Helper/enums';
import { Spaces } from 'src/app/shared/interface';


@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements OnInit {
  amountMessage = amountNotAvailable;
  @Input() spaces: Spaces[] = [];
  @Input() mapWidth='auto';
  @Input() mapHeight='640px';
  @Input() membershipSelection = false;
  @Input() sessionId = null;
  zoom =14;
  infoWindowOpened = null;
  previousInfoWindow = null;

  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {
    console.log(this.spaces);
    const innerWidth = window.innerWidth;
    if(innerWidth<768){
      this.mapHeight = '400px';
    }
  }

  clickedMarker(spaceMarker: Spaces, index: number,infoWindow: AgmInfoWindow): void {
    if (this.previousInfoWindow == null)
    {this.previousInfoWindow = infoWindow;}
    else {
      this.infoWindowOpened = infoWindow;
      this.previousInfoWindow.close();
    }
    this.previousInfoWindow = infoWindow;
  }

  getStyleConfigs(): { width: string; height: string; border: string} {
    return {
      width: this.mapWidth,
      height: this.mapHeight,
      border: '0'
    };
  }

  mapClicked(event: MouseEvent): void {
    if (this.previousInfoWindow != null) {
      this.previousInfoWindow.close();
    }
  }

  routeToSpaceDetails(spaceInfo: Spaces): void {
    window.scrollTo(0,0);
    if(this.sessionId !== null){
      this.router.navigate([`/membership/${this.sessionId}/space-details/${spaceInfo.id}`]);
    }else{
      this.router.navigate([`/coworking-spaces/${spaceInfo.cityInfo.slug}/${spaceInfo.areaInfo.slug}/${spaceInfo.slug}`]);
    }
  }

  onImgError(event): void {
    event.target.src = 'assets/images/img.png';
  }

}
