import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QueryParams } from 'src/app/shared/services/common-services/rest-api.service';
import { SubSink } from 'subsink';
import { FilterParameterForPrice, FilterService, FiltersQuery, FiltersResponse } from '../../filters/filters.service';

@Component({
  selector: 'app-horizontal-filters',
  templateUrl: './horizontal-filters.component.html',
  styleUrls: ['./horizontal-filters.component.scss']
})
export class HorizontalFiltersComponent implements OnInit,OnDestroy {
  @Output() appliedFilters = new EventEmitter<FiltersQuery>();
  @Input() filtersByPopularLocationId: {
    value: boolean;
    spaceId: string;
    cityId?: string;
  } = { value: false, spaceId: null};
  @Input() locationsIdsArr: string[];
  areaSlug= null;
  filters: FiltersQuery = {
    budget: {
      seatType: [],
      priceRanges: [
      ]
    },
    nearBy: [],
    popularAreas: [],
    amenitiesAvailable: [],
    seatCategories: []
  };
  queryObj: QueryParams = {
    lat: 0,
    long: 0,
    name: undefined,
    page: 0,
    count: 10,
    areaSlug: ''
  };
  iconRotate = false;
  filtersData: FiltersResponse;
  filterDataKeys: string[];
  loading = false;
  private sub = new SubSink();
  constructor(
    private filterService: FilterService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.areaSlug = this.route.snapshot.params['areaSlug'] || null;
    this.sub.add(
      this.route.params.subscribe((prm) => {
        this.queryObj = {
          lat: prm.lat||0,
          long: prm.long || 0,
          areaSlug: prm.areaSlug || '',
          page: 0,
          count: 10,
          citySlug: prm.citySlug || 'gurgaon',
          id: prm?.id || undefined,
          popular: prm?.popular || undefined
        };
        this.getFiltersUsingCitySlugs();
      })
    );
  }


  getFiltersUsingCitySlugs(): void {
    this.loading = true;
    this.sub.add(
      this.filterService.getSpaceFiltersByCitySlug(this.queryObj)
        .subscribe(res => {
          this.filtersData = res;
          this.loading = false;
          this.checkFilterData();
        })
    );
  }


  getFiltersUsingPopularLocatonIds(): void {
    this.loading = true;
    this.sub.add(
      this.filterService.getSpaceFiltersByPopularLocationsIds(this.queryObj.id)
        .subscribe(res => {
          this.filtersData = res;
          this.loading = false;
          this.checkFilterData();
        })
    );
  }

  getFiltersUsinglatLong(): void {
    this.loading = true;
    this.sub.add(
      this.filterService.getSpaceFilters(this.queryObj)
        .subscribe(res => {
          this.filtersData = res;
          this.loading = false;
          this.checkFilterData();
        })
    );
  }

  getFiltersUsingCityId(): void {
    this.loading = true;
    this.sub.add(
      this.filterService.getSpaceFiltersByCityId(this.queryObj.id)
        .subscribe(res => {
          this.filtersData = res;
          this.loading = false;
          this.checkFilterData();
        })
    );
  }

  addDeskPrefernece(deskIds: string[]): void {
    this.filters.seatCategories = deskIds || undefined;
    this.checkForValues();
  }

  addAmenities(amenityIds: string[]): void {
    this.filters.amenitiesAvailable = amenityIds || undefined;
    this.checkForValues();
  }

  addPopularLocations(popularLocationIds: string[]): void {
    this.filters.popularAreas = popularLocationIds || undefined;
    this.checkForValues();
  }

  addLocationPreferences(nearByIds: string[]): void {
    this.filters.nearBy = nearByIds || undefined;
    this.checkForValues();
  }

  addPriceToFilter(prices: FilterParameterForPrice): void {
    if(this.filters.budget && this.filters.budget.priceRanges && this.filters.budget.priceRanges.length > 0) {
      const index = this.filters.budget.priceRanges.findIndex(price => price.maximum === prices.maximum);
      if(index > -1) {
        this.filters.budget.priceRanges.splice(index,1);
      } else {
        this.filters.budget.priceRanges.push(prices);
      }
    } else {
      this.filters.budget.priceRanges = [];
      this.filters.budget.priceRanges.push(prices);
    }
    this.checkForValues();
  }

  addSeatType(seatTypeId: string): void {
    if(this.filters.budget && this.filters.budget.seatType && this.filters.budget.seatType.length > 0){
      this.filters.budget.seatType = [];
    }
    this.filters.budget.seatType = [];
    this.filters.budget.seatType.push(seatTypeId);
    this.checkForValues();
  }

  checkFilterData(): void {
    if(this.filtersData.amenities && this.filtersData.amenities.length === 0){
      delete this.filtersData.amenities;
    }
    //currently budget is not showing so delete it from filtersData
    if(this.filtersData.budget){
      delete this.filtersData.budget;
    }
    //currently popularLocations is not showing so delete it from filtersData
    if(this.filtersData.popularLocations && this.filtersData.popularLocations.length === 0){
      delete this.filtersData.popularLocations;
    }
    if(this.filtersData.desks && this.filtersData.desks.length === 0){
      delete this.filtersData.desks;
    }
    if(this.filtersData.landmarks && this.filtersData.landmarks.length === 0){
      delete this.filtersData.landmarks;
    }
    this.filterDataKeys = Object.keys(this.filtersData);
  }

  checkForValues(): void {
    if(this.filters.budget && this.filters.budget.seatType && this.filters.budget.seatType.length === 0) {
      delete this.filters.budget.seatType;
    }
    if(this.filters.seatCategories && this.filters.seatCategories.length === 0) {
      delete this.filters.seatCategories;
    }
    if(this.filters.popularAreas && this.filters.popularAreas.length === 0) {
      delete this.filters.popularAreas;
    }
    if(this.filters.nearBy && this.filters.nearBy.length === 0) {
      delete this.filters.nearBy;
    }
    if(this.filters.amenitiesAvailable && this.filters.amenitiesAvailable.length === 0) {
      delete this.filters.amenitiesAvailable;
    }
    if(this.filters.budget && this.filters.budget.priceRanges && this.filters.budget.priceRanges.length === 0) {
      delete this.filters.budget.priceRanges;
    }
    if(this.filters.budget && Object.keys(this.filters.budget).length === 0) {
      delete this.filters.budget;
    }
    this.appliedFilters.emit(this.filters);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

