import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cities } from 'src/app/shared/interface/cities';
import { RestApiService } from 'src/app/shared/services/common-services/rest-api.service';
import { AppRouterService } from 'src/app/shared/services/router/app-router.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-location-modal',
  templateUrl: './location-modal.component.html',
  styleUrls: ['./location-modal.component.scss']
})

export class LocationModalComponent implements OnInit, OnDestroy {
  searchCity: string = null;
  selectedCityArray = [];
  citiesList: Cities[] = [];
  loading = false;
  private subs = new SubSink();
  constructor(
    private appRouterService: AppRouterService,
    private restService: RestApiService,
    private dialogRef: MatDialogRef<LocationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {cities: string[]}
  ){
    this.selectedCityArray = this.data.cities;
  }

  ngOnInit(): void {
    this.loading = true;
    this.subs.add(
      this.restService.getCities().subscribe(cityData =>{
        this.loading = false;
        cityData.forEach((item) => {
          const i = this.citiesList.findIndex(x => x?.id === item?.cityId);
          if (i <= -1) {
            this.citiesList.push(item.city);
          }
        });
      })
    );
  }
  selectedCityName(city: string): void {
    const cityIndex = this.selectedCityArray.indexOf(city);
    if(cityIndex < 0){
      this.selectedCityArray.push(city);
    }

  }
  closeDialog(): void {
    this.dialogRef.close(this.selectedCityArray);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
