import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { SpaceService } from 'src/app/shared/services/spaces/space.service';
import { SearchSpaceQuery, SearchSpaces } from 'src/app/shared/interface';
import { routeParams } from 'src/app/Helper/enums';
import { Helper } from 'src/app/Helper/helpers';
import { MoreCitiesComponent } from 'src/app/components/more-cities/more-cities.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-d-search-section',
  templateUrl: './d-search-section.component.html',
  styleUrls: ['./d-search-section.component.scss']
})
export class DSearchSectionComponent implements OnInit {
  @ViewChild('searchBox') searchBox: ElementRef;
  helper = Helper
  searchCity: string = null;
  isSearboxTableOpen = false;
  searchSpaceList: SearchSpaces[] = [];
  mform: FormGroup = new FormGroup({
    searchToken: new FormControl()
  });
  searchSpaceQuery:SearchSpaceQuery = {
    page: 0,
    count: 50,
    searchToken: ''
  }
  collapse = true;
  private subs = new SubSink();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private spaceService: SpaceService,
    private dialog: MatDialog
  ) { 
    this.renderer.listen('window', 'click',(e:Event)=>{
      if(e.target !== this.searchBox.nativeElement){
         this.isSearboxTableOpen = false;
      }else{
         this.isSearboxTableOpen = true;
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((prm) => {
      if(prm[`${routeParams.citySlug}`]){
        this.searchSpaceQuery.citySlug  = prm[`${routeParams.citySlug}`];
      }
    });
    this.mform.valueChanges
      .pipe(
        debounceTime(500),
        switchMap(value => {
          console.log(value);
          if(value.searchToken){
            this.searchSpaceQuery.searchToken = value.searchToken;
            return this.spaceService.getSearchSpaces(this.searchSpaceQuery);
          }else{
            this.searchSpaceList = [];
            return []
          }
        })
      )
      .subscribe(data => {
        this.searchSpaceList = data;
        console.log(this.searchSpaceList);
        this.isSearboxTableOpen = true;
      });
  }

  setSearchPlaceholder(): string {
    if(this.searchSpaceQuery?.citySlug){
      const placeHolder = Helper.convertSlugToString(this.searchSpaceQuery?.citySlug)
      return placeHolder.length < 17 ? placeHolder : placeHolder.substring(0, 17)+ '...';

    } else return 'India';
  
  }


  getInputValue(): void {
    if(this.mform.value.searchToken.length === 0){
      this.isSearboxTableOpen = true;
      this.resetSearchToken();
    }
  }

  resetSearchToken(): void {
    this.mform.setValue({
      searchToken: null
    });
  }

  routeToSpaceDetais(space: SearchSpaces): void {
   const spaceSlug = `${space.cityInfo.slug}/${space.areaInfo.slug}/${space.slug}`;
   this.router.navigate([`/coworking-spaces/${spaceSlug}`]);
   this.isSearboxTableOpen = false;
   this.mform.reset();
  }

  moreCities(): void {
    const dialogRef = this.dialog.open(MoreCitiesComponent,{
      width:'98vw',
      position:{top:'0px',},
      disableClose: true,
      data:{ route: true}
    });
  }

}
