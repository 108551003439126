<span class="sub-heading">Popular filters</span>
<div class="mt-3">
   
    <div class="d-flex justify-content-between flex-row align-items-center">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
            <label class="form-check-label ms-1" for="defaultCheck1">
                Stylesafe Advanced - Safe and Hygenic Workspaces
            </label>
        </div>
        <div>
            <!-- <span class="list-text">(06)</span> -->
        </div>
    </div>
    <div class="d-flex justify-content-between flex-row align-items-center">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
            <label class="form-check-label ms-1" for="defaultCheck1">
                No lock-in
            </label>
        </div>
        <div>
            <!-- <span class="list-text">(36)</span> -->
        </div>
    </div>

</div>