/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[domChange]'
})
export class DomChangeDirective implements OnDestroy {
    private changes: MutationObserver;
    @Output() public domChange = new EventEmitter();

    constructor(private elementRef: ElementRef) {
      const element = this.elementRef.nativeElement;
      this.changes = new MutationObserver((mutations: MutationRecord[]) => {
        this.domChange.emit(mutations);});
      this.changes.observe(element, {
        attributes: true,
      });
    }
    ngOnDestroy(): void {
      this.changes.disconnect();
    }
}
