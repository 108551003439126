<div class="container stats my-lg-5 my-4">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col col-4">
            <span class=" mb-0 display-3  stats_number" id="location">{{styleworkstats.spacesNumber}}+</span>
            <p class="mt-lg-2 mt-0 stats_desc">{{styleworkstats.spaces}}<span>to choose from</span></p>
          </div>
          <div class="col col-4">
            <span class=" mb-0 display-3  stats_number" id="customer">{{styleworkstats.customersServedNumber}}+</span>
            <p class="mt-lg-2 mt-0  stats_desc">{{styleworkstats.customersServed}}<span>served </span></p>
          </div>
          <div class="col col-4">
            <span class=" mb-0 display-3 stats_number" id="enterprise">{{styleworkstats.enterpriseServedNumber}}+</span>
            <p class="mt-lg-2 mt-0  stats_desc">{{styleworkstats.enterpriseServed}}<span>served </span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
