import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CookieInterceptorService implements HttpInterceptor {

  constructor() { }
  // function which will be called for all http calls
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newHeaders = req.headers;
    newHeaders = newHeaders.set('samesite', 'Lax');
    const authReq = req.clone({headers: newHeaders});
    return next.handle(authReq);
  }

}
