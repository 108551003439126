<agm-map
    [zoom]="zoom"
    [latitude]="spaces[0].location.lat"
    [longitude]="spaces[0].location.lng"
    [zoomControl]="false"
    (mapClick)="mapClicked($event)"
    [fullscreenControl]='true' 
    [mapTypeControl]='true'
    [ngStyle]="getStyleConfigs()"
    *ngIf="spaces.length > 0"
>
    <ng-container *ngFor="let marker of spaces;let i=index">
        <agm-marker 
        (markerClick)="clickedMarker(marker,i,infoWindow)"
        [latitude]="marker?.location?.lat"
        [longitude]="marker?.location?.lng"
        [iconUrl]="'assets/icons/map-marker.svg'"
        >
            <agm-info-window #infoWindow width="100%">
                <div class="row">
                    <div class="col-3">
                        <img class="rounded col-12 px-0 space-image" src="{{marker.images[0]}}" (error)="onImgError($event)" [title]="marker.name"  alt="{{marker.name}}">
                    </div>
  
                    <div class="col-9 ps-md-4">
                        <p class="space-marker-text fz-xs-10 tab" [title]="marker.name" (click)="routeToSpaceDetails(marker)">{{marker?.name.length > 18 ? marker?.name.substring(0,18)+'...': marker?.name}}</p>
                        <ng-container *ngIf="marker?.nearby.length > 0">
                            <div>
                                <img 
                                src="{{marker?.nearby[0]?.type?.icon}}" 
                                alt="{{marker?.nearby[0]?.type?.name}} icon"
                                height="12px"
                                width="12px" 
                                /> 
                                <span class=" ms-2 near-text">{{marker?.nearby[0]?.name}}
                                    <b>({{marker?.nearby[0]?.approxDistance}})</b></span>
                            </div>
                           
                        </ng-container>
                        <ng-container *ngIf="!marker?.nearby || marker?.nearby.length == 0">
                            <span>
                                <img src="assets/icons/Metro.svg" alt="metro"> NA
                            </span>
                            
                        </ng-container>

                    </div>
                    
                </div>
                <div class="row mt-3">
                    <div class="col-lg-6  col-4 col-sm-6">
                        <span class="desk-text"> Desks from</span>
                        <p class="space-marker-text fz-xs-10" *ngIf="marker?.deskPrice && marker?.deskPrice?.amount">
                            ₹{{marker?.deskPrice?.amount}}/
                            <span class="price-text">{{marker?.deskPrice?.duration}}</span>
                        </p>
                        <p class="space-marker-text" *ngIf="!marker.deskPrice || !marker.deskPrice.amount || !marker.deskPrice.duration ">
                            <span class="price-text"> {{amountMessage}}</span>
                        </p>

                    </div>
                    <!-- <div class="col-sm-1"></div> -->
                    <div class="col-lg-6 col-8 mt-2 col-sm-6">
                        <button class="btn fz-xs-10 map-btn" (click)="routeToSpaceDetails(marker)">View Details</button>
                    </div>
                </div>
            </agm-info-window>
        </agm-marker>
    </ng-container>
</agm-map>
