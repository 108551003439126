import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterParameterForPrice, FilterService } from '../../filters.service';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements OnInit {
  @Output() pricesObj = new EventEmitter<FilterParameterForPrice>();
  @Input() minPrice = 0;
  @Input() maxPrice = 0;
  @Input() count = 0;

  prices: FilterParameterForPrice = {
    minimum: 0,
    maximum: 0,
  };

  constructor(
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
  }

  setPrices(event: {target: {checked: boolean}}): void {
    this.prices = {
      minimum: this.minPrice * 100,
      maximum: this.maxPrice * 100,
    };
    this.pricesObj.emit(this.prices);
  }

}
