import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum Environment {
  prod = 'prod',
  staging = 'staging',
  test = 'test',
  dev = 'dev',
  local = 'local',
}

interface Configuration {
  environment: Environment;
  apiUrl: string;
  webUrl: string;
  encryptionSecretKey: string;
  razorPaySecretKeyId:string;
}

@Injectable({ providedIn: 'root' })
export class EnvConfigurationService {
  public configSubject$: BehaviorSubject<Configuration> =
    new BehaviorSubject<Configuration>({
      environment: Environment.local,
      apiUrl: '',
      webUrl: '',
      encryptionSecretKey: '',
      razorPaySecretKeyId:''
    });

  private readonly configUrl = 'assets/config/config.json';

  constructor(private http: HttpClient) {}

  public load(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<Configuration>(this.configUrl)
        .toPromise()
        .then((config) => {
          this.configSubject$.next(config);
          resolve();
        })
        .catch((err: unknown) => {
          console.error(err);
          reject();
        });
    });
  }
}
