import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  hideFooterClass = true;
  testimonialHeading = 'Client’s Testimonials';
  testimonialSubHeading = 'We helped them find a perfect workspace, now it’s your turn. Here’s what they have to say about the experience.';
  constructor() { }

  ngOnInit(): void {
  }

}
