import { Component, HostListener, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { SpaceListResponse, SpaceService } from 'src/app/shared/services/spaces/space.service';
import { CustomAddressLocation, CustomLocationService } from 'src/app/shared/services/locations/custom-location.service';


@Component({
  selector: 'app-style-work-space',
  templateUrl: './style-work-space.component.html',
  styleUrls: ['./style-work-space.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0
        })
      ),
      transition('void <=> *', animate(800))
    ]),
  ]

})
export class StyleWorkSpaceComponent implements OnInit {
  fMembership = false;
  eWorkspaces = false;
  bookSpace = false;
  getPrice = false;
  bookTour = false;
  spacesData: SpaceListResponse;

flexiblemembership = 'assets/images/Landing-page/flexible-membership-plan.svg';
explorespaces ='assets/images/Landing-page/explore-spaces.svg';
bookspace ='assets/images/Landing-page/book-your-space.svg';
bestprice ='assets/images/Landing-page/get-the-best-pricce.svg';
freetour ='assets/images/Landing-page/book-a-free-tour.svg';


  private subs = new SubSink();

  constructor(
    private spaceService: SpaceService,
    private router: Router,
    private customLocationService: CustomLocationService
  ) { }

  routeToExploreSpaces(): void {
    this.router.navigate(['/coworking-spaces']);
  }
  routeToContactUs(): void{
    this.router.navigate(['/contact-us']);
  }
  ngOnInit(): void {
  }

}

