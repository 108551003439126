<ng-container *ngIf="cardWithImage">
    <div class="slide me-lg-1 carosel-content rounded row" (click)="spaceSummary(cardWithImage?.link)">
        <div class="img-holder col-md-12 col-4 pe-0 pe-md-2">
            <div [hidden]="!imageLoader">
                <app-bootstrap-loader class="d-flex align-items-center justify-content-center py-3">
                </app-bootstrap-loader>
            </div>
            <img [hidden]="imageLoader" src="{{cardWithImage.image}}" class="rounded" (load)="this.imageLoader = false;"
                (error)="onErrorHandling($event)" [title]="cardWithImage?.title" />

                <img *ngIf="cardWithImage?.categoryBadge"  class="category-badge" [src]="cardWithImage?.categoryBadge" alt="">
        </div>

        <div class="mt-md-2 col-md-12 col-8">
            <span class="content-heading" [title]="cardWithImage?.title">
                {{cardWithImage?.title |truncate:{sliceStart:0, sliceEnd:20, append: '...'} }}
            </span>
             <div class="d-flex flex-column h-75">
                <div class="mt-2 d-flex ">
                    <div>
                        <img class='icon' src="assets/icons/Location1.svg" alt="location">
                    </div>
                    <div class="ms-2 ">
                        <p class="carosel-content-text" [title]="cardWithImage?.address">
                            {{cardWithImage?.address |truncate:{sliceStart:0, sliceEnd:60, append: '...'} }}
                        </p>
                    </div>
                </div>
                <div class="mt-auto row">
                    <p class="day-pass-text col-md-12 col-12">Desks <span class="from">from*</span></p>
                    <div *ngIf="cardWithImage.price"
                        class=" mt-md-2 d-flex col-md-12 col-12 justify-content-between flex-row align-items-center d-sm-inline-flex">
                        <p class="pass-price">
                            {{cardWithImage.price}}
                        </p>
                        <p class="based-text mt-3 d-none d-lg-block">*based on desk type</p>
                    </div>
                    <div *ngIf="cardWithImage.seatPrice && !cardWithImage.price"
                    class=" mt-md-2 d-flex col-md-12 col-12 justify-content-between flex-row align-items-center d-sm-inline-flex">
                    <p class="pass-price">
                       
                        {{cardWithImage.seatPrice}}
                    </p>
                    <p class="based-text mt-3 d-none d-lg-block">*based on desk type</p>
                </div>
                <div *ngIf="!cardWithImage.price && !cardWithImage.seatPrice"
                class=" mt-md-2 d-flex col-md-12 col-12 justify-content-between flex-row align-items-center d-sm-inline-flex">
                <p class="pass-price">
                    {{amountMessage}}
                </p>
                
               </div>
                </div>
             </div>
        </div>
    </div>
</ng-container>