import { Component, Input, OnInit } from '@angular/core';
import { SpaceOpeningDays, Timings } from 'src/app/Helper/enums';
import { Helper } from 'src/app/Helper/helpers';
import { SpaceTimings } from 'src/app/shared/interface';

@Component({
  selector: 'app-d-about',
  templateUrl: './d-about.component.html',
  styleUrls: ['./d-about.component.scss']
})
export class DAboutComponent implements OnInit {
  @Input() description = '';
  @Input() spaceTimings: SpaceTimings;
  @Input() address = '';
  timingsType = Timings;
  daysEnum = SpaceOpeningDays;
  constructor() { }

  ngOnInit(): void {
  }

  //type === 0  days and type === 1 timings
  getSpaceWorkingDays(spaceTimings: SpaceTimings, type: number): string {
    if (spaceTimings) {
      if (type === this.timingsType.days) {
        return `${Object.values(this.daysEnum)[spaceTimings.openingDay]} - ${
          Object.values(this.daysEnum)[spaceTimings.closingDay]
        }`;
      } else if (type === this.timingsType.time) {
        return `${Helper.getColonTime(
          spaceTimings.openingTime || 0
        )} - ${Helper.getColonTime(spaceTimings.closingTime || 0)}`;
      }
    }
  }

}
